/**
 * Root Reducer
 */
import { combineReducers } from 'redux';

// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

// Import Reducers
import app from './modules/App/AppReducer';
import config from './modules/Config/ConfigReducer';
import intl from './modules/Intl/IntlReducer';
import currency from './modules/Currency/CurrencyReducer';
import cms from './modules/Cms/CmsReducer';
import error from './modules/Error/ErrorReducer';

import companies from './modules/Company/CompanyReducer';
import users from './modules/User/UserReducer';
import groups from './modules/Group/GroupReducer';
import addresses from './modules/Address/AddressReducer';
import categories from './modules/Category/CategoryReducer';
import products from './modules/Product/ProductReducer';
import orders from './modules/Order/OrderReducer';
import cart from './modules/Cart/CartReducer';
import shippings from './modules/Shipping/ShippingReducer';
import transactions from './modules/Transaction/TransactionReducer';
import notifications from './modules/Notification/NotificationReducer';
import tasks from './modules/Task/TaskReducer';

// Combine all reducers into one root reducer
export default combineReducers({
    addresses,
    app,
    cart,
    categories,
    config,
    cms,
    companies,
    currency,
    error,
    groups,
    intl,
    notifications,
    orders,
    products,
    shippings,
    tasks,
    transactions,
    users,
});
