import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button } from 'reactstrap';

import { getFile } from '../../../../util/file';

import { getContentBlockItemsByName, getContentBlockMixedItem, getContentBlockItemValue } from '../../../Cms/CmsActions';

import AppLogo from '../../components/view/AppLogo';
import HtmlComponent from '../../../../components/Text/HtmlComponent';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem';
import GenericSnippet from '../../components/view/GenericSnippet';
import PanelContactForm from '../../../Contact/components/form/PanelContactForm';

export function HomePage({ intl, page }) {
    const { blocks } = page || {};
    const title = getContentBlockItemValue(blocks, 'title');
    const subtitle = getContentBlockItemValue(blocks, 'subtitle');
    const contents = getContentBlockItemsByName(page.blocks, 'content');
    const cover = getContentBlockItemValue(blocks, 'cover', 'path');
    const categoriesPosition = getContentBlockItemValue(blocks, 'categories-position');

    const renderContent = (content, isFullColumn = false) => {
        const title = getContentBlockMixedItem(content, 'title') && getContentBlockMixedItem(content, 'title').text;
        const text = getContentBlockMixedItem(content, 'text') && (
            <div className="p-2 p-md-0">
                <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} />
            </div>
        );
        const image = getContentBlockMixedItem(content, 'image') && getContentBlockMixedItem(content, 'image').path && <CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" className="mt-3 mt-md-0 w-100" />;
        const cta = getContentBlockMixedItem(content, 'call-to-action') && getContentBlockMixedItem(content, 'call-to-action').text && <div className="text-center"><Button color="warning" size="lg" tag={Link} to={getContentBlockMixedItem(content, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(content, 'call-to-action').text}</Button></div>;
        const position = getContentBlockMixedItem(content, 'position') && getContentBlockMixedItem(content, 'position').text;
        return (
            <Container className="py-4 bg-white">
                <h3 className="mb-4 pb-0 h2 font-tertiary font-weight-normal font-italic text-center text-secondary title-border">{title}</h3>
                <Row>
                    {
                        (!position || position === 'right')
                        ? (
                            <Fragment>
                                <Col xs="12" md={image ? 6 : 12}>
                                    {text}
                                </Col>
                                {image && <Col>{image}</Col>}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {image && <Col>{image}</Col>}
                                <Col xs="12" md={image ? 6 : 12}>
                                    {text}
                                </Col>
                            </Fragment>
                        )
                    }
                </Row>
                {cta}
            </Container>
        );
    };

    return (
        <div>
            <Helmet>
                <body data-header-withcover={cover ? 'true' : 'false'} data-header-contrast={cover ? 'true' : 'false'} />
            </Helmet>
            <GenericSnippet />
            <div className={classnames(cover ? 'header-cover' : 'page-wrapper', 'bg-black', 'pt-5')} style={{ backgroundImage: cover ? `url(${getFile(cover)})` : '', height: cover ? '600px' : 'auto' }}>
                <Container className="pb-5 text-center">
                    <div className="mt-5 pt-5 pb-4">
                        <AppLogo />
                        <h1 className={classnames('mt-5', 'font-secondary', 'text-secondary')}>
                            {title}
                        </h1>
                        <h2 className="font-tertiary font-italic text-white">{subtitle}</h2>
                    </div>
                </Container>
            </div>

            {contents && contents.length && contents.map((content, index) => (
                <Fragment key={index}>
                    {index === parseInt(categoriesPosition, 10) && <CmsPanel slug="categories" />}
                    {renderContent(content)}
                </Fragment>
            ))}

            <PanelContactForm />

        </div>
    );
}

// Retrieve data from store as props
function mapStateToProps(store) {
    return {
        // user: store.users.user,
    };
}

HomePage.defaultProps = {
};

HomePage.propTypes = {
    // location: PropTypes.object,
    intl: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(injectIntl(HomePage));

export const HomePageModel = {
    type: 'page',
    name: 'home',
    slug: '*',
    blocks: [
        {
            name: 'cover',
            type: 'image',
            max: 1,
        },
        {
            name: 'title',
            type: 'text',
            max: 1,
        },
        {
            name: 'subtitle',
            type: 'text',
            max: 1,
        },
        {
            name: 'content',
            type: 'mixed',
            blocks: [
                {
                    name: 'title',
                    type: 'text',
                    max: 1,
                },
                {
                    name: 'text', // name or id; MUST be unique
                    max: 1, // max same items allowed
                    type: 'html', // || text || link || image || mixed (with children)
                },
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
                {
                    name: 'call-to-action',
                    type: 'link',
                    max: 1,
                },
                {
                    name: 'image-position',
                    type: 'select',
                    max: 1,
                    options: ['left', 'right'],
                },
            ],
        },
        {
            name: 'categories-position',
            type: 'text',
            max: 1,
        },

    ],
};
