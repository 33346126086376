// Import Actions
import { SET_ADDRESSES, REMOVE_ADDRESS, SET_COUNTRIES } from './AddressActions';
import { LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    data: [],
    countries: [],
};

const AddressReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ADDRESSES:
            return {
                ...state,
                data: state.data.filter(address => !action.addresses.find(addressToAdd => addressToAdd._id === address._id)).concat(action.addresses),
            };

        case REMOVE_ADDRESS:
            return {
                ...state,
                data: state.data.filter(address => address._id !== action.addressId),
            };

        case SET_COUNTRIES:
            return {
                ...state,
                countries: action.countries,
            };

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default AddressReducer;
