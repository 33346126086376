import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { JSONLD, Product, AggregateRating, Generic, GenericCollection, Review, Author, Rating } from 'react-structured-data';

import config from '../../../../config';
import { dateFormat } from '../../../../util/date';

import { getConfig } from '../../../Config/ConfigActions';

function GenericSnippet({ intl, title, description, image }) {
    return (
        <JSONLD dangerouslyExposeHtml>
            <Product
                name={title || config.application.name}
                image={image || `${config.url}assets/images/logo.png`}
                description={description}
            />
        </JSONLD>
    );
}

function mapStateToProps(store, props) {
    return {
    };
}

GenericSnippet.defaultProps = {
    title: '',
    description: '',
    image: '',
};

GenericSnippet.propTypes = {
    intl: PropTypes.object.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
};

export default connect(mapStateToProps)(injectIntl(GenericSnippet));
