import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

class HtmlComponent extends Component {

    constructor(props) {
        super(props);
        this.transform = this.transform.bind(this);
    }

    transform(node, index) {
    }

    renderHtml() {
        if(this.props.text) {
            return ReactHtmlParser(this.props.text, {
                transform: this.transform,
            });
        }
    }

    render() {
        if(this.props.inline) {
            return <span className="html-text-wrapper">{this.renderHtml()}</span>;
        }
        return <div className="html-text-wrapper">{this.renderHtml()}</div>;
    }

}

HtmlComponent.propTypes = {
    text: PropTypes.string,
    inline: PropTypes.bool,
};

export default HtmlComponent;
