import ReactGA from 'react-ga';
import slugify from 'slugify';
import moment from 'moment';

import config from '../../config';
import { formatPrice } from '../../util/price';
import { unique } from '../../util/array';

import { getShippingFee, getShippingFeeDetails } from '../Shipping/ShippingActions';
import { getProducts, getProductPrice } from '../Product/ProductActions';
import { getCompany, getCompanyOption } from '../Company/CompanyActions';
import { getLoggedUser } from '../User/UserActions';
import { getCompanyGroups, checkCompanyGroupConditions } from '../Group/GroupActions';
import { getDefaultLanguage, getTranslation } from '../Intl/IntlActions';
import { getConfig } from '../Config/ConfigActions';

export const SET_COMPANY = 'SET_COMPANY';
export const SET_ITEM = 'SET_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_EXPEDITION_DATE = 'SET_EXPEDITION_DATE';
export const CLEAR_CART = 'CLEAR_CART';

// Getters
export function getCartCompany(store) {
    return getCompany(store, (store.cart.company && store.cart.company._id) || store.cart.company);
}

export function getCart(store) {
    return store.cart;
}

export function getCartItems(store) {
    return store.cart.items;
}

export function getCartItemByProduct(store, product) {
    return store.cart.items.find(item => item.productId === product._id);
}

export function getCartShipping(store) {
    return store.cart.shipping;
}

export function getCartExpeditionDate(store) {
    return store.cart.expeditionDate;
}

export function getCartAddress(store, type) {
    return (store.cart[type] || {}).address;
}

export function getCartItemFromProduct(product, quantity = 1, locale = getDefaultLanguage(), user = null, options = {}) {
    if(product) {
        const { _id, __v, categories, description, composition, translations, pictures, isActive, ...productData } = product;
        return {
            ...productData,
            productId: (product._id || '').toString(),
            quantity: parseInt(quantity, 10),
            // unitPriceHT: getProductPrice(product, 1, true),
            // priceHT: getProductPrice(product, 1),
            status: 'ordered',
            name: getTranslation(product, 'name', locale),
            addedBy: user && user._id,
            dates: {
                added: moment.utc().format(),
            },
            ...(options || {}),
        };
    }
}

export function getItemPackFillPercentage(item) {
    return item.quantity % item.packing
        ? Math.round(((item.quantity % item.packing) * 100) / item.packing)
        : 100;
}

export function getItemsPackFill(items, isPercent = false) {
    return items && items.length ? items.reduce((total, item) => total + (isPercent ? getItemPackFillPercentage(item) : item.quantity), 0) / items.length : 0;
}

export function getPackages(items = []) {
    const packages = [];
    (items || []).forEach(item => {
        for(let i = 0; i < item.quantity; i += 1) {
            packages.push({ ...item, quantity: 1 });
        }
    });

    return packages;

    // Old package calculation by product unit
    // const packages = [];
    // (items || []).forEach(item => {
    //     if(item.quantity > item.packing) {
    //         const threshold = Math.ceil(item.quantity / item.packing);
    //         for(let i = 0; i < threshold; i += 1) {
    //             packages.push({
    //                 items: [{
    //                     ...item,
    //                     quantity: (i + 1) !== threshold ? item.packing : item.quantity - (i * item.packing),
    //                 }],
    //             });
    //         }
    //     } else {
    //         packages.push({ items: [{ ...item }] });
    //     }
    // });
    // // console.log('Order packages', packages);
    // return packages;
}

export function getParcels(method, items = []) {
    if(!method || !(method.calculation || {}).parcelGroup) {
        // console.log('getParcels ::: Method without parcel grouping');
        return [[...(items || [])]];
    }

    let parcels = [];
    const orderPackages = getPackages(items);

    for(let i = 0; i < Math.ceil(orderPackages.length / method.calculation.parcelGroup); i += 1) {
        parcels.push([...orderPackages.slice(i * method.calculation.parcelGroup, (i + 1) * method.calculation.parcelGroup)]);
    }

    parcels = parcels.map(parcelItems => {
        const productIds = [];
        return parcelItems.map(item => {
            if(!productIds.includes(item.productId)) {
                productIds.push(item.productId);
                const quantity = parcelItems.filter(otherItem => otherItem.productId === item.productId).reduce((total, otherItem) => total + otherItem.quantity, 0);
                return getCartItemFromProduct({ _id: item.productId, ...item }, quantity);
            }
            return null;
        }).filter(item => item);
    });

    return parcels;
}

export function isCartValid(store, onlyConditions = false) {
    const groups = getCompanyGroups(store) || [];
    return (!groups.length || groups.every(group => checkCompanyGroupConditions(group, getCartItems(store))))
        && (onlyConditions || (
            getTotalQuantity(getCartItems(store)) > 0
            && getCartTotalValue(store) > 0
            && !!getCartCompany(store)
            && !!getCartAddress(store, 'shipping')
            && !!getCartExpeditionDate(store)
        ));
}

// export function getCartSubscription(store) {
//     return store.cart.subscription;
// }

export function getTotalWeight(items, products = null) {
    return formatPrice((items || []).filter(item => item.status === 'ordered').reduce((total, item) => {
        const product = products && products.length ? products.find(product => product._id === item.productId) : null;
        return total + item.quantity * item.packing * (product && !item.forceUseItemData ? product.weight : item.weight);
    }, 0));
}

export function getTotalQuantity(items, ignoreStatus = false) {
    return formatPrice((items || []).filter(item => ignoreStatus || item.status === 'ordered').reduce((quantity, item) => quantity + ((item && item.quantity) || 0), 0));
}

export function getTotalPiecesQuantity(items, ignoreStatus = false) {
    return formatPrice((items || []).filter(item => ignoreStatus || item.status === 'ordered').reduce((quantity, item) => quantity + ((item && (item.quantity * item.packing)) || 0), 0));
}

export function getSubtotal(items, products = null) {
    return formatPrice((items || []).filter(item => item.status === 'ordered').reduce((total, item) => {
        const product = products && products.length && !item.forceUseItemData ? products.find(product => product._id === item.productId) : item;
        return total + getProductPrice(product, item.quantity);
    }, 0));
}

export function getTotalTax(items, products = null, taxRate = null) {
    if(items && items.length) {
        return formatPrice(
            items
                .filter(item => item.status === 'ordered')
                .map(item => ({
                    ...item,
                    ...((!item.forceUseItemData && products && products.length && products.find(product => product._id === item.productId)) || {}),
                    taxRate: item.taxRate || config.application.taxRate,
                }))
                .filter(item => item.taxRate === taxRate || taxRate == null)
                .reduce((subtotal, item) => {
                    return subtotal + getProductPrice(item, item.quantity) * item.taxRate;
                }, 0),
        );
    }
    return 0;
}

export function getTaxRates(items) {
    const taxRates = [];
    items.forEach(item => {
        const taxRate = item.taxRate || config.application.taxRate;
        if(!taxRates.includes(taxRate)) {
            taxRates.push(taxRate);
        }
    });
    return taxRates;
}

export function getCartShippingTotals(store) {
    const shipping = getCartShipping(store);
    const company = getCartCompany(store);
    const shippingExtraFeeRateDefault = getConfig(store, 'shipping-extra-fee');
    return getShippingFeeDetails(shipping.address, shipping.method, getCartItems(store), { increasePercentage: getCompanyOption(company, 'shippingExtraFee') && (getCompanyOption(company, 'shippingExtraFeeRate') || shippingExtraFeeRateDefault) });
}

export function getCartTotalValue(store) {
    const products = getProducts(store);
    const company = getCartCompany(store);
    const shippingFeeDetails = getCartShippingTotals(store);
    return formatPrice(
        getSubtotal(getCartItems(store), products)
        + (shippingFeeDetails.base || 0) + (shippingFeeDetails.extraFee || 0) + (shippingFeeDetails.increase || 0)
        + (getCompanyOption(company, 'exemptTax') ? 0 : (getTotalTax(getCartItems(store), products)) + (shippingFeeDetails.tax || 0)),
    );
}

export function getCartTotals(store, forceReturnTax = false) {
    const company = getCartCompany(store);
    const products = getProducts(store);
    const shippingFeeDetails = getCartShippingTotals(store);
    return {
        items: getSubtotal(getCartItems(store), products),
        shippingFee: (shippingFeeDetails.base || 0) + (shippingFeeDetails.extraFee || 0) + (shippingFeeDetails.increase || 0),
        includingTax: !getCompanyOption(company, 'exemptTax'),
        tax: (forceReturnTax || !getCompanyOption(company, 'exemptTax')) ? (getTotalTax(getCartItems(store), products) + (shippingFeeDetails.tax || 0)) : 0,
        taxRates: unique([...getTaxRates(getCartItems(store, products)), shippingFeeDetails.taxRate]),
        totalTTC: getCartTotalValue(store),
    };
}

export function formatAnalyticsItems(items) {
    const getCategories = item => {
        const categories = {};
        (item.categories || []).forEach((category, index) => {
            let categoryFieldName = 'item_category';
            if(index > 0) {
                categoryFieldName += index;
            }
            categories[categoryFieldName] = category;
        });
        return categories;
    };
    return (items || []).map(item => ({
        item_id: item.slug || slugify(item.name),
        item_name: item.name,
        price: formatPrice(parseFloat(item.priceTTC) * parseFloat(config.google.analytics.alterationRate || 1)),
        quantity: parseInt(item.quantity, 10),
        item_brand: 'Keesbo',
        ...getCategories(item),
    }));
}

export function trackAnalytics(order) {
    if(typeof window !== 'undefined') {
        if(config.google.analytics.trackingCode) {
            (order.items || []).forEach(item => {
                ReactGA.plugin.execute('ecommerce', 'addItem', {
                    'id': order.code, // Transaction ID. Required.
                    'name': item.name, // Product name. Required.
                    'sku': item.sku, // SKU/code.
                    'price': item.unitPriceHT, // Unit price.
                    'quantity': item.quantity, // Quantity.
                });
                ReactGA.plugin.execute('ec', 'addProduct', {
                    id: item.sku,
                    name: item.name,
                    price: item.unitPriceHT,
                    quantity: item.quantity,
                });
            });

            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
                id: order.code,
                revenue: order.totals.totalTTC,
                tax: order.totals.tax,
                shipping: order.totals.shippingFee,
                currency: 'EUR',
            });
            ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                'id': order.code,
                revenue: order.totals.totalTTC,
                tax: order.totals.tax,
                shipping: order.totals.shippingFee,
                currency: 'EUR',
            });
            ReactGA.plugin.execute('ecommerce', 'send');
            ReactGA.plugin.execute('ec', 'send');
        } else {
            console.error('GA not configured');
        }

        if(window.dataLayer) {
            window.dataLayer.push({ ecomm_pagetype: 'purchase' });
            window.dataLayer.push({ ecomm_prodid: (order.items || []).map(item => `${item.slug || item._id}`.toLowerCase()) });
            window.dataLayer.push({ ecomm_totalvalue: order.totals.totalTTC * parseFloat(config.google.analytics.alterationRate || 1) });
        } else {
            console.error('DataLayer does not exist');
        }

        if(window.fbq) {
            window.fbq('track', 'Purchase', {
                currency: 'EUR',
                value: order.totals.totalTTC,
                contents: (order.items || []).map(item => {
                    return {
                        id: item.productId,
                        name: item.name,
                        quantity: item.quantity,
                    };
                }),
            });
        } else {
            console.error('FbPixel not configured');
        }
    }
}

// Actions
export function setCartCompany(companyId) {
    return {
        type: SET_COMPANY,
        companyId,
    };
}
export function setCartItem(item) {
    return {
        type: SET_ITEM,
        item,
    };
}

export function removeItem(item) {
    return {
        type: REMOVE_ITEM,
        item,
    };
}

export function clearCart() {
    return {
        type: CLEAR_CART,
    };
}

export function setShippingAddress(address = {}) {
    return {
        type: SET_SHIPPING_ADDRESS,
        address,
    };
}

export function setShippingMethod(method = {}) {
    return {
        type: SET_SHIPPING_METHOD,
        method,
    };
}

export function setCartExpedition(date) {
    return {
        type: SET_EXPEDITION_DATE,
        date,
    };
}

// export function setCartSubscriptionPeriod(periodUnit, periodValue) {
//     return {
//         type: SET_CART_SUBSCRIPTION,
//         subscription: {
//             periodIntervalUnit: periodUnit,
//             periodIntervalValue: periodValue,
//         },
//     };
// }
//
// export function removeCartSubscription() {
//     return {
//         type: REMOVE_CART_SUBSCRIPTION,
//     };
// }
