import callApi from '../../util/apiCaller';

export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';

export function getNotificationsByTargetRequest(targetType, targetId) {
    return dispatch => callApi(`notifications/${targetType}/${targetId}`).then(res => {
        return res && res.notifications;
    }).catch(error => {
        return null;
    });
}

export function getUnreadNotificationsRequest() {
    return dispatch => callApi('notifications/unread').then(res => {
        res && dispatch(setUnreadNotifications(res.notifications));
        return res && res.notifications;
    }).catch(error => {
        return null;
    });
}

export function addNotificationRequest(targetType, targetId, message, values = {}, authorId = null, options = {}) {
    return dispatch => callApi('notification/edit', 'post', { notification: { targetType, target: targetId, message, values, author: authorId, options } }).then(result => {
        return result && result.notification;
    }).catch(err => {
        console.error(err);
    });
}

export function editNotificationRequest(notification) {
    return dispatch => callApi('notification/edit', 'post', { notification }).then(result => {
        return result && result.notification;
    }).catch(err => {
        console.error(err);
    });
}

export function setNotificationAsViewedRequest(notificationId) {
    return dispatch => callApi(`notification/${notificationId}/view`, 'post').then(result => {
        result && result.ok && dispatch(setNotificationAsRead(notificationId));
        return result && result.ok;
    }).catch(err => {
        console.error(err);
    });
}

export function removeNotificationRequest(notificationId) {
    return dispatch => callApi(`notification/${notificationId}/remove`, 'delete').then(result => {
        return result && result.ok;
    }).catch(err => {
        console.error(err);
    });
}

export function getUnreadNotifications(store) {
    return store.notifications.unread;
}

export function getNotificationOption(notification, option) {
    return (notification && notification.options && notification.options[option]) || '';
}

export function isNotificationViewed(notification, userId) {
    return notification && (notification.viewers || {})[userId];
}

export function getNotificationAuthorId(notification) {
    return notification && notification.author && (notification.author._id || notification.author);
}

export function setUnreadNotifications(notifications) {
    return {
        type: SET_UNREAD_NOTIFICATIONS,
        notifications,
    };
}

export function setNotificationAsRead(notificationId) {
    return {
        type: SET_NOTIFICATION_AS_READ,
        notificationId: notificationId !== 'all' && notificationId,
    };
}
