import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import config from '../../../../config';

import { getCartItemFromProduct, getCartItemByProduct, setCartItem, formatAnalyticsItems } from '../../../Cart/CartActions';
import { getProductPrice } from '../../ProductActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getCurrentCurrency } from '../../../Currency/CurrencyActions';

function ProductAddToCart({ dispatch, intl, product, item, user, onAdded, color, currency, ...props }) {
    const [isAdded, setIsAdded] = useState(false);

    const productPrice = getProductPrice(product, 1);

    const handleTrackAddToCart = (quantity = 1, diff = 0) => {
        if(typeof window !== 'undefined') {
            if(config.google.analytics.trackingCodeGA4) {
                // GA: New
                console.log(ReactGA4.event(diff > 0 ? 'add_to_cart' : 'remove_from_cart', {
                    currency,
                    value: productPrice,
                    items: formatAnalyticsItems([{ ...product, priceTTC: productPrice, quantity }]),
                }));
            }

            if(window.fbq) {
                window.fbq('track', 'AddToCart', {
                    content_name: product.name,
                    content_category: product.brand,
                    content_ids: [product._id],
                    content_type: 'product',
                    value: productPrice,
                    currency: 'EUR',
                });
            }
        }
    };

    const handleAddToCart = () => {
        product && dispatch(setCartItem(getCartItemFromProduct(product, item ? item.quantity + 1 : 1, intl.locale, user)));
        setIsAdded(true);
        handleTrackAddToCart(item.quantity + 1, 1);
        setTimeout(() => {
            setIsAdded(false);
        }, 2000);
    };

    if(product) {
        return (
            <Button color={isAdded ? 'success' : color} outline={!isAdded} onClick={handleAddToCart} {...props}>
                {
                    isAdded
                    ? <span><FontAwesomeIcon icon="check-circle" /> <FormattedMessage id="cartAddedTo" defaultMessage="Added to cart" /></span>
                    : <span><FontAwesomeIcon icon="shopping-basket" /> <FormattedMessage id="cartAddTo" defaultMessage="Add to cart" /></span>
                }
            </Button>
        );
    }
    return null;
}
// <Button color="secondary" size="sm" outline block><FontAwesomeIcon icon="eye" /></Button>

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        currency: getCurrentCurrency(store),
        user,
        item: getCartItemByProduct(store, props.product),
    };
}

ProductAddToCart.defaultProps = {
    user: null,
    onAdded: null,
    color: '',
    currency: '',
    item: null,
};

ProductAddToCart.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    product: PropTypes.object.isRequired,
    item: PropTypes.object,
    onAdded: PropTypes.func,
    color: PropTypes.string,
    currency: PropTypes.string,
};

export default connect(mapStateToProps)(injectIntl(ProductAddToCart));
