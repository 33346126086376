import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, ButtonGroup, Button, Form, FormGroup, FormText, Label, Input, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { contactRequest } from '../../ContactActions';
import { displayErrors } from '../../../Error/ErrorActions';

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: (props.user && props.user.firstName) || '',
            lastName: (props.user && props.user.lastName) || '',
            email: (props.user && props.user.email) || '',
            phone: '',
            message: '',
            isProcessing: false,
            isSent: false,
            errorFields: [],
        };
    }

    handleChange = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState(
            { [name]: value },
        );
    }

    hasErrorField = name => {
        return this.state.errorFields && this.state.errorFields.indexOf(name) !== -1;
    }

    handleSubmit = event => {
        event.preventDefault();

        if(this.state.email && this.state.message) {
            this.setState({
                isProcessing: true,
            });

            this.props.dispatch(contactRequest({
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phone: this.state.phone,
            }, this.state.message)).then(result => {
                this.setState({
                    isProcessing: false,
                });
                if(result) {
                    this.setState({
                        isSent: true,
                    });
                    displayErrors('success', this.props.intl.formatMessage({
                        id: 'alertContactSuccess',
                        defaultMessage: 'Well received!',
                    }));
                } else {
                    displayErrors('error', this.props.intl.formatMessage({
                        id: 'alertContactError',
                        defaultMessage: 'Unable to send your message! Please retry...',
                    }));
                }
            });
        } else {
            const errorFields = [];
            if(!this.state.email) {
                errorFields.push('email');
            }
            if(!this.state.message) {
                errorFields.push('message');
            }
            this.setState({
                errorFields,
            });
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.isSent
                    ? (
                        <Alert color="success" className="text-center">
                            <h4><FontAwesomeIcon icon="thumbs-up-o" /> <FormattedMessage id="alertContactSuccess" defaultMessage="Well received!" /></h4>
                            {this.props.closeForm && <Button color="success" outline tag={Link} to="/"><FontAwesomeIcon icon="chevron-left" /> <FormattedMessage id="back" defaultMessage="Back" /></Button>}
                        </Alert>
                    ) : (
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col xs="12" sm="6">
                                <FormGroup className={classnames(this.hasErrorField('firstName') && 'has-danger', '')}>
                                    <Label for="firstNameField"><FormattedMessage id="userFirstName" defaultMessage="First name" /></Label>
                                    <Input type="text" name="firstName" id="firstNameField" className={this.hasErrorField('firstName') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} placeholder="" value={this.state.firstName} />
                                </FormGroup>
                                </Col>
                            <Col>
                                <FormGroup className={classnames(this.hasErrorField('lastName') && 'has-danger', '')}>
                                    <Label for="lastNameField"><FormattedMessage id="userLastName" defaultMessage="Last name" /></Label>
                                    <Input type="text" name="lastName" id="lastNameField" className={this.hasErrorField('lastName') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} placeholder="" value={this.state.lastName} />
                                </FormGroup>
                            </Col>
                            </Row>
                            <FormGroup className={classnames(this.hasErrorField('email') && 'has-danger', '')}>
                                <Label for="emailField"><FormattedMessage id="userEmail" defaultMessage="Email" /></Label>
                                <Input type="text" name="email" id="emailField" className={this.hasErrorField('email') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} placeholder="Email" value={this.state.email} />
                            </FormGroup>
                            <FormGroup className={classnames('')}>
                                <Label for="phoneField"><FormattedMessage id="userPhone" defaultMessage="Phone" /></Label>
                                <Input type="text" name="phone" id="phoneField" onChange={this.handleChange} placeholder="+33..." value={this.state.phone} />
                            </FormGroup>
                            <FormGroup className={classnames(this.hasErrorField('message') && 'has-danger', '')}>
                                <Label for="messageField"><FormattedMessage id="contactMessage" defaultMessage="Message" /></Label>
                                <Input type="textarea" name="message" id="messageField" className={this.hasErrorField('message') ? 'is-invalid form-control-danger' : ''} rows="4" onChange={this.handleChange} value={this.state.message} />
                            </FormGroup>
                            <FormGroup className="mt-3">
                                <ButtonGroup className="d-flex">
                                    {this.props.closeForm && <Button color="primary" outline onClick={event => this.props.closeForm()}><FontAwesomeIcon icon="chevron-left" /> <FormattedMessage id="back" defaultMessage="Back" /></Button>}
                                    <Button color="secondary" disabled={!(this.state.email && this.state.message) || this.state.isProcessing}>{this.state.isProcessing ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="paper-plane" />} <FormattedMessage id="contactPageButton" defaultMessage="Send" /></Button>
                                </ButtonGroup>
                            </FormGroup>
                        </Form>
                    )
                }
            </div>
        );
    }
}

ContactForm.defaultProps = {
    user: null,
    closeForm: null,
};

ContactForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    closeForm: PropTypes.func,
};

function mapStateToProps(store, props) {
    return {
        user: store.users.user,
    };
}

export default connect(mapStateToProps)(injectIntl(ContactForm));
