import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';

import { getContentBlockItemsByName, getContentBlockMixedItems, getContentBlockMixedItem } from '../../../Cms/CmsActions';

import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem';

function PanelMainMenu({ panel, isMobile }) {
    const [isOpen, toggle] = useState(false);
    const links = getContentBlockItemsByName(panel.blocks, 'links');

    if(links && links.length) {
        return (
            <div className={classnames('general-menu', isMobile && ['mt-4', 'mobile'])}>
                <Nav>
                    {links.filter(link => getContentBlockMixedItem(link, 'mainLevel') && getContentBlockMixedItem(link, 'mainLevel').path).map((link, index) => (
                        <Fragment key={index}>
                            {
                                getContentBlockMixedItems(link, 'subLevel') && getContentBlockMixedItems(link, 'subLevel').length
                                ? (
                                    <UncontrolledDropdown isOpen={isOpen} onMouseEnter={() => toggle(true)} onMouseLeave={() => toggle(false)}>
                                        <DropdownToggle tag={Link} to={getContentBlockMixedItem(link, 'mainLevel').path} className={classnames(isOpen && ['border-bottom-warning', 'open'])}>
                                            {getContentBlockMixedItem(link, 'mainLevel').text}
                                        </DropdownToggle>
                                        <DropdownMenu className="general-submenu">
                                            {getContentBlockMixedItems(link, 'subLevel').map(sub => <DropdownItem key={sub.path} tag={Link} to={sub.path} className="text-dark">{sub.text}</DropdownItem>)}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )
                                : <NavItem><CmsBlockItem item={getContentBlockMixedItem(link, 'mainLevel')} type="link" /></NavItem>
                            }
                        </Fragment>
                    ))}
                </Nav>
            </div>
        );
    }
    return null;
}

PanelMainMenu.defaultProps = {
    isMobile: false,
};

PanelMainMenu.propTypes = {
    panel: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
};

export default PanelMainMenu;

export const PanelMainMenuModel = {
    type: 'panel',
    name: 'mainMenu',
    blocks: [{
        name: 'links',
        max: 4,
        type: 'mixed',
        blocks: [
            {
                name: 'mainLevel',
                type: 'link',
                max: 1,
            },
            {
                name: 'subLevel',
                type: 'link',
            },
        ],
    }],
};
