import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

import PageWrapper from '../../../../components/Content/PageWrapper';
import ContactForm from './ContactForm';
import CmsPanel from '../../../Cms/components/content/CmsPanel';

function PanelContactForm(props) {
    return (
        <div className="header-cover bg-alt2 py-5 text-white" style={{ marginBottom: '-70px' }}>
            <Container className="pb-5">
                <PageWrapper>
                    <div className="px-2 px-md-0">
                        <h2 className="mb-2 title-border title-border-white font-tertiary font-weight-normal font-italic text-center text-secondary"><FormattedMessage id="contactPageTitle" defaultMessage="Contact us" /></h2>
                        <div className="text-center">
                            <CmsPanel slug="contact-panel-info" />
                        </div>

                        <div className="mt-3">
                            <ContactForm />
                        </div>
                    </div>
                </PageWrapper>

            </Container>
        </div>

    );
}

export default PanelContactForm;
