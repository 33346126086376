import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import { capitalizeFirstLetter } from '../../../../util/text';

import { getLanguagePath } from '../../../Intl/IntlActions';
import withRouter from '../../../../components/Router/WithRouter';

const Breadcrumbs = ({ location, intl, crumbs }) => {
    const renderCrumb = (crumb, index) => {
        return (
            <span key={index}>
                {
                    typeof crumb === 'object'
                    ? <Link to={crumb.link} className={classnames(index + 1 === crumbs.length && 'text-secondary')}>{crumb.text}</Link>
                    : <span className={classnames(index + 1 === crumbs.length && 'text-secondary')}>{crumb}</span>
                }
                {index + 1 < crumbs.length && ' > '}
            </span>
        );
    };

    // console.log(location, location.pathname.split('/'));
    // Enabled on all pages except home & cart
    const pathParts = location.pathname.split('/').filter(part => part);
    const isEnabled = pathParts.length > 1 && !location.pathname.includes('/cart');

    if(!crumbs || !crumbs.length) {
        if(typeof window !== 'undefined' && window.document) {
            const titleParts = `${window.document.title}`.split('-');
            titleParts.pop();
            crumbs = [titleParts.join('-')];
        } else {
            crumbs = [capitalizeFirstLetter(pathParts.join(' ').replace(/-/g, ' '))];
        }
    }

    pathParts.shift();

    if(isEnabled) {
        return (
            <div className="breadcrumbs p-2 text-primary">
                <Link to={getLanguagePath('/', intl.locale)} className=""><FormattedMessage id="appHome" defaultMessage="Home" /></Link>
                {' > '}
                {crumbs.map(renderCrumb)}
            </div>
        );
    }
    return null;
};

Breadcrumbs.defaultProps = {
    crumbs: [],
};

Breadcrumbs.propTypes = {
    location: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    crumbs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

export default withRouter(injectIntl(Breadcrumbs));
