import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageWrapper from '../../../components/Content/PageWrapper';
import AppLogo from '../../App/components/view/AppLogo';

function BugPage({ error, search, intl, resetErrorBoundary }) {
    console.error(error);
    console.log('BugPage::: Displayed');

    useEffect(() => {
        if(typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);
            if(!params.get('reload') && process.env.NODE_ENV !== 'development') {
                setTimeout(() => {
                    let url = window.location.href;
                    const reloadParam = 'reload=1';
                    if(url.indexOf('?') > -1) {
                        url += `&${reloadParam}`;
                    } else {
                        url += `?${reloadParam}`;
                    }
                    window.location.href = url;
                }, 100);
            }
        }
    }, []);

    const handleGoToHome = () => window.open(`/${intl.locale}/`, '_self');

    const handleReload = () => window.location.reload();

    return (
        <div className="container-md page-wrapper bg-company text-center">
            <PageWrapper>
                <AppLogo />
                <div className="card mt-5 mb-5 shadow text-center">
                    <div className="card-header">
                        <h2 className="card-title">
                            <FontAwesomeIcon icon="exclamation-triangle" /> <FormattedMessage id="appBackHomeTitle" defaultMessage="Wrong Way..." />
                        </h2>
                    </div>
                    <div className="card-bodypx-3">
                        <FormattedMessage id="appBugErrorText" defaultMessage="Something went wrong.... SORRY ! Please click on the link below to reload this page..." /><br />
                        <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary" onClick={handleGoToHome}><FontAwesomeIcon icon="home" /> <FormattedMessage id="appHome" defaultMessage="Home" /></button>
                            <button type="button" className="btn btn-outline-primary" onClick={handleReload}><FontAwesomeIcon icon="sync" /> <FormattedMessage id="refresh" defaultMessage="Refresh" /></button>
                            {false && <button type="button" className="btn btn-outline-primary" onClick={resetErrorBoundary}><FontAwesomeIcon icon="sync" /> </button>}
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
}

BugPage.propTypes = {
    search: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
};

export default injectIntl(BugPage);
