import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import config from '../../config';

import AppLogo from '../../modules/App/components/view/AppLogo';

import styles from './Loader.css';

export default class Loader extends Component {
    componentDidMount() {
        NProgress.start();
    }

    componentWillUnmount() {
        NProgress.done();
    }

    render() {
        if(!this.props.hideContent) {
            return (
                <div className={`${styles.wrapper} loader-wrapper`} style={{ paddingTop: '150px', minHeight: '300px', textAlign: 'center' }}>
                    <AppLogo imageProps={{ style: { maxWidth: '100px' } }} /><br /><br />
                    <FontAwesomeIcon icon="spinner" spin size="lg" className="text-primary" />
                </div>
            );
        }
        return null;
    }
}

Loader.defaultProps = {
    hideContent: false,
};

Loader.propTypes = {
    hideContent: PropTypes.bool,
};
