// Import Actions
import { SET_GROUPS, SET_COMPANY_GROUPS } from './GroupActions';

// Initial State
export const initialState = {
    data: [],
    companyGroups: null,
};

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_GROUPS: {
            return {
                ...state,
                data: action.groups,
            };
        }

        case SET_COMPANY_GROUPS: {
            return {
                ...state,
                companyGroups: action.groups,
            };
        }

        default:
            return state;
    }
};

export default UserReducer;
