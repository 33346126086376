import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CmsBlock from '../../content/CmsBlock';

const NoticeDefault = ({ notice }) => {
    return (
        <Fragment>
            {(notice.blocks || []).map((subBlock, index) => <CmsBlock key={subBlock.id || index} block={subBlock} />)}
        </Fragment>
    );
};

NoticeDefault.propTypes = {
    notice: PropTypes.object.isRequired,
};

export default NoticeDefault;

export const NoticeDefaultModel = {
    type: 'notice',
    name: 'default',
    blocks: [
        {
            name: 'content', // name or id; MUST be unique
            label: 'Content', // Display name, if not set, name is used
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
        {
            name: 'image', // name or id; MUST be unique
            label: 'Image', // Display name, if not set, name is used
            max: 1, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
        {
            name: 'link', // name or id; MUST be unique
            label: 'Link', // Display name, if not set, name is used
            max: 1, // max same items allowed
            type: 'link', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
    ],
};
