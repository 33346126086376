// Pages
import PageDefault, { PageDefaultModel } from './components/model/pages/PageDefault';
// import PageTest, { PageTestModel } from './components/model/pages/PageTest';

import HomePage, { HomePageModel } from '../App/pages/view/HomePage';
import ProductListPage, { ProductListPageModel } from '../Product/pages/list/ProductListPage';
import PageMenu, { PageMenuModel } from './components/model/pages/PageMenu';

// Blocks
import PanelDefault, { PanelDefaultModel } from './components/model/panels/PanelDefault';
import PanelFooterLinks, { PanelFooterLinksModel } from '../App/components/view/PanelFooterLinks';
import PanelMainMenu, { PanelMainMenuModel } from '../App/components/menu/PanelMainMenu';
import PanelCategory, { PanelCategoryModel } from '../Category/components/view/PanelCategory';

// Notices
import NoticeDefault, { NoticeDefaultModel } from './components/model/notices/NoticeDefault';

export const CMS_PAGE_MODEL_DEFAULT = PageDefaultModel;
export const CMS_PAGE_MODELS = [PageDefaultModel, HomePageModel, ProductListPageModel, PageMenuModel];

export const CMS_PANEL_MODEL_DEFAULT = PanelDefault;
export const CMS_PANEL_MODELS = [PanelDefaultModel, PanelMainMenuModel, PanelFooterLinksModel, PanelCategoryModel];

export const CMS_NOTICE_MODELS = [NoticeDefaultModel];

export function getModels(type = null) {
    return [...CMS_PAGE_MODELS, ...CMS_PANEL_MODELS, ...CMS_NOTICE_MODELS].filter(model => type === null || (model && type === model.type));
}

export function getModel(type, name) {
    return getModels(type).find(model => model.name === name);
}

export function getPageModelViewer(model) {
    switch(model) {
        // Add custom model page
        // case 'xyz':
        //     return <PageXyz page={this.props.page} />;
        case 'home':
            return HomePage;

        case 'products':
            return ProductListPage;

        case 'pageMenu':
            return PageMenu;

        // case 'test':
        //     return PageTest;

        case 'default':
        default:
            return PageDefault;
    }
}

export function getPanelModelViewer(model) {
    switch(model) {
        // Add custom model page
        // case 'xyz':
        //     return <PageXyz page={this.props.page} />;
        case 'mainMenu':
            return PanelMainMenu;

        case 'footerLinks':
            return PanelFooterLinks;

        case 'categories':
            return PanelCategory;

        case 'default':
        default:
            return PanelDefault;
    }
}

export function getNoticeModelViewer(model) {
    switch(model) {
        // Add custom model page

        case 'default':
        default:
            return NoticeDefault;
    }
}
