import React from 'react';
import PropTypes from 'prop-types';

export default function PageTitle(props) {
    return (
        <div className="d-md-flex align-items-center justify-content-md-between mb-4 p-2 p-md-0 border-bottom text-center text-md-left">
            {props.children}
        </div>
    );
}

PageTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
};
