import callApi from '../../util/apiCaller';

import { getTotalWeight, getSubtotal, getItemsPackFill, getPackages } from '../Cart/CartActions';

export const SET_GROUPS = 'SET_GROUPS';
export const SET_COMPANY_GROUPS = 'SET_COMPANY_GROUPS';

export const GROUP_DEFAULT = 'general';

export function getGroupsRequest() {
    return dispatch => {
        return callApi('groups').then(res => {
            if(res.groups) {
                dispatch(setGroups(res.groups));
            }
            return res.groups;
        }).catch(error => {
            return null;
        });
    };
}

export function getCompanyGroupsRequest(identifiers = null, setOnCurrentCompany = true) {
    return dispatch => {
        return callApi(`group${identifiers && identifiers.length ? `/${identifiers.join('+')}` : ''}`).then(res => {
            if(res.groups && setOnCurrentCompany) {
                dispatch(setCompanyGroups(res.groups));
            }
            return res.groups;
        }).catch(error => {
            return null;
        });
    };
}

export function editUserGroupRequest(group) {
    return dispatch => {
        return callApi('group/edit', 'post', { group }).then(res => {
            res.group && dispatch(getGroupsRequest());
            return res.group;
        }).catch(err => {
            return null;
        });
    };
}

export function getGroups(store) {
    return store.groups.data || [];
}

export function getCompanyGroups(store) {
    return store.groups.companyGroups || [];
}

export function doesUserGroupHasCondition(store, key) {
    const groups = getCompanyGroups(store);
    return groups && groups.reduce((conditions, group) => conditions.concat(group.condition), []).find(condition => condition.key === key);
}

export function isConditionPercentValue(condition) {
    return (condition || {}).value.indexOf('%') !== -1;
}

export function getConditionValue(condition) {
    let conditionValue = parseFloat(condition.value);
    if(isConditionPercentValue(condition)) {
        conditionValue = parseFloat(condition.value.replace('%', ''));
    }
    return conditionValue;
}

export function getConditionValueToCheck(condition, items) {
    let valueToCheck = 0;

   if(condition.key === 'weight') { // check order total weight for all suborderitems
       valueToCheck = getTotalWeight(items);
   } else if(condition.key === 'subtotal') { // check order sub total for all suborderitems
       valueToCheck = getSubtotal(items);
   } else if(condition.key === 'packFill') { // check pack filling
       valueToCheck = getItemsPackFill(items, isConditionPercentValue(condition));
   } else if(condition.key === 'packCount') {
       valueToCheck = getPackages(items).length;
   } else {
       console.error('ConditionKeyMissing');
   }
   return valueToCheck;
}

export function isConditionChecked(condition, items, exceptions = []) {
    // console.log('isConditionChecked ::: Start', condition, items, exceptions);

    if(exceptions && exceptions.includes(condition.key)) {
        // console.log('isConditionChecked ::: Allowed by exceptions');
        return true;
    }

    const conditionValue = getConditionValue(condition);
    const valueToCheck = getConditionValueToCheck(condition, items);

    // console.log('isConditionChecked ::: Check', condition.key, `${valueToCheck}${isConditionPercentValue(condition) ? '%' : ''} ${condition.limit === 'min' ? '>' : '<'}${condition.tolerance === 'exclusive' ? '' : '='} ${conditionValue}${isConditionPercentValue(condition) ? '%' : ''}`);
    if(condition.limit === 'min') {
        if(condition.tolerance === 'exclusive') {
            return valueToCheck > conditionValue;
        }
        // inclusive
        return valueToCheck >= conditionValue;
    }
    // max
    if(condition.tolerance === 'exclusive') {
        return valueToCheck < conditionValue;
    }
    // inclusive
    return valueToCheck <= conditionValue;
}

export function getConditionCheckPercentage(condition, items) {
    return (getConditionValueToCheck(condition, items) * 100) / getConditionValue(condition);
}

export function checkCompanyGroupConditions(group, items) {
    return (group.conditions || []).every(condition => isConditionChecked(condition, items));
}

export function setGroups(groups) {
    return {
        type: SET_GROUPS,
        groups,
    };
}

export function setCompanyGroups(group) {
    return {
        type: SET_COMPANY_GROUPS,
        group,
    };
}
