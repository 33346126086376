import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { getContentBlockItemsByName, getContentBlockItemValue } from '../../../Cms/CmsActions';

import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem';

const PanelFooterLinks = ({ panel }) => {
    const title = getContentBlockItemValue(panel.blocks, 'title');
    return (
        <Fragment>
            <h6>{title}</h6>
            <ul className="list-unstyled ms-2">
                {getContentBlockItemsByName(panel.blocks, 'links').map((link, index) => (
                    <li className="pb-1" key={index}><CmsBlockItem item={link} type="link" /></li>
                ))}
            </ul>
        </Fragment>
    );
};

PanelFooterLinks.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelFooterLinks;

export const PanelFooterLinksModel = {
    type: 'panel',
    name: 'footerLinks',
    blocks: [
        {
            name: 'title',
            type: 'text',
            max: 1,
        },
        {
            name: 'links',
            type: 'link',
        },
    ],
};
