import callApi, { callExternalApi } from '../../util/apiCaller';

import { addError } from '../Error/ErrorActions';

// Export Constants
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SET_RATES = 'SET_RATES';

export const CURRENCY_DEFAULT = 'EUR';

export function setCurrencyRequest(newCurrency) {
    return dispatch => {
        if(newCurrency && isCurrencyEnabled(newCurrency)) {
            return callApi(`user/currency/${newCurrency}`).then(res => {
                return res;
            }).catch(error => {
                // dispatch(addError(error));
            });
        }
    };
}

export function getCurrencyRatesRequest() {
    return dispatch => {
        const enabledCurrencies = getEnabledCurrencies().filter(currency => currency !== CURRENCY_DEFAULT);
        if(enabledCurrencies.length > 0) {
            return callApi(`currencies?base=${CURRENCY_DEFAULT}&symbols=${enabledCurrencies.join(',')}`).then(res => {
                dispatch(setCurrencyRates(res.rates));
                return res;
            }).catch(error => {
                dispatch(addError(error));
                return null;
            });
        }
        return Promise.resolve({ [CURRENCY_DEFAULT]: 1 });
    };
}

export function getEnabledCurrencies() {
    return ['EUR']; /* , 'CHF', 'GBP', 'SEK' */
}

export function isCurrencyEnabled(currency) {
    return getEnabledCurrencies().includes(currency);
}

export function getCurrencySymbol(currency) {
    switch(currency) {
        case 'GBP':
            return '£';

        case 'SEK':
            return 'Kr';

        case 'CHF':
            return 'Chf';

        case 'EUR':
            return '€';

        default:
            return currency;
    }
}

export function initCurrency(user) {
    return dispatch => {
        if(user && user.currency) {
            dispatch(switchCurrency(user.currency));
        }
    };
}

export function getCurrentCurrency(store) {
    return store.currency.currency;
}

export function switchCurrency(newCurrency) {
    if(newCurrency && isCurrencyEnabled(newCurrency)) {
        return {
            type: SWITCH_CURRENCY,
            currency: newCurrency,
        };
    }
    return {};
}

export function setCurrencyRates(rates) {
    return {
        type: SET_RATES,
        rates,
    };
}
