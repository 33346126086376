import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, InputGroup, Input, ButtonGroup, Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { capitalizeFirstLetter } from '../../../../util/text';
import { dateFormat } from '../../../../util/date';

import { getCompaniesRequest } from '../../../Company/CompanyActions';
import { getUsersRequest, getLoggedUser } from '../../../User/UserActions';
import { getOrdersRequest } from '../../../Order/OrderActions';

class SearchMini extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocus: false,
            search: '',
            data: {},
        };
    }

    handleChange = event => {
        const { target } = event;
        const { name, value } = target;
        this.setState({
            [name]: value,
        }, () => {
            if(this.state.search.length > 1) {
                setTimeout(() => {
                    this.state.search === value && this.fetch();
                }, 300);
            }
        });
    };

    fetch = () => {
        Promise.all([getCompaniesRequest, getUsersRequest, getOrdersRequest].map(caller => this.props.dispatch(caller((this.state.search || '').trim(), {}, {}, { current: 0, size: 50 })))).then(([companies, users, orders]) => {
            this.setState({
                data: {
                    companies,
                    users,
                    orders,
                },
            });
        });
    };

    toggleFocus = event => {
        setTimeout(() => {
            this.setState({
                isFocus: !this.state.isFocus,
            });
        }, 200);
    };

    renderResults() {
        if(this.state.search && this.state.search.length > 2 && this.state.isFocus) {
            return (
                <div className="w-100 py-3 px-2 bg-white border shadow" style={{ position: 'absolute', width: '100%', zIndex: '10', maxHeight: '300px', overflow: 'auto' }}>
                    {['admin'].includes(this.props.user.role) && this.renderResult('companies', 'company', ['name'], 'store', company => (
                        <ButtonGroup>
                            <Button color="secondary" tag={Link} to={`/${this.props.intl.locale}/console/company/${company._id}`}><FontAwesomeIcon icon="pen" /></Button>
                            <Button color="warning" tag={Link} to={`/${this.props.intl.locale}/console/orders/order?company=${company._id}`}><FontAwesomeIcon icon="list" /></Button>
                            <Button color="warning" tag={Link} to={`/${this.props.intl.locale}/company/${company._id}/outstanding`}><FontAwesomeIcon icon="euro-sign" /></Button>
                            <Button color="primary" tag={Link} to={`/${this.props.intl.locale}/console/company/${company._id}/users`}><FontAwesomeIcon icon="users" /></Button>
                        </ButtonGroup>
                    ))}
                    {['admin', 'manager'].includes(this.props.user.role) && this.renderResult('users', 'user', ['email', 'firstName', 'lastName', 'phone'], 'user', item => <Button color="secondary" size="sm" tag={Link} to={`/fr/console/company/${item.companies[0] || ''}/user/${item._id}`}><FontAwesomeIcon icon="eye" /></Button>)}
                    {this.renderResult('orders', 'order', item => (
                        <Fragment>
                            <strong>{item.code}{item.invoiceNumber && `/${item.invoiceNumber}`}</strong> - {dateFormat(item.dates?.expedition, 'LL')} - {item.company?.name || '???'}
                        </Fragment>
                    ), 'list')}
                </div>
            );
        }
        return null;
    }

    renderResult(key, type, displayFields, picto, renderLinks = null) {
        const data = this.state.data[key];
        if(data && data.length) {
            return (
                <div className="mb-2 pt-2 text-primary">
                    <h6 className="text-secondary"><FontAwesomeIcon icon={picto} /> <FormattedMessage id={key} defaultMessage={capitalizeFirstLetter(key)} /></h6>
                    {data.map(item => (
                        <div key={item._id} className="d-flex justify-content-between ms-3 my-1 py-1 border-bottom">
                            <div>
                                {
                                    Array.isArray(displayFields)
                                    ? displayFields.map((displayField, index) => (
                                        <Fragment key={index}>
                                            {index === 0 ? <strong>{item[displayField]}</strong> : <span>{item[displayField]}</span>}
                                            {(index + 1) < displayFields.length && ' - '}
                                        </Fragment>
                                    ))
                                    : displayFields(item)
                                }
                            </div>
                            {
                                renderLinks
                                ? renderLinks(item)
                                : <Button color="secondary" size="sm" tag={Link} to={`/${this.props.intl.locale}/console/${type}/${item._id}`}><FontAwesomeIcon icon="eye" /></Button>
                            }
                        </div>
                    ))}
                </div>
            );
        }
    }

    render() {
        return (
            <Form className="mr-auto ml-md-3 my-2 my-md-0 w-100 mw-100 navbar-search" style={{ position: 'relative', width: '40%' }} onSubmit={event => event.preventDefault()}>
                <InputGroup>
                    <Input type="text" name="search" value={this.state.search} onChange={this.handleChange} onFocus={this.toggleFocus} onBlur={this.toggleFocus} className=" border-top-0 border-left-0 small" placeholder={this.props.intl.formatMessage({ id: 'searchFor', defaultMessage: 'Search...' })} autoFocus autoComplete="off" aria-label="Search" aria-describedby="search" />
                    {this.props.onClose && <Button color="light" onClick={this.props.onClose}><FontAwesomeIcon icon="times-circle" /></Button>}
                </InputGroup>
                {this.renderResults()}
            </Form>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
    };
}

SearchMini.defaultProps = {
    onClose: null,
};

SearchMini.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

export default connect(mapStateToProps)(injectIntl(SearchMini));
