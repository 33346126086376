'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { Slide, ToastContainer } from 'react-toastify';

// Import Style
import 'react-toastify/dist/ReactToastify.css';

function ToastProvider({ children }) {
    return (
        <>
            {children}
            <ToastContainer position="top-right" transition={Slide} offset={50} autoClose={10000} limit={5} />
        </>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ToastProvider;
