import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getConfig } from '../../../Config/ConfigActions';

import HtmlComponent from '../../../../components/Text/HtmlComponent';

function Disclaimer({ disclaimer }) {
    if(disclaimer) {
        return (
            <div className="p-3 bg-secondary text-center text-white">
                <FontAwesomeIcon icon="info-circle" /> <HtmlComponent text={disclaimer} inline />
            </div>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    const { locale } = store.intl;
    return {
        locale,
        disclaimer: getConfig(store, `disclaimer-${locale}`),
    };
}

Disclaimer.defaultProps = {
    disclaimer: '',
};

Disclaimer.propTypes = {
    disclaimer: PropTypes.string,
};

export default connect(mapStateToProps)(Disclaimer);
