import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';

import config from '../../../../config';
import { getFile } from '../../../../util/file';

import { getCategories } from '../../CategoryActions';
import { getProducts } from '../../../Product/ProductActions';
import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockItemValue, getContentBlockMixedItem } from '../../../Cms/CmsActions';
import { getTranslation } from '../../../Intl/IntlActions';

import LazyLoader from '../../../../components/Content/LazyLoader';
import FileLoader from '../../../../components/Content/FileLoader';
import AppLogo from '../../../App/components/view/AppLogo';
import CmsBlockItem from '../../../Cms/components/content/CmsBlockItem';
import Price from '../../../Currency/components/view/Price';

// configReveal({ ssrFadeout: true });

export function PanelCategory({ intl, panel, categories, products }) {
    const [activeTab, toggle] = useState(0);
    const { blocks } = panel || {};
    const title = getContentBlockItemValue(blocks, 'title');
    const categoriesPanels = getContentBlockItemsByName(blocks, 'categories');
    const cta = getContentBlockItemByName(blocks, 'cta');

    const getCategory = categoryPanel => {
        const categoryId = getContentBlockMixedItem(categoryPanel, 'category');
        return categories.find(category => category._id === categoryId.text);
    };

    const getProductWithLowestPrice = category => {
        return category && (products || []).filter(product => product.isActive && product.categories.includes(category._id)).reduce((lowestPriceProduct, product) => {
            return !lowestPriceProduct || product.unitPriceHT < lowestPriceProduct.unitPriceHT ? product : lowestPriceProduct;
        }, null);
    };

    const getCategoriesTitles = () => {
        return categoriesPanels.map(categoryPanel => {
            const title = getContentBlockMixedItem(categoryPanel, 'title');
            if(title && title.text) {
                return title.text;
            }

            const category = getCategory(categoryPanel);
            if(category) {
                return getTranslation(category, 'name', intl.locale);
            }
            return null;
        }).filter(categoryTitle => categoryTitle);
    };

    if(categoriesPanels && categoriesPanels.length > 0) {
        return (
            <div className="header-cover bg-alt py-5">
                <Container>
                    <h2 className="mb-5 title-border title-border-white font-tertiary font-weight-normal font-italic text-center text-white">{title}</h2>

                    <Nav tabs fill className="mb-0 border-0">
                        {getCategoriesTitles().map((categoryTitle, index) => (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames({ active: activeTab === index }, 'px-1', 'px-lg-2', 'py-2', 'py-lg-3', 'border-0', 'rounded-0', 'font-secondary', 'text-white', activeTab === index ? 'bg-secondary' : 'bg-black')}
                                    onClick={() => { toggle(index); }}
                                >
                                    {categoryTitle}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {categoriesPanels.map((categoryPanel, index) => {
                            const lowestPriceProduct = getProductWithLowestPrice(getCategory(categoryPanel));
                            const text = getContentBlockMixedItem(categoryPanel, 'text');
                            const image = getContentBlockMixedItem(categoryPanel, 'image');
                            return (
                                <TabPane key={index} tabId={index}>
                                    <Container>
                                        <Row className="mh-100">
                                            <Col
                                                xs="4"
                                                className="d-none d-md-flex mh-100 bg-secondary"
                                                style={{
                                                    backgroundImage: `url(${getFile((image || {}).path || '')})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    minHeight: '500px',
                                                }}
                                            >
                                                {lowestPriceProduct && (
                                                    <span
                                                        className="position-relative d-inline-block m-auto bg-white shadow-lg h5 text-center font-tertiary font-italic"
                                                        style={{
                                                            width: '50%',
                                                            paddingTop: '50%',
                                                            borderRadius: '50%',
                                                            minWidth: '150px',
                                                            minHeight: '150px',
                                                        }}
                                                    >
                                                        <div className="position-absolute py-3 py-lg-4 px-3 w-100 mh-100" style={{ top: '10px', left: 0 }}>
                                                            <FormattedMessage id="productPriceFrom" defaultMessage="From" /><br />
                                                            <span className="display-4 text-secondary"><Price amount={lowestPriceProduct.unitPriceHT} /></span><br />
                                                            {lowestPriceProduct.unitType === 'kg' ? 'Kg' : <FormattedMessage id="productUnitTypePiece" defaultMessage="piece" />}
                                                        </div>
                                                    </span>
                                                )}
                                            </Col>
                                            <Col className="bg-white">
                                                <div className="p-3">
                                                    <CmsBlockItem item={text} type="html" />
                                                    {lowestPriceProduct && (
                                                        <div className="d-block d-md-none mw-100 display-5 text-center">
                                                            <FormattedMessage id="productPriceFrom" defaultMessage="From" /> <span className="display-4 text-secondary"><Price amount={lowestPriceProduct.unitPriceHT} /></span> {lowestPriceProduct.unitType === 'kg' ? 'Kg' : <FormattedMessage id="productUnitTypePiece" defaultMessage="piece" />}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        {cta && cta.path && (
                                            <div className="mt-3 text-center">
                                                <Button color="secondary" size="lg" tag={Link} to={cta.path}>{cta.text}</Button>
                                            </div>
                                        )}
                                    </Container>
                                </TabPane>
                            );
                        })}
                    </TabContent>

                </Container>
            </div>
        );
    }
    return null;
}

PanelCategory.defaultProps = {
    categories: [],
    products: [],
};

PanelCategory.propTypes = {
    intl: PropTypes.object.isRequired,
    panel: PropTypes.object.isRequired,
    categories: PropTypes.arrayOf(PropTypes.object),
    products: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(store, props) {
    return {
        categories: getCategories(store),
        products: getProducts(store),
    };
}

export default connect(mapStateToProps)(injectIntl(PanelCategory));

export const PanelCategoryModel = {
    type: 'panel',
    name: 'categories',
    blocks: [
        {
            name: 'title',
            max: 1,
            type: 'text',
        },
        {
            name: 'categories',
            type: 'mixed',
            blocks: [
                {
                    name: 'category',
                    max: 1,
                    type: 'category',
                },
                {
                    name: 'title',
                    max: 1,
                    type: 'text',
                },
                {
                    name: 'text',
                    max: 1,
                    type: 'html',
                },
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
            ],
        },
        {
            name: 'cta',
            max: 1,
            type: 'link',
        },
    ],
};
