import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, CardImg, CardImgOverlay, ButtonGroup, Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageWrapper from '../../../components/Content/PageWrapper';

function ErrorPage(props) {
    return (
        <Container className="page-wrapper bg-company pt-3">
            <PageWrapper>
                <Card className="mt-5 mb-5 shadow text-center">
                    <CardHeader>
                        <CardTitle tag="h2">
                            <FontAwesomeIcon icon="exclamation-triangle" /> <FormattedMessage id="appBackHomeTitle" defaultMessage="Wrong Way..." />
                        </CardTitle>
                    </CardHeader>
                    <CardBody className="px-3">
                        <FormattedMessage id="app404ErrorText" defaultMessage="We didn't find what you're looking for! Go back to homepage or your account" />
                        <ButtonGroup className="d-block mt-3">
                            <Button to="/" tag={Link} color="secondary" outline><FontAwesomeIcon icon="chevron-left" /> <FormattedMessage id="appBackHomeButton" defaultMessage="Back home" /></Button>
                            <Button to={`/${props.intl.locale}/user/profile`} tag={Link} color="primary" outline><FontAwesomeIcon icon="user-circle" /> <FormattedMessage id="userAccountMy" defaultMessage="My account" /></Button>
                        </ButtonGroup>
                    </CardBody>
                </Card>
            </PageWrapper>
        </Container>
    );
}

ErrorPage.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(ErrorPage);
