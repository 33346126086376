export function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

export function unique(array) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
}

export function flat(array) {
    let ret = [];
    for(let i = 0; i < array.length; i += 1) {
        if(Array.isArray(array[i])) {
            ret = ret.concat(flat(array[i]));
        } else {
            ret.push(array[i]);
        }
    }
    return ret;
}
