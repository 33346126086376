import copy from 'copy-to-clipboard';
import { nanoid, customAlphabet } from 'nanoid';

export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
}

export function copyToClipboard(text) {
    return copy(text);
}

export function textToColor(str) {
    let color = '#999999';
    if(str && str.length) {
        let hash = 0;
        for(let i = 0; i < str.length; i += 1) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        color = '#';
        for(let i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += (`00${value.toString(16)}`).substr(-2);
        }
    }
    return color;
}

export function nl2br(str, isXhtml) {
    if(typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>';
    return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

export function stripTags(text) {
    return text ? text.replace(/(<([^>]+)>)/ig, '') : text;
}

export function truncateToChar(text, stoppingCharacter = '.') {
    return text && text.indexOf(stoppingCharacter) > 0 ? text.substring(0, text.indexOf(stoppingCharacter)) : text;
}

export function truncate(str, wordsCount = 20) {
    return str.split(' ').splice(0, wordsCount).join(' ');
}

export function removeAccents(string) {
    const accents = 'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ';
    const accentsOut = 'AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg';
    return `${string}`
        .split('')
        .map((letter, index) => {
            const accentIndex = accents.indexOf(letter);
            return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
        })
        .join('');
}

export function getRandomString(size = 10, isReadable = false) {
    // polyfills.js
    if(typeof window !== 'undefined' && !window.crypto) {
        window.crypto = window.msCrypto;
    }

    return isReadable ? customAlphabet('123456789ABCDEFGHJKLMNPQRSTUVWXYZ', size)() : nanoid(size);
}
