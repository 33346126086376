import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ProductWeight({ product, hideLabel }) {
    if(product && product.weight) {
        let unit = 'Kg';
        let isNet = false;
        let { weight } = product;
        if(product.weightNet) {
            isNet = true;
            weight = product.weightNet;
        }
        if(weight < 1) {
            unit = 'g';
            weight *= 1000;
        }
        return <span>{!hideLabel && <span><FormattedMessage id="weightNet" defaultMessage="Net weight" />: </span>}{weight ? <span>{weight} {unit}</span> : 'NC'}</span>;
    }
    return null;
}

ProductWeight.defaultProps = {
    hideLabel: false,
};

ProductWeight.propTypes = {
    product: PropTypes.object.isRequired,
    hideLabel: PropTypes.bool,
};
