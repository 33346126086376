import createHmac from 'create-hmac';
import { getRandomString } from './text';

export function getHashKeys(string, salt = null, fakeEnv = '') {
    if(!salt) {
        salt = getRandomString();
    }
    const key = createHmac('sha256', salt)
        .update(`${fakeEnv || process.env.NODE_ENV}${salt}${string}`)
        .digest('hex');
    return {
        salt,
        key,
    };
}
