import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, Button } from 'reactstrap';

import CmsPanel from '../../Cms/components/content/CmsPanel';
import AppLogo from './view/AppLogo';

function PreFooter({ intl }) {
    return null;
}

PreFooter.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(PreFooter);
