// Import Actions
import { SET_COMPANIES, REMOVE_COMPANY } from './CompanyActions';
import { LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    data: [],
};

const CompanyReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                data: state.data.filter(existingCompany => !action.companies.find(company => company._id === existingCompany._id)).concat(action.companies),
            };

        case REMOVE_COMPANY:
            return {
                ...state,
                data: state.data.filter(company => company._id !== action.companyId)
            };

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default CompanyReducer;
