import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Collapse, ListGroup, ListGroupItem, Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getCartItems } from '../../CartActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getLanguagePath } from '../../../Intl/IntlActions';
import withRouter from '../../../../components/Router/WithRouter';

class CartMini extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location !== this.props.location) {
            this.setState({
                isOpen: false,
            });
        }
    }

    render() {
        const { cartItems, intl } = this.props;

        const countLimit = 3;
        const cartItemsCount = (cartItems || []).reduce((total, item) => total + item.quantity, 0);

        // TODO: Use useEffect to close collapse on route change

        return (
            <Fragment>
                <a href="#" id="cart-toggler" className="position-relative" onClick={event => { event.preventDefault(); this.setState({ isOpen: !this.state.isOpen }); }}>
                    <span className="d-inline d-md-none"><FontAwesomeIcon icon="shopping-basket" /></span> {cartItemsCount > 0 && <span className={classnames('d-inline-block', 'bg-secondary', 'text-white', 'heartbeat', 'wobble-hor-bottom', 'text-center', 'cart-icon')} style={{ width: '2em', height: '2em', borderRadius: '2em', padding: '0.1em', lineHeight: '2em', fontSize: '0.8em' }}>{cartItemsCount}</span>}
                    <span className="d-none d-md-inline"><FormattedMessage id="cartMy" defaultMessage="My cart" /></span>
                </a>
                <Collapse isOpen={this.state.isOpen} className="position-absolute p-2 bg-primary rounded-sm" style={{ top: '100%', right: 0, minWidth: '20rem', zIndex: 10 }}>
                    <ul className="list-unstyled">
                        {(cartItems || []).slice(-countLimit).reverse().map(item => (
                            <li key={item.productId}>
                                <strong className="text-secondary">{item.quantity}</strong> x {item.name}
                            </li>
                        ))}
                        {(cartItems || []).length > countLimit && (
                            <li className="mt-1">
                                ...
                            </li>
                        )}
                    </ul>
                    <Button color="secondary" size="sm" tag={Link} to={getLanguagePath('/cart/preview', intl.locale)} className="w-100 mt-2">
                        <FontAwesomeIcon icon="shopping-basket" /> <FormattedMessage id="cartMyView" defaultMessage="View my cart" />
                    </Button>
                </Collapse>
            </Fragment>
        );
    }
}

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        user,
        cartItems: getCartItems(store),
    };
}

CartMini.defaultProps = {
    user: null,
};

CartMini.propTypes = {
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(CartMini)));
