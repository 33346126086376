import React, { PropTypes, Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ButtonGroup, Button, Form, FormGroup, Label, Input, FormFeedback, FormText, Alert } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setRedirect } from '../../../App/AppActions';
import { displayErrors } from '../../../Error/ErrorActions';

import User from '../../User';
import { checkPassword } from '../../UserActions';

class UserNewPassword extends User {
    constructor(props) {
        super(props);

        Object.assign(this.state, {
            user: props.user,
            userAttributes: props.userAttributes,
            isSaving: false,
        });

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handleSubmit(event) {
        event.preventDefault();

        const errorFields = [];

        if(!this.state.password) {
            errorFields.push('password');
        } else if(!this.state.confirmPassword) {
            errorFields.push('confirmPassword');
        } else if(!checkPassword(this.state.password)) {
            errorFields.push('password');
            displayErrors('error', this.props.intl.formatMessage({
                id: 'userPasswordRestriction',
                defaultMessage: 'The password must have 6 characters!',
            }));
            // displayErrors('error', this.props.intl.formatMessage({
            //     id: 'userPasswordRestrictionAlt',
            //     defaultMessage: 'The password must have 6 characters!',
            // }));
        } else if(this.state.password !== this.state.confirmPassword) {
            errorFields.push('confirmPassword');
            displayErrors('error', this.props.intl.formatMessage({
                id: 'userPasswordConfirmationError',
                defaultMessage: 'The password and the confirmation must be the same!',
            }));
        }

        if(!errorFields.length) {
            this.setState({ isSaving: true });
            this.handleNewPassword(this.state.password).then(result => {
                this.setState({ isSaving: false });
                if(!result) {
                    displayErrors('error', this.props.intl.formatMessage({
                        id: 'userResetPasswordError',
                        defaultMessage: 'Unable to reset your password! Please retry...',
                    }));
                } else if(!this.state.newPasswordRequired) {
                    this.props.redirect !== false && this.props.dispatch(setRedirect(this.props.appRedirect || this.props.redirect || '/'));
                }
            });
        } else {
            this.setState({ errorFields });
        }
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <p>
                        <strong>
                            <FontAwesomeIcon icon="unlock-alt" /> <FormattedMessage id="userPasswordResetText" defaultMessage="Please set your personnal password. Confirm it and click on the button below." />
                        </strong>
                    </p>
                    <FormGroup>
                        <Row>
                            <Col sm="6" className={this.hasErrorField('password') ? 'has-danger' : ''}>
                                <Label for="passwordField"><FormattedMessage id="userPassword" defaultMessage="Password" /></Label>
                                <Input type="password" name="password" id="passwordField" className={this.hasErrorField('password') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} value={this.state.password} />
                                {
                                    this.hasErrorField('confirmPassword')
                                    ? <FormFeedback><FormattedMessage id="userPasswordError" defaultMessage="Please fill a correct password!" /></FormFeedback>
                                    : null
                                }
                            </Col>
                            <Col sm="6" className={this.hasErrorField('confirmPassword') ? 'has-danger' : ''}>
                                <Label for="confirmPasswordField"><FormattedMessage id="userPasswordConfirmation" defaultMessage="Password confirmation" /></Label>
                                <Input type="password" name="confirmPassword" id="confirmPasswordField" className={this.hasErrorField('confirmPassword') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} value={this.state.confirmPassword} />
                                {
                                    this.hasErrorField('confirmPassword')
                                    ? <FormFeedback><FormattedMessage id="userPasswordConfirmationError" defaultMessage="This confirmation must be the same of your password" /></FormFeedback>
                                    : null
                                }
                            </Col>
                        </Row>
                        <FormText color="secondary" className="text-center">
                            <FontAwesomeIcon icon="exclamation-triangle" /> <FormattedMessage id="userPasswordRestriction" defaultMessage="The password must have 8 characters and at least must have a special character (@&!()$*€%)!" />
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <ButtonGroup className="btn-block">
                            <Button color="success" disabled={!this.state.password || !this.state.confirmPassword || this.state.isSaving}>
                                {this.state.isSaving ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="lock" />} <FormattedMessage id="userEditPassword" defaultMessage="Edit my password" />
                            </Button>
                        </ButtonGroup>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        intl: store.intl,
        appRedirect: store.app.redirect,
    };
}

export default connect(mapStateToProps)(injectIntl(UserNewPassword));
