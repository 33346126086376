import { SET_TASKS } from './TaskActions';

// Initial State
export const initialState = {
    data: [],
    isFetching: false,
};

const TaskReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TASKS: {
            return {
                ...state,
                data: action.target ? state.data.filter(task => task.target._id !== action.target).concat(action.tasks) : action.tasks,
            };
        }

        default:
            return state;
    }
};

export default TaskReducer;
