import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../config';

import LazyLoader from '../../../../components/Content/LazyLoader';

function AppLogo({ suffix, disableLazy, imageProps }) {
    const logo = (
        <picture>
            {false && <source srcSet={`${config.url}assets/images/logo${suffix ? `-${suffix}` : ''}.webp`} />}
            <img src={`${config.url}assets/images/logo${suffix ? `-${suffix}` : ''}.png`} alt={config.application.name} {...imageProps} />
        </picture>
    );

    if(disableLazy) {
        return logo;
    }

    return (
        <LazyLoader>
            {logo}
        </LazyLoader>
    );
}

AppLogo.defaultProps = {
    suffix: '',
    disableLazy: false,
    imageProps: {},
};

AppLogo.propTypes = {
    suffix: PropTypes.string,
    disableLazy: PropTypes.bool,
    imageProps: PropTypes.object,
};

export default AppLogo;
