import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ButtonGroup, Button, Form, FormGroup, FormText, Label, Input, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from 'moment';

import { loginRequest, askLostPasswordRequest, resetPasswordRequest, checkPassword, setUserDateActivity, getUserDateActivity } from '../../UserActions';
import { displayErrors } from '../../../Error/ErrorActions';
import withRouter from '../../../../components/Router/WithRouter';

export class UserForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: props.email || '',
            password: '',
            code: props.code || '',
            resetPassword: props.code && props.email,
            isLostPasswordAsked: false,
            isSending: false,
            errorFields: [],
        };
    }

    handleChange = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState(
            { [name]: value },
        );
    }

    handleSubmit = event => {
        event.preventDefault();
        if(this.state.resetPassword) {
            if(this.isValid()) {
                this.setState({ isSending: true });
                this.props.dispatch(resetPasswordRequest(this.state.email.toLowerCase(), this.state.code, this.state.password)).then(result => {
                    this.setState({ isSending: false });
                    if(result) {
                        displayErrors('success', this.props.intl.formatMessage({
                            id: 'userResetPasswordSuccess',
                            defaultMessage: 'Password reset successed!',
                        }));
                        this.props.dispatch(loginRequest(this.state.email, this.state.password)).then(() => {
                            this.props.navigate('/');
                        });
                    } else {
                        displayErrors('error', this.props.intl.formatMessage({
                            id: 'userConfirmationCodeError',
                            defaultMessage: 'Please check your email box to get the confirmation code...',
                        }));
                    }
                });
            } else {
                const errorFields = [];

                if(!this.state.code) {
                    errorFields.push('code');
                } else if(!this.state.password || !checkPassword(this.state.password)) {
                    errorFields.push('password');
                } else if(!this.state.confirmPassword || this.state.password !== this.state.confirmPassword) {
                    errorFields.push('confirmPassword');
                }

                this.setState({ errorFields });
            }
        } else {
            this.setState({
                isSending: true,
            });
            this.props.dispatch(askLostPasswordRequest(this.state.email)).then(result => {
                this.setState({
                    isSending: false,
                });
                if(result) {
                    this.props.dispatch(setUserDateActivity('lostPassword'));
                    this.setState({
                        isLostPasswordAsked: true,
                    });
                    displayErrors('warning', this.props.intl.formatMessage({
                        id: 'userValidForgotPasswordSuccess',
                        defaultMessage: 'Check your email box to use the confirmation code to reset your password!',
                    }));
                } else {
                    displayErrors('error', this.props.intl.formatMessage({
                        id: 'userValidForgotPasswordError',
                        defaultMessage: 'Unable to send you a reset password code!',
                    }));
                }
            });
        }
    }

    isValid() {
        return this.state.code && this.state.password && checkPassword(this.state.password) && this.state.confirmPassword === this.state.password;
    }

    hasErrorField(name) {
        return this.state.errorFields && this.state.errorFields.indexOf(name) !== -1;
    }

    render() {
        return (
            <div>
                {
                    this.state.code
                    ? (
                        <FormGroup className="text-center">
                            <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userResetPasswordInfo2" defaultMessage="Reset your password by filling this fields below:" />
                        </FormGroup>
                    )
                    : null
                }
                {
                    this.state.resetPassword
                    ? (
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Row>
                                    <Col sm="6" className={this.hasErrorField('password') ? 'has-danger' : ''}>
                                        <Label for="passwordField"><FormattedMessage id="userPassword" defaultMessage="Password" /></Label>
                                        <Input type="password" name="password" id="passwordField" className={this.hasErrorField('password') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} value={this.state.password} />
                                        <FormText color="muted"><FormattedMessage id="userPasswordRestrictionAlt" defaultMessage="The password must have 6 characters!" /></FormText>
                                        {
                                            this.hasErrorField('password')
                                            ? <FormFeedback><FormattedMessage id="userPasswordError" defaultMessage="Please fill a password!" /></FormFeedback>
                                            : null
                                        }
                                    </Col>
                                    <Col sm="6" className={this.hasErrorField('confirmPassword') ? 'has-danger' : ''}>
                                        <Label for="confirmPasswordField"><FormattedMessage id="userPasswordConfirmation" defaultMessage="Password confirmation" /></Label>
                                        <Input type="password" name="confirmPassword" id="confirmPasswordField" className={this.hasErrorField('confirmPassword') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} value={this.state.confirmPassword} />
                                        <FormText color="muted"><FormattedMessage id="userPasswordConfirmationRestriction" defaultMessage="This confirmation must be the same of your password" /></FormText>
                                        {
                                            this.hasErrorField('confirmPassword')
                                            ? <FormFeedback><FormattedMessage id="userPasswordConfirmationError" defaultMessage="Please fill a confirmation same as the password field!" /></FormFeedback>
                                            : null
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <ButtonGroup className="btn-block">
                                    {this.props.closeForm ? (
                                        <Button color="light" onClick={this.props.closeForm}>
                                            <FontAwesomeIcon icon="chevron-left" /> <FormattedMessage id="back" defaultMessage="Back" />
                                        </Button>
                                    ) : null}
                                    <Button color="warning" disabled={!this.state.password || !this.state.confirmPassword || this.state.isSending}>
                                        {this.state.isSending ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="lock" />} <FormattedMessage id="userReInitPassword" defaultMessage="Reset my password" />
                                    </Button>
                                </ButtonGroup>
                            </FormGroup>
                        </Form>
                    )
                    : (
                        <Fragment>
                            {
                                !this.props.lastRequestDate || moment.utc().subtract(5, 'minutes').isSameOrAfter(this.props.lastRequestDate * 1000)
                                ? (
                                    <Form onSubmit={this.handleSubmit}>
                                        <p className="text-center">
                                            <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userResetPasswordInfo3" defaultMessage="Enter your email to reset your password. You will receive an email with instructions for setting your password." />
                                        </p>
                                        <FormGroup>
                                            <Label for="emailField"><FormattedMessage id="userEmail" defaultMessage="Email" /></Label>
                                            <Input type="text" name="email" id="emailField" className={this.hasErrorField('email') ? 'is-invalid form-control-danger' : ''} onChange={this.handleChange} placeholder={this.props.intl.messages.userEmail} value={this.state.email} />
                                        </FormGroup>
                                        <FormGroup>
                                            <ButtonGroup className="btn-block">
                                                {this.props.closeForm ? (
                                                    <Button color="light" onClick={this.props.closeForm}>
                                                        <FontAwesomeIcon icon="chevron-left" /> <span className="d-none d-sm-inline"><FormattedMessage id="back" defaultMessage="Back" /></span>
                                                    </Button>
                                                ) : null}
                                                <Button color="secondary" disabled={!this.state.email || this.state.isSending}>
                                                    {this.state.isSending ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="unlock" />} <FormattedMessage id="userReInitPassword" defaultMessage="Reset my password" />
                                                </Button>
                                            </ButtonGroup>
                                        </FormGroup>
                                    </Form>
                                )
                                : (
                                    <Fragment>
                                    {
                                        this.state.isLostPasswordAsked
                                        ? (
                                            <p className="text-success text-center">
                                                <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userValidForgotPasswordSuccess" defaultMessage="Check your email box to click on the confirmation link!" />
                                            </p>
                                        )
                                        : (
                                            <p className="text-danger text-center">
                                                <FontAwesomeIcon icon="exclamation-circle" /> <FormattedMessage id="userForgotPasswordRetryDateError" defaultMessage="You can not make another forgot password call yet. Please check your email box to get the reset password link." />
                                                <br />
                                            </p>
                                        )
                                    }
                                    </Fragment>
                                )
                            }
                        </Fragment>
                )
            }
            </div>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        lastRequestDate: getUserDateActivity(store, 'lostPassword'),
    };
}

UserForgotPassword.defaultProps = {
    lastRequestDate: '',
    email: '',
    code: '',
    closeForm: null,
};

UserForgotPassword.propTypes = {
    dispatch: PropTypes.func.isRequired,
     navigate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    lastRequestDate: PropTypes.string,
    email: PropTypes.string,
    code: PropTypes.string,
    closeForm: PropTypes.func,
};

export default connect(mapStateToProps)(injectIntl(withRouter(UserForgotPassword)));
