const envVars = (typeof window !== 'undefined' && window.env_vars) || process.env || {};

console.log('Config source', typeof window !== 'undefined' && window.env_vars && 'window.env_vars', process.env && 'process.env');
const port = envVars.PORT || 8000;
const config = {
    version: envVars.APP_VERSION || '',
    url: envVars.BASE_URL || `http://localhost:${port}/`,
    api: envVars.API_URL || `http://localhost:${port}/api`, // 'https://dev.keesbo.eu',
    ws: envVars.WEBSOCKET_URL || '',
    port,
    application: {
        name: 'Keesbo',
        email: 'contact@keesbo.eu',
        phone: '+33.6.11.83.90.21',
        seo: {
            defaultTitle: '',
        },
        options: {
        },
        taxRate: 0.1,
        order: {
            summary: {
                emails: ['ventas@jcuros.com', 'ventes@keesbo.eu'],
            },
        },
        payment: {
            transfer: {
                bankName: 'Banque Courtois',
                iban: 'FR76 ...',
                bic: '',
            },
        },
    },
    aws: {
        s3: {
            region: 'eu-west-3',
            bucket: envVars.S3_BUCKET || 'dev.keesbo.eu',
        },
    },
    google: {
        analytics: {
            trackingCode: envVars.GOOGLE_ANALYTICS_TRACKING_CODE || '',
            trackingCodeGA4: envVars.GOOGLE_ANALYTICS_TRACKING_CODE_GA4 || '',
            alterationRate: 0.25,
        },
    },
    bugsnag: {
        key: envVars.BUGSNAG_KEY || '',
    },
};
// console.log(config);
// export default config;
module.exports = config;
