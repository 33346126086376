import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

function PageWrapper({ children }) {
  return (
        <Row>
            <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                {children}
            </Col>
        </Row>
    );
}

PageWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageWrapper;
