import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactToPrint from 'react-to-print';

import ProductListExport from './ProductListExport';

class ProductListExportButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.productsExportRef = null;
    }

    render() {
        return (
            <Fragment>
                <ReactToPrint
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return <Button color="secondary" tag="a" href="#"><FontAwesomeIcon icon="download" /> <FormattedMessage id="productExportButton" defaultMessage="Download products prices" /></Button>;
                    }}
                    content={() => this.productsExportRef}
                />
                <div className="d-none">
                    <ProductListExport ref={elt => { this.productsExportRef = elt; }} products={this.props.products} categories={this.props.categories} intl={this.props.intl} />
                </div>
            </Fragment>
        );
    }
}

ProductListExportButton.propTypes = {
    intl: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default injectIntl(ProductListExportButton);
