/**
 * Root Component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { AppContainer } from 'react-hot-loader';

import ErrorBoundary from './components/Error/ErrorBoundary';
import IntlWrapper from './modules/Intl/IntlWrapper';
import BugPage from './modules/Error/pages/BugPage';
// import pageAnimator from './components/Content/PageAnimator';

// Import Routes
import AppRoutes from './routes';

// const AnimatedRoutes = pageAnimator(Routes);

// import './assets/css/public.min.css';

export default function App({ ssr, store, location, context, key, routerComponent }) {
    console.log('AppWrapper::: Render');
    const Router = routerComponent || BrowserRouter;
    const content = (
        <Provider key={key || 1} store={store}>
            <IntlWrapper>
                <ErrorBoundary errorComponent={BugPage}>
                    <Router location={location} context={context}>
                        <AppRoutes ssr={ssr} />
                    </Router>
                </ErrorBoundary>
            </IntlWrapper>
        </Provider>
    );
    return content;
}

App.defaultProps = {
    store: {},
    location: '',
    context: {},
    routerComponent: BrowserRouter,
    ssr: false,
    key: 0,
};

App.propTypes = {
    store: PropTypes.object,
    location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    context: PropTypes.object,
    key: PropTypes.number,
    ssr: PropTypes.bool,
    routerComponent: PropTypes.func,
};
