import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import config from '../../config';
import '../../util/icons';

// Import Actions
import { initApp } from './AppActions';
import { initLanguage, getCountryFromLanguage } from '../Intl/IntlActions';
import { getLoggedUser } from '../User/UserActions';

// Import Components
import Header from './components/layout/Header';
// import Menu from './components/Menu/Menu';
import Footer from './components/layout/Footer';
import Loader from '../../components/Loader/Loader';
import Disclaimer from './components/view/Disclaimer';
import ToastProvider from '../Error/components/ToasterProvider';

function App({ children, isMounted, user }) {
    const canMount = isMounted || !process.env.CLIENT;

    return (
        <ToastProvider>
            <Header />

            <Container fluid className={classnames('content-wrapper', `user-role-${(user || {}).role}`)}>
                <Disclaimer />
                <div className="content pb-6">
                    {children || <Outlet />}
                </div>
            </Container>

            <Footer />
        </ToastProvider>
    );
}

App.defaultProps = {
    user: null,
};

App.propTypes = {
    children: PropTypes.object.isRequired,
    isMounted: PropTypes.bool.isRequired,
    user: PropTypes.object,
};

function mapStateToProps(store, props) {
    return {
        isMounted: store.app.isMounted,
        user: getLoggedUser(store),
    };
}

App.need = [(params, store, query) => { return initLanguage(params.lang || query.lang, null, store); }, (params, store, query) => { return initApp(true); }];

export default connect(mapStateToProps)(App);
