import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default function ProductUnit({ product, hideSeparator }) {
    if(product && product.unitType) {
        return <span>{!hideSeparator && '/'}<span> {product.unitType === 'kg' ? 'Kg' : <FormattedMessage id="productUnitTypePiece" defaultMessage="Piece" />}</span></span>;
    }
    return null;
}

ProductUnit.defaultProps = {
    hideSeparator: false,
};

ProductUnit.propTypes = {
    product: PropTypes.object.isRequired,
    hideSeparator: PropTypes.bool,
};
