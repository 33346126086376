// Import Actions
import { SET_CONTENTS, SET_CONTENT, SET_CONTENT_TRANSLATION } from './CmsActions';

// Initial State
export const initialState = {
    data: [],
    // [
    //     {
    //         type: 'page',
    //         language: 'fr',
    //         slug: 'legal',
    //         title: 'legal mention',
    //         content: 'azerty'
    //     }
    // ]
    blocksById: {},
};

const CmsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CONTENTS:
            return {
                ...state,
                data: action.resetAll ? action.contents : state.data.filter(content => !action.contents.find(newContent => newContent._id === content._id)).concat(action.contents),
            };

        case SET_CONTENT: {
            const exists = state.data.find(content => content._id === action.content._id);
            return {
                ...state,
                data: exists ? state.data.map(content => {
                    if(content._id === action.content._id) {
                        return action.content;
                    }
                    return content;
                })
                : state.data.concat([action.content]),
            };
        }

        case SET_CONTENT_TRANSLATION:
            return {
                ...state,
                data: state.data.map(content => {
                    if(content._id === action.contentId) {
                        return {
                            ...content,
                            translations: action.translations,
                        };
                    }
                    return content;
                }),
            };

        default:
            return state;
    }
};

/* Selectors */

// Export Reducer
export default CmsReducer;
