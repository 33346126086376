import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getPriceCent, getPriceFromCent } from '../../../../util/price';

import { CURRENCY_DEFAULT, getCurrencySymbol } from '../../CurrencyActions';

class Price extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    getUniqueId() {
        return `tooltip-${Math.floor((Math.random() * 1000) + 1)}`;
    }

    getRate() {
        return Math.round(this.props.rate * 100) / 100;
    }

    getConvertedPrice() {
        if(this.props.currency === CURRENCY_DEFAULT) {
            return getPriceFromCent(getPriceCent(this.props.amount));
        }
        const rate = this.getRate();
        return getPriceFromCent(getPriceCent(this.props.amount) * rate);
        // Default to fix undefined price in renderPrice
        // return this.props.amount || 0;
    }

    getCentSeparator() {
        switch(this.props.currency) {
            case 'GBP':
            case 'CHF':
            case 'SEK':
                return '.';

            default:
                return ',';
        }
    }

    isPrefixed() {
        switch(this.props.currency) {
            case 'GBP':
            case 'CHF':
                return true;

            default:
                return false;
        }
    }

    isSymbolDecimalSeparator() {
        switch(this.props.currency) {
            case 'EUR':
                return true;

            default:
                return false;
        }
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    renderPrice() {
        const price = this.getConvertedPrice();
        const priceText = price ? price.toString().split('.') : ['0', ''];
        const plainPrice = priceText[0];
        let cents = priceText[1] || '';
        cents = cents.toString();
        if(cents < 10 && cents.length === 1) {
            cents = `${cents}0`;
        // } else if(cents >= 10 && cents.length == 1) {
        //     cents = `${cents}0`;
        }
        const separator = this.isSymbolDecimalSeparator() ? getCurrencySymbol(this.props.currency) : this.getCentSeparator();

        return (
            <span>
                {plainPrice}{cents ? (this.props.formatPrice ? <Fragment>{separator}<sup>{cents}</sup></Fragment> : `${separator}${cents}`) : (this.isSymbolDecimalSeparator() ? getCurrencySymbol(this.props.currency) : '')}
            </span>
        );
    }

    render() {
        const uniqueId = this.getUniqueId();
        return (
            // <span id={uniqueId} className="text-nowrap">
            <span className="text-nowrap">
                {this.isPrefixed() && !this.isSymbolDecimalSeparator() ? `${getCurrencySymbol(this.props.currency)} ` : null}
                {this.renderPrice()}
                {!this.isPrefixed() && !this.isSymbolDecimalSeparator() ? ` ${getCurrencySymbol(this.props.currency)}` : null}

                {this.props.currency !== CURRENCY_DEFAULT && this.props.isCalculated ? <small> <FontAwesomeIcon icon="info-circle" /></small> : null}
                {false && this.props.currency !== CURRENCY_DEFAULT && this.props.isCalculated && (
                    <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={uniqueId} toggle={this.toggle}>
                        <FormattedMessage id="priceRoundAlertTooltip" defaultMessage="This price may be rounded due to the currency conversation" />
                    </Tooltip>
                )}
            </span>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        currency: props.currency || store.currency.currency,
        rate: store.currency.rates[props.currency || store.currency.currency],
    };
}
Price.defaultProps = {
    amount: 0,
    isCalculated: false,
    formatPrice: true,
};

Price.propTypes = {
    currency: PropTypes.string.isRequired,
    rate: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCalculated: PropTypes.bool,
    formatPrice: PropTypes.bool,
};

export default connect(mapStateToProps)(Price);
