import slugify from 'slugify';
import { storagePersistent } from './storage';

import config from '../config';

const KEY_PREFIX = `${slugify(config.application.name).toLowerCase()}.${process.env.NODE_ENV}.persistor.v2.`;

export function persistData(key, data) {
    try {
        if(storagePersistent) {
            storagePersistent.setItem(`${KEY_PREFIX}${key}`, data ? JSON.stringify(data) : '');
        }
    } catch (e) {
        console.error(e);
    }
}

export function getPersistedData(key, defaultData = {}) {
    if(storagePersistent) {
        try {
            const value = storagePersistent.getItem(`${KEY_PREFIX}${key}`);
            const data = value && JSON.parse(value);
            return data || defaultData;
        } catch (e) {
            return defaultData;
        }
    }
    return defaultData;
}
