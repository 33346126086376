import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, CardImg, CardImgOverlay, CardTitle, CardBody, CardText, Badge, ButtonGroup, Button, UncontrolledCollapse, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '../../../../config';

import { getFile } from '../../../../util/file';
import { truncateToChar, stripTags } from '../../../../util/text';

import { getProductSlug, getProductPrice } from '../../ProductActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getTranslation } from '../../../Intl/IntlActions';

import FileLoader from '../../../../components/Content/FileLoader';
import HtmlComponent from '../../../../components/Text/HtmlComponent';
import ProductWeight from '../view/ProductWeight';
import ProductUnit from '../view/ProductUnit';
import ProductAddToCart from '../form/ProductAddToCart';
import Price from '../../../Currency/components/view/Price';
import CartItemQuantitySelector from '../../../Cart/components/form/CartItemQuantitySelector';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import withRouter from '../../../../components/Router/WithRouter';

function ProductListItem({ navigate, intl, product, user, isOrder, viewType, onViewProduct, isActive }) {
    const { locale } = intl;

    const canAddToCart = !!user;

    const productUnitPrice = product.unitPriceHT; // getProductPrice(product, 1, true);

    const viewProduct = event => {
        event && event.preventDefault();
        onViewProduct && !isActive && onViewProduct(product);
    };

    const image = (product.pictures || [])[0];

    if(viewType === 'list') {
        return (
            <Card className="mx-2 mx-md-0 mb-4 product-list-item overflow-hidden rounded border">
                <Row className="g-0">
                    <Col xs="12" md="4">
                        {image && (
                            <div className="product-image-wrapper bg-light" style={{ height: '260px', overflow: 'hidden' }} onClick={viewProduct}>
                                <FileLoader filename={product.pictures[0]} alt={getTranslation(product, 'name', locale)} className="card-img" style={{ maxWidth: '100%' }} />
                            </div>
                        )}
                    </Col>
                    <Col>
                        <div className="py-3 px-2">
                            <h5 className="h4 mb-md-4 text-start" style={{ minHeight: '2em' }}>{isActive ? getTranslation(product, 'name', locale) : <a href="#" onClick={viewProduct}>{getTranslation(product, 'name', locale)}</a>}</h5>
                            <HtmlComponent text={getTranslation(product, 'description', locale)} />

                            <Row className="mt-2">
                                <Col xs="6" md="12">
                                    <span id={`product-weight-${product._id}`}><ProductWeight product={product} /> <small><FontAwesomeIcon icon="info-circle" className="text-light" /></small></span>
                                    <UncontrolledTooltip target={`product-weight-${product._id}`} placement="bottom">
                                        <CmsPanel slug="product-weight-information" />
                                    </UncontrolledTooltip>

                                    <div className="text-center text-md-left">
                                        <FormattedMessage id="productPacking" defaultMessage="Box of {count} unit(s)" values={{ count: product.packing }} />
                                    </div>
                                </Col>
                                <Col className="d-block d-md-none">
                                    <div className="d-block display-5 text-center text-secondary">
                                        <strong><Price amount={productUnitPrice} formatPrice /></strong>
                                    </div>
                                    <small className="d-block mb-3 mb-md-0 text-center">
                                        <ProductUnit product={product} />
                                    </small>
                                </Col>
                            </Row>
                            {getTranslation(product, 'composition', locale) && (
                                <div className="mt-2">
                                    <a href="#" id={`product-composition-toggler-${product._id}`}><FormattedMessage id="productCompositionLink" defaultMessage="View product's composition" /></a>
                                    <UncontrolledCollapse toggler={`#product-composition-toggler-${product._id}`}>
                                        <HtmlComponent text={getTranslation(product, 'composition', locale)} />
                                    </UncontrolledCollapse>
                                </div>
                            )}
                            <div className="mt-2">
                                {(product.annexes || []).length > 0 && product.annexes.map(annex => <FileLoader key={annex} filename={annex} hidePlaceholders />)}
                            </div>
                        </div>
                    </Col>
                    <Col xs="12" md="3">
                        <div className="p-md-3">
                            <div className="d-none d-md-block">
                                <div className="d-block display-5 text-center text-secondary">
                                    <strong><Price amount={productUnitPrice} formatPrice /></strong>
                                </div>
                                <small className="d-block mb-3 mb-md-0 text-center">
                                    <ProductUnit product={product} />
                                </small>
                            </div>

                            <ButtonGroup vertical className="d-flex justify-content-between mt-md-2 p-1">
                                {canAddToCart && <Fragment>{product.isAvailable ? <CartItemQuantitySelector product={product} /> : <Button color="link" className="border-0 text-danger" disabled><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></Button>}</Fragment>}
                                {!isActive && <Button color="link" className="border-0 text-secondary" onClick={viewProduct}><FormattedMessage id="productView" defaultMessage="View this product" /> <FontAwesomeIcon icon="chevron-right" /></Button>}
                            </ButtonGroup>
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    }
    return (
        <Card className={classnames('product-list-item', isOrder ? 'me-3' : 'mb-4', 'shadow', 'overflow-hidden', 'rounded', 'border')}>
            <CardBody className={classnames(false && product.isHighlight && 'bg-secondary')} style={{ }}>
                <div className="">
                    <h3 className="h4 mb-2 pb-0">{isActive ? getTranslation(product, 'name', locale) : <a href="#" onClick={viewProduct} className="d-flex justify-content-between"><span>{getTranslation(product, 'name', locale)}</span> <FontAwesomeIcon icon="chevron-right" size="sm" className="m-1" /></a>}</h3>
                </div>

                <Row>
                    <Col xs="12" md="6">
                        {canAddToCart && <Fragment>{product.isAvailable ? <CartItemQuantitySelector product={product} /> : <Button color="link" block className="border-0 text-danger" disabled><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></Button>}</Fragment>}
                    </Col>
                    <Col className="text-end">
                        <span className="h3 text-secondary"><Price amount={productUnitPrice} formatPrice /></span><ProductUnit product={product} /><br />
                        <strong><ProductWeight product={product} hideLabel /></strong>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
// <Button color="secondary" size="sm" outline block><FontAwesomeIcon icon="eye" /></Button>

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
    };
}

ProductListItem.defaultProps = {
    user: null,
    viewType: 'grid',
    isOrder: false,
    onViewProduct: null,
    isActive: false,
};

ProductListItem.propTypes = {
     navigate: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    product: PropTypes.object.isRequired,
    viewType: PropTypes.string,
    isOrder: PropTypes.bool,
    onViewProduct: PropTypes.func,
    isActive: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ProductListItem)));
