import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
} from '@fortawesome/free-regular-svg-icons';

import {
    faAt,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faArrowDown,
    faArrowUp,
    faAsterisk,

    faBan,
    faBell,
    faBellSlash,
    faBox,
    faBook,
    faBullhorn,
    faBug,

    faCalendarAlt,
    faCalculator,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faChevronRight,
    faCircle,
    faClipboard,
    faCreditCard,
    faChevronLeft,
    faCog,
    faCogs,
    faCopy,
    faCube,
    faCubes,

    faDolly,
    faDownload,

    faEnvelope,
    faEuroSign,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,

    faFile,
    faFileDownload,
    faFileImage,
    faFileInvoice,
    faFileUpload,
    faFilter,
    faFolderOpen,
    faForward,
    faFrown,

    faGavel,
    faGift,

    faHandPointer,
    faHistory,

    faImage,
    faInfo,
    faInfoCircle,

    faKey,

    faLayerGroup,
    faLightbulb,
    faList,
    faLock,
    faLongArrowAltDown,
    faLongArrowAltUp,

    faMap,
    faMapMarkerAlt,
    faMapSigns,
    faMinus,
    faMoneyBillAlt,

    faPaperPlane,
    faPauseCircle,
    faPen,
    faPhone,
    faPlus,
    faPowerOff,
    faPrint,

    faQuestionCircle,

    faRandom,
    faRecycle,
    faRedo,
    faRedoAlt,
    faReply,

    faSave,
    faSearch,
    faShoppingBasket,
    faSignInAlt,
    faSlidersH,
    faSpinner,
    faStar,
    faStarHalf,
    faStore,
    faSync,
    faSyncAlt,

    faTachometerAlt,
    faThumbsUp,
    faThumbtack,
    faTimes,
    faTimesCircle,
    faToggleOn,
    faToggleOff,
    faTrash,
    faTrashAlt,
    faTruck,

    faUndo,
    faUnlock,
    faUnlockAlt,
    faUpload,
    faUser,
    faUserCircle,
    faUserPlus,
    faUsers,
    faUserShield,
    faUserSlash,
    faUserTimes,

    // Global
    // fas
} from '@fortawesome/free-solid-svg-icons';

library.add(
    // Global
    // fas,

    // more icons go here

    faAt,
    faArrowAltCircleUp,
    faArrowAltCircleDown,
    faArrowDown,
    faArrowUp,
    faAsterisk,

    faBan,
    faBell,
    faBellSlash,
    faBox,
    faBook,
    faBullhorn,
    faBug,

    faCalendarAlt,
    faCalculator,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faChevronRight,
    faCircle,
    faClipboard,
    faCreditCard,
    faChevronLeft,
    faCog,
    faCogs,
    faCopy,
    faCube,
    faCubes,

    faDolly,
    faDownload,

    faEnvelope,
    faEuroSign,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,

    faFile,
    faFileDownload,
    faFileImage,
    faFileInvoice,
    faFileUpload,
    faFilter,
    faFolderOpen,
    faForward,
    faFrown,

    faGavel,
    faGift,

    faHandPointer,
    faHistory,

    faImage,
    faInfo,
    faInfoCircle,

    faKey,

    faLayerGroup,
    faLightbulb,
    faList,
    faLock,
    faLongArrowAltDown,
    faLongArrowAltUp,

    faMap,
    faMapMarkerAlt,
    faMapSigns,
    faMinus,
    faMoneyBillAlt,

    faPaperPlane,
    faPauseCircle,
    faPen,
    faPhone,
    faPlus,
    faPowerOff,
    faPrint,

    faQuestionCircle,

    faRandom,
    faRecycle,
    faRedo,
    faRedoAlt,
    faReply,

    faSave,
    faSearch,
    faShoppingBasket,
    faSignInAlt,
    faSlidersH,
    faSpinner,
    faStar,
    faStarHalf,
    faStore,
    faSync,
    faSyncAlt,

    faTachometerAlt,
    faThumbsUp,
    faThumbtack,
    faTimes,
    faTimesCircle,
    faToggleOn,
    faToggleOff,
    faTrash,
    faTrashAlt,
    faTruck,

    faUndo,
    faUnlock,
    faUnlockAlt,
    faUpload,
    faUser,
    faUserCircle,
    faUserPlus,
    faUsers,
    faUserShield,
    faUserSlash,
    faUserTimes,
);
