import { SWITCH_CURRENCY, SET_RATES, getEnabledCurrencies } from './CurrencyActions';

const initCurrency = 'EUR';
const enabledCurrencies = getEnabledCurrencies();

export const initialState = {
    currency: initCurrency,
    enabledCurrencies,
    rates: {},
};

const CurrencyReducer = (state = initialState, action) => {
    switch(action.type) {

        case SWITCH_CURRENCY:
            return {
                ...state,
                currency: action.currency,
            };

        case SET_RATES:
            return {
                ...state,
                rates: action.rates,
            };

        default:
            return state;
    }
};

export default CurrencyReducer;
