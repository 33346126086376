import callApi from '../../util/apiCaller';
import { sequence } from '../../util/promises';
import { getHashKeys } from '../../util/security';

import { getProductsRequest } from '../Product/ProductActions';
import { getCompanyGroupsRequest } from '../Group/GroupActions';
import { isLoggedInRequest } from '../User/UserActions';
import { getCompaniesRequest } from '../Company/CompanyActions';
import { getAddressesRequest } from '../Address/AddressActions';
import { getCmsTemplateContentsRequest } from '../Cms/CmsActions';
import { getCurrencyRatesRequest } from '../Currency/CurrencyActions';
import { getPublicConfigRequest } from '../Config/ConfigActions';
import { addError } from '../Error/ErrorActions';
import config from '../../config';

// Export Constants
export const INIT_SOCKET = 'INIT_SOCKET';
export const SEND_SOCKET = 'SEND_SOCKET';
export const RECEIVE_SOCKET = 'RECEIVE_SOCKET';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_REDIRECT = 'SET_REDIRECT';
export const REDIRECT = 'REDIRECT';
export const SET_STATUS_CODE = 'SET_STATUS_CODE';
export const SET_MOUNTED = 'SET_MOUNTED';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const INIT_PERSISTED_DATA = 'INIT_PERSISTED_DATA';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_SSR_MODE = 'SET_SSR_MODE';

export const FETCH_LIMIT = 500;

// Export Actions
export function initApp(isSSR = false) {
    return dispatch => {
        return (isSSR ? Promise.resolve(null) : dispatch(isLoggedInRequest())).then(user => {
            const initItems = isSSR ? [getCmsTemplateContentsRequest, getPublicConfigRequest, getProductsRequest, getCurrencyRatesRequest] : [getCompanyGroupsRequest];
            if(user) {
                initItems.push(getAddressesRequest);
                switch(user.role) {
                    case 'admin':
                        // initItems.push();
                        break;

                    case 'manager':
                    default:
                        // initItems.push(getOrdersByCompanyRequest);
                        break;
                }
                if(!user.companies.includes('*')) {
                    initItems.push(getCompaniesRequest);
                }
            // } else {
            } else {
                // initItems.push();
            }
            return sequence([...initItems], dispatch).then(results => {
                dispatch(setMounted());
                if(typeof window !== 'undefined' && window.dataLayer) {
                    window.dataLayer.push({ event: 'optimize.callback' });
                }
            }).catch(err => {
                console.error('AppInitializationError', err, initItems);
            });
        });
    };
}

export function checkVersionRequest() {
    return dispatch => callApi('app/web/version').then(res => {
        if(res.version && res.version !== config.version && process.env.NODE_ENV !== 'development') {
            typeof window !== 'undefined' && window.location.reload();
        }
        return res.version;
    }).catch(error => {
        dispatch(addError(error));
    });
}

export function clearCacheRequest() {
    return dispatch => callApi('app/cache/reset').then(res => {
        return res && res.ok;
    }).catch(error => {
        dispatch(addError(error));
        return false;
    });
}

export function launchCronRequest(key, param) {
    return dispatch => {
        return callApi('cron/launch', 'post', { cron: { key, param } }).then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getTable(store, tableId) {
    return store.app.tables.find(table => table.id === tableId) || {};
}

export function isFetching(store, type) {
    return (store[type] || {}).isFetching || false;
}

export function getRedirect(store) {
    return store.app.redirect;
}

export function getStatusCode(store) {
    return store.app.statusCode;
}

export function isSSR(store) {
    return store.app.isSSR;
}

export function initSocket(socket) {
    return {
        type: INIT_SOCKET,
        socket,
    };
}

export function sendSocket(datas) {
    // Secure subscribe socket with key
    if(datas && datas.type === 'subscribe' && typeof datas.data === 'object') {
        datas.data = {
            ...(datas.data || {}),
            ...getHashKeys(datas.data.channel),
        };
    }
    return {
        type: SEND_SOCKET,
        data: datas,
    };
}

export function receiveSocket(datas) {
    return {
        type: RECEIVE_SOCKET,
        data: datas,
    };
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU,
    };
}

export function setRedirect(redirect = '', statusCode = '301') {
    console.log('AppActions::: setRedirect', redirect, statusCode);
    return {
        type: SET_REDIRECT,
        redirect,
        statusCode,
    };
}

export function setStatusCode(statusCode) {
    console.log('AppActions::: setStatusCode', statusCode);
    return {
        type: SET_STATUS_CODE,
        statusCode,
    };
}

export function redirect() {
    return {
        type: REDIRECT,
    };
}

export function setMounted() {
    return {
        type: SET_MOUNTED,
    };
}

export function updateTable(tableId, tableData) {
    return {
        type: UPDATE_TABLE,
        tableId,
        tableData,
    };
}

export function initPersistedData() {
    return {
        type: INIT_PERSISTED_DATA,
    };
}

export function setIsFetching(dataType, isFetching = true) {
    return {
        type: SET_IS_FETCHING,
        dataType: dataType.toLowerCase(),
        isFetching,
    };
}

export function setAppSSRMode(isSSR) {
    return {
        type: SET_SSR_MODE,
        isSSR,
    };
}
