import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getFile } from '../../../../util/file';

import LazyLoader from '../../../../components/Content/LazyLoader';
import HtmlComponent from '../../../../components/Text/HtmlComponent';

function CmsBlockItem({ item, type, ...props }) {
    if(item) {
        switch(type) {
            case 'link':
                return item.path && (
                    item.path.indexOf('http') === 0
                    ? <a href={item.path} target="_blank" rel="noreferrer" {...props}>{item.text}</a>
                    : <Link to={item.path} {...props}>{item.text}</Link>
                );
            case 'image':
                return (
                    <LazyLoader key={item.sort}>
                        <img src={getFile(item.path)} alt={item.text} role="presentation" {...props} />;
                    </LazyLoader>
                );

            case 'html':
                return <HtmlComponent key={item.sort} text={item.text} {...props} />;
            case 'text':
            default:
                return <span key={item.sort} {...props}>{item.text}</span>;
        }
    }
    return null;
}

CmsBlockItem.defaultProps = {
    item: null,
    type: '',
};

CmsBlockItem.propTypes = {
    item: PropTypes.object,
    type: PropTypes.string,
};

export default CmsBlockItem;
