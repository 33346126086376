import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import io from 'socket.io-client';

import config from '../../../../config';

import { logoutUser, getLoggedUser } from '../../../User/UserActions';
import { initCurrency, setCurrencyRequest, switchCurrency, getCurrencySymbol } from '../../../Currency/CurrencyActions';
import { checkVersionRequest, initApp, initSocket, sendSocket, initPersistedData } from '../../AppActions';
import { initLanguage, getLanguagePath, getCountryFromLanguage, getEnabledLanguages } from '../../../Intl/IntlActions';
import { displayErrors, removeError } from '../../../Error/ErrorActions';

import socketListener from '../../../../socketListener';

import Flag from '../../../../components/Flag/Flag';
import AppLogo from '../view/AppLogo';
import Menu from '../menu/Menu';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import CartMini from '../../../Cart/components/view/CartMini';
import NotificationSummary from '../../../Notification/components/list/NotificationSummary';
import TaskSummary from '../../../Task/components/list/TaskSummary';
import withRouter from '../../../../components/Router/WithRouter';

const socket = io(config.ws);

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            dropdownLanguageOpen: false,
            dropdownCurrencyOpen: false,
            dropdownNotificationsOpen: false,
            isScrolling: false,
        };

        this.scrollThreshold = 30;
    }

    componentDidMount() {
        this.props.dispatch(initPersistedData());
        this.props.dispatch(initSocket(socket));

        socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);

        this.props.dispatch(initApp());

        window && window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();

        if(this.props.appRedirect) {
            this.props.navigate(getLanguagePath(this.props.appRedirect, this.props.intl.locale), { replace: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
            this.props.dispatch(checkVersionRequest());
        }

        if(!prevProps.user && this.props.user) {
            this.props.dispatch(initCurrency(this.props.user));
            this.switchLanguage(this.props.user.language);
            this.props.user && socketListener(socket, this.props.dispatch, this.props.user, this.props.intl);
        }
        if(prevProps.appRedirect !== this.props.appRedirect && this.props.appRedirect) {
            this.props.navigate(getLanguagePath(this.props.appRedirect, this.props.intl.locale), { replace: true });
        }
        if(prevProps.errors.length !== this.props.errors.length) {
            this.props.errors.forEach(error => {
                if(error && error.type && error.message) {
                    displayErrors(error.type, this.props.intl.messages[`alert${error.message}`] || error.message);
                }
                this.props.dispatch(removeError(error.message));
            });
        }
    }

    isHomePage = () => {
        return this.props.location.pathname.split('/').filter(path => !!path).length <= 1;
    };

    switchLanguage = lang => {
        if(lang !== this.props.intl.locale) {
            this.props.dispatch(initLanguage(lang, this.props.user)).then(lang => {
                // Search for meta alternate link set by Helmet to redirect to translated content
                const redirectionLink = Array.from(document.querySelectorAll('link[rel="alternate"]')).find(link => link.hreflang === lang);
                if(redirectionLink) {
                    console.log('Link alternate found', redirectionLink.href, redirectionLink.href.replace(config.url, '/'));
                    this.props.navigate(redirectionLink.href.replace(config.url, '/'));
                } else if(this.props.location.pathname !== getLanguagePath(this.props.location.pathname, lang)) {
                    console.log('Replace route to ', this.props.location.pathname, getLanguagePath(this.props.location.pathname, lang), this.props.location.search, getLanguagePath(this.props.location.pathname, lang) + this.props.location.search);
                    this.props.navigate(getLanguagePath(this.props.location.pathname, lang) + this.props.location.search, { replace: true });
                }
            });
        } else {
            console.log('Locale already set', lang);
        }
    };

    switchCurrency = currency => {
        this.props.dispatch(switchCurrency(currency));
        this.props.user && this.props.dispatch(setCurrencyRequest(currency));
    };

    handleScroll = event => {
        const isScrolling = (document.body.scrollTop || document.documentElement.scrollTop) > this.scrollThreshold;
        if(isScrolling !== this.state.isScrolling) {
            this.setState({
                isScrolling,
            });
        }
    };

    handleLogout = () => {
        socket.emit('userDisconnection');
        this.props.dispatch(logoutUser(true));
        this.props.navigate('/');
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    toggleDropdownLanguage = () => {
        this.setState({
            dropdownLanguageOpen: !this.state.dropdownLanguageOpen,
        });
    };

    toggleDropdownCurrency = () => {
        this.setState({
            dropdownCurrencyOpen: !this.state.dropdownCurrencyOpen,
        });
    };

    toggleDropdownNotifications = () => {
        this.setState({
            dropdownNotificationsOpen: !this.state.dropdownNotificationsOpen,
        });
    };

    isLoggedIn() {
        return this.props.user && this.props.user._id;
    }

    renderLanguages() {
        return getEnabledLanguages().map(lang => <a href="#" key={lang} onClick={event => { this.switchLanguage(lang); }} className="d-inline-block ms-1"><Flag country={getCountryFromLanguage(lang)} /></a>);
    }

    renderCurrencies() {
        const currenciesNodes = this.props.currency.enabledCurrencies.filter(currency => currency !== this.props.currency.currency).filter(currency => this.props.currency.rates[currency] || currency === 'EUR').map(
            currency => <DropdownItem key={currency} size="sm"><NavLink onClick={() => this.switchCurrency(currency)} className={currency === this.props.currency.currency ? 'selected' : ''}>{getCurrencySymbol(currency)}</NavLink></DropdownItem>,
        );
        return (
            <ButtonDropdown isOpen={this.state.dropdownCurrencyOpen} toggle={this.toggleDropdownCurrency} color="link" size="sm">
                <DropdownToggle nav caret className="p-0">
                    {getCurrencySymbol(this.props.currency.currency)}
                </DropdownToggle>
                <DropdownMenu className="text-center">{currenciesNodes}</DropdownMenu>
            </ButtonDropdown>
        );
    }

    render() {
        return (
            <div className={classnames('fixed-top', 'header-wrapper', this.state.isScrolling && 'header-scrolled')}>
                <Helmet
                    htmlAttributes={{ lang: `${this.props.intl.locale}-${getCountryFromLanguage(this.props.intl.locale)}`, 'data-header-user': this.props.user ? this.props.user.role : 'false' }}
                    defaultTitle={config.application.name}
                    titleTemplate={`%s - ${config.application.name}`}
                    meta={[
                            { charset: 'utf-8' },
                            {
                                'http-equiv': 'X-UA-Compatible',
                                content: 'IE=edge',
                            },
                            {
                                name: 'viewport',
                                content: 'width=device-width, initial-scale=1',
                            },
                            {
                                name: 'description',
                                content: this.props.intl.formatMessage({
                                    id: 'seoDescription',
                                    defaultMessage: '',
                                }),
                            },
                            {
                                name: 'theme-color',
                                content: '#33485e',
                            },
                            {
                                name: 'og:url',
                                content: `${config.url}${this.props.location.pathname.substring(1)}`,
                            },
                            {
                                name: 'og:type',
                                content: 'website',
                            },
                            {
                                name: 'og:locale',
                                content: this.props.intl.locale,
                            },
                            {
                                name: 'og:title',
                                content: `${config.application.name}`,
                            },
                            {
                                name: 'og:description',
                                content: this.props.intl.formatMessage({
                                    id: 'seoDescription',
                                    defaultMessage: 'Charcuterie, Direct producer',
                                }),
                            },
                            {
                                name: 'og:image',
                                content: 'https://keesbo.eu/assets/images/logo.png',
                            },
                        ]}
                    link={[]/* LANGUAGES_ENABLED.filter(locale => locale !== this.props.intl.locale).map((locale) => {
                        return {
                            rel: 'alternate',
                            href: `${config.url}${this.props.location.pathname.replace(`/${this.props.intl.locale}`, `/${locale}`)}`,
                            hrefLang: locale,
                        }; }) */.concat([
                            {
                                rel: 'canonical',
                                href: `${config.url}${this.props.location.pathname.substring(1)}`,
                            },
                        ])}
                />
                <Container fluid="xl" className="p-0">
                    <Navbar light expand="md" className="p-0">
                        <div className="w-third">
                            <CmsPanel slug={`main-menu${this.props.user ? '-user' : ''}`} isTemplate />
                        </div>

                        <NavbarBrand tag={Link} to={getLanguagePath('/', this.props.intl.locale)} className="ml-auto">
                            <AppLogo imageProps={{ className: classnames('site-logo', 'd-inline'), style: { height: (!this.isHomePage() || this.state.isScrolling) ? '5rem' : '0px', marginTop: (!this.isHomePage() || this.state.isScrolling) ? '0' : '5rem', opacity: (!this.isHomePage() || this.state.isScrolling) ? '1' : '0' } }} />
                        </NavbarBrand>

                        <div className="ml-auto pe-1 pr-xl-0 w-third">
                            <div className="top-bar">
                                <Nav className={classnames(['d-flex', 'justify-content-end'], 'info-menu')}>
                                    <NavItem className="d-none d-lg-inline-block">
                                        <CmsPanel slug="header-top" isTemplate />
                                    </NavItem>
                                    {getEnabledLanguages().length > 1 && (
                                        <NavItem>
                                            {this.renderLanguages()}
                                        </NavItem>
                                    )}
                                    {this.props.currency.enabledCurrencies.length > 1 && (
                                        <NavItem>
                                            {this.renderCurrencies()}
                                        </NavItem>
                                    )}
                                </Nav>
                            </div>
                            <div className="bottom-bar">
                                <Nav className={classnames(['d-flex', 'justify-content-end'], 'general-menu')}>

                                    {this.isLoggedIn() && this.props.user.role === 'admin' && <NavItem className="border-0 p-0"><TaskSummary /></NavItem>}
                                    <NavItem className="border-0 p-0"><NotificationSummary /></NavItem>
                                    <NavItem><Link to={getLanguagePath(this.isLoggedIn() ? '/user/profile' : '/user/login', this.props.intl.locale)}><span className="d-none d-md-inline"><FormattedMessage id="userAccountMy" defaultMessage="My account" /></span><span className="d-inline d-md-none"><FontAwesomeIcon icon="user-circle" /></span></Link></NavItem>

                                    {this.props.user && (
                                        <NavItem>
                                            <CartMini />
                                        </NavItem>
                                    )}

                                </Nav>
                            </div>
                        </div>
                    </Navbar>
                    {this.isLoggedIn() && this.props.user.role === 'admin' && <Menu />}
                </Container>
            </div>
        );
    }
}
// <Link to={getLanguagePath('/orders', this.props.intl.locale)}><FontAwesomeIcon icon="user-circle" /> <span className="d-none d-md-inline"><FormattedMessage id="ordersMy" defaultMessage="My orders" /></span> {subordersCount ? <Badge className="bg-transparent text-secondary" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>{subordersCount}</Badge> : null}</Link>
// <FormattedMessage id="userAccountMy" defaultMessage="My account" /> ({`${this.props.user.firstName} ${this.props.user.lastName}`})

// {false && !this.isLoggedIn() ? <Link to={getLanguagePath('/user/register', this.props.intl.locale)} color="light" size="sm"><FontAwesomeIcon icon="user-circle" /></Link> : ''}

function mapStateToProps(store, props) {
    const user = getLoggedUser(store);
    return {
        errors: store.error.data,
        user,
        appRedirect: store.app.redirect,
        currency: store.currency,
    };
}

Header.contextTypes = {
    router: PropTypes.object,
};

Header.defaultProps = {
    user: null,
    appRedirect: '',
};

Header.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    user: PropTypes.object,
    appRedirect: PropTypes.string,
    currency: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(Header)));
