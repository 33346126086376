export function getPriceCent(price) {
    return Math.round(price * 100);
    // Correction ?! RUN test
    // return Math.round(parseFloat(price) * 100);
}

export function getPriceFromCent(priceInCents) {
    return Math.round(priceInCents) / 100;
}

export function formatPrice(price) {
    return getPriceFromCent(getPriceCent(price));
}
