import { Parser } from 'expr-eval';
import callApi from '../../util/apiCaller';

import config from '../../config';
import { getPriceCent, getPriceFromCent } from '../../util/price';

import { setOrder } from '../Order/OrderActions';
import { getTotalWeight, getSubtotal, getTotalQuantity, getPackages, getParcels } from '../Cart/CartActions';
import { GROUP_DEFAULT } from '../Group/GroupActions';
import { addError } from '../Error/ErrorActions';

export const SET_SHIPPINGS = 'SET_SHIPPINGS';
export const REMOVE_SHIPPING = 'REMOVE_SHIPPING';

export function getShippingsRequest() {
    return dispatch => {
        return callApi('shippings').then(res => {
            res.shippings && dispatch(setShippings(res.shippings));
            return res.shippings;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getShippingMethodsRequest(address) {
    return callApi('shippings/methods', 'post', { address }).then(results => {
        return results;
    }).catch(err => {
        console.error(err);
        return [];
    });
}

export function editShippingRequest(shipping) {
    return dispatch => {
        return callApi('shipping/edit', 'post', { shipping }).then(res => {
            dispatch(getShippingsRequest(true));
            return res.shipping;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function removeShippingRequest(shippingId) {
    return dispatch => {
        return callApi('shipping/remove', 'delete', { shipping: { _id: shippingId } }).then(res => {
            dispatch(removeShipping(shippingId));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getShippingLabelRequest(orderId, regenerate = false) {
    return dispatch => {
        return callApi(`order/${orderId}/shipping/label${regenerate ? '/regenerate' : ''}`).then(res => {
            return res;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function setShippingTrackingRequest(orderId, trackingNumber) {
    return dispatch => {
        return callApi(`order/${orderId}/shipping/trackingnumber`, 'post', { trackingNumber }).then(res => {
            return res;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getShippingFee(address, method, items = [], options = { increasePercentage: 0 }) {
    // console.log('calculateShippingFee', address, method);
    if(method && method.rates && address && address.countryCode) {
        const { base, extraFee, increase, tax } = getShippingFeeDetails(address, method, items, options);
        // console.log('Shipping Fee Total', method.identifier, base, extraFee, tax, base + extraFee + tax);
        return base + extraFee + increase; // + tax;
    }
    address && method && console.error('MissingShippingMethodData', method, address);
    return null;
}

export function getShippingFeeDetails(address = {}, method = {}, items = [], options = { increasePercentage: 0 }) {
    let shippingFee = 0;
    let extraFee = 0;
    let increase = 0;
    let tax = 0;
    let taxRate = 0;

    if(method && method.rates && address && address.countryCode) {
        const { calculation, rates } = method || {};
        // get step value (weight or subtotal)
        const stepName = (calculation || {}).step || 'weight';

        // console.log('getShippingFeeDetails', calculation, rates, stepName);

        const parcels = getParcels(method, items);
        // console.log('Shipping Parcels', parcels);
        parcels.forEach((parcelItems, index) => {
            let parcelShippingFee = 0;

            let stepValue = null;
            switch(stepName) {
                case 'pack':
                    stepValue = getPackages(parcelItems).length;
                    break;

                case 'packGroup':
                    stepValue = getParcels(method, parcelItems).length;
                    break;

                case 'subtotal':
                    stepValue = getSubtotal(parcelItems);
                    break;

                case 'weight':
                default:
                    stepValue = getTotalWeight(parcelItems);
            }

            // console.log('Parcel items', parcelItems);
            // console.log('Shipping method step', stepName, stepValue, parcelItems);

            // calculate shipping fee depending on rates and method config
            if(calculation) {
                if(calculation.mode === 'fixed') {
                    parcelShippingFee += calculateShippingFeeFromStep(method, stepValue);
                } else if(calculation.mode === 'loop') {
                    let stepValueTemp = stepValue;
                    const shippingSteps = rates.map(rate => rate[stepName]);
                    const maxShippingStep = shippingSteps[shippingSteps.length - 1];
                    let calculatedStepValue = 0;

                    while(stepValueTemp > 0) {
                        calculatedStepValue = stepValueTemp > maxShippingStep ? maxShippingStep : stepValueTemp;
                        parcelShippingFee += calculateShippingFeeFromStep(method, calculatedStepValue);
                        stepValueTemp -= calculatedStepValue;
                    }
                } else {
                    console.error(new Error('UnknowShippingMethodCalculationMode'), calculation);
                }
            } else {
                console.error(new Error('NoShippingMethodCalculation'), method);
            }

            // HT
            const parcelExtraFee = calculateShippingExtraFees(method, {
                shippingFee: parcelShippingFee,
                weight: getTotalWeight(parcelItems),
                subtotal: getSubtotal(parcelItems),
            });

            extraFee += parcelExtraFee;
            shippingFee += parcelShippingFee;

            // shippingFee = getPriceFromCent(getPriceCent(shippingFee) + getPriceCent(parcelShippingFee) + getPriceCent(parcelExtraFee));
            // console.log('Parcel Shipping Fee', `Base: ${parcelShippingFee} //  Extra fee: ${parcelExtraFee} // Total parcel: ${getPriceFromCent(getPriceCent(parcelShippingFee) + getPriceCent(parcelExtraFee))} // TOTAL: ${shippingFee}`);
        });

        if(options.increasePercentage) {
            increase = ((shippingFee + extraFee) * parseFloat(options.increasePercentage)) / 100;
        }

        taxRate = parseFloat(method.taxRate || config.application.taxRate);
        if(method.isPriceTTC) {
            tax = getPriceFromCent(getPriceCent(shippingFee + extraFee + increase) - (getPriceCent(shippingFee + extraFee + increase) / (1 + taxRate)));
            shippingFee = getPriceFromCent(getPriceCent(shippingFee / (1 + taxRate)));
            // extraFee = getPriceFromCent(getPriceCent(extraFee / (1 + taxRate)));
        } else {
            // console.log('Setting TTC price');
            tax = getPriceFromCent(getPriceCent(shippingFee + extraFee + increase) * parseFloat(taxRate));
        }

        process.env.NODE_ENV === 'development' && console.log('ShippingFee details', {
            base: shippingFee, // HT
            extraFee, // HT
            increase,
            tax,
            taxRate,
        });
    }
    return {
        base: shippingFee, // HT
        extraFee, // HT
        increase,
        tax,
        taxRate,
    };
}

export function calculateShippingFeeFromStep(method, stepValue) {
    // console.log('calculateShippingFeeFromStep', method.calculation, method.rates, stepValue, method.calculation.limit, method.calculation.tolerance);
    if(method && method.rates && method.rates.length && stepValue) {
        const { calculation, rates } = method;
        const stepName = calculation.step;
        stepValue = parseFloat(stepValue);
        let rateFound = rates.find((rate, index) => {
            const prevStepRate = rates[index - 1] ? parseFloat(rates[index - 1][stepName]) : null;
            const currentStepRate = parseFloat(rate[stepName]);
            const nextStepRate = rates[index + 1] ? parseFloat(rates[index + 1][stepName]) : null;
            if(calculation.limit === 'min') {
                if(calculation.tolerance === 'exclusive') {
                    return stepValue > currentStepRate && (nextStepRate === null || stepValue <= nextStepRate);
                }
                // inclusive
                return stepValue >= currentStepRate && (nextStepRate === null || stepValue < nextStepRate);
            }
            // max
            if(calculation.tolerance === 'exclusive') {
                return stepValue < currentStepRate && (prevStepRate === null || stepValue >= prevStepRate);
            }
            // inclusive
            return stepValue <= currentStepRate && (prevStepRate === null || stepValue > prevStepRate);
        });
        if(!rateFound && calculation.forceMinimum) {
            console.log('Rate not found!');
            [rateFound] = rates;
            console.log(rateFound);
            stepValue = rateFound[stepName];
        }
        // console.log('calculateShippingFeeFromStep result', rateFound, rateFound ? parseFloat(rateFound.value) : 0);
        if(rateFound) {
            if(calculation.isProrated && calculation.prorata) {
                console.log('Value is prorated!', `(${rateFound.value} * ${stepValue}) / ${calculation.prorata} = ${(rateFound.value * stepValue) / calculation.prorata}`);
                return parseFloat((rateFound.value * stepValue) / calculation.prorata);
            }
            return parseFloat(rateFound.value);
        }
        return 0;
    }
    // console.error(new Error('NoShippingRates'), method, stepValue);

    return 0;
}

/*
    Variables allowed: shippingFee, weight, subtotal
    ex: "ceil(subtotal/150)*0.95"
*/
export function calculateShippingExtraFees(method, variables) {
    return getPriceFromCent(getShippingExtraFees(method, variables).reduce((total, extraFee) => total + getPriceCent(extraFee.base), 0));
}

export function getShippingExtraFees(method, variables) {
    return ((method || {}).extraFees || []).map(extraFee => {
        let base = 0;
        try {
            base = Parser.evaluate(extraFee.calculation, variables);
        } catch (err) {
            console.error(err);
        }
        let tax = 0;
        const taxRate = parseFloat(method.taxRate || config.application.taxRate);
        if(method.isPriceTTC) {
            tax = getPriceFromCent(getPriceCent(base) - (getPriceCent(base) / (1 + taxRate)));
            base = getPriceFromCent(getPriceCent(base / (1 + taxRate)));
        } else {
            // console.log('Setting TTC price');
            tax = getPriceFromCent(getPriceCent(base) * parseFloat(taxRate));
        }
        return {
            ...extraFee,
            base,
            tax,
            taxRate,
        };
    });
}

// Getters
export function getShippings(store) {
    return store.shippings.data;
}

export function getShipping(store, shippingId) {
    return store.shippings.data.find(shipping => shipping._id === shippingId);
}

export function getShippingsCategories(store) {
    const categories = [];
    getShippings(store).forEach(shipping => {
        if(!categories.includes(shipping.category)) {
            categories.push(shipping.category);
        }
    });
    return categories;
}

export function getShippingByAddress(store, address, company = null, items = []) {
    return address && store.shippings.data.find(shipping => isShippingApplicable(shipping, address, company, items));
}

export function isShippingApplicable(shipping, address, company = null, items = [], escapeConditions = []) {
    console.log('isShippingApplicable', shipping.identifier, shipping, address, company?._id);
    console.log('Country', address.countryCode && (shipping.countries || []).includes(address.countryCode) ? 'OK' : 'KO');
    console.log('Postal code', (
        !shipping.exclude
        || (
            !address.postalCode
            || !shipping.exclude.postalCodes
            || !shipping.exclude.postalCodes.split(',').some(postalCode => !isAddressDepartmentAvailableForShipping(address.postalCode, postalCode))
        )
    ) ? 'OK' : 'KO');
    console.log('Company group', company && company.groups, shipping && shipping.groups, (
        !company
        || !company.groups
        || !shipping.groups
        || !shipping.groups.length
        || (company && shipping.groups.find(group => (company.groups || []).includes(group)))
    ) ? 'OK' : 'KO');
    console.log('Shipping conditions', shipping.conditions, (shipping.conditions || []).every(condition => checkShippingCondition(condition, items)) ? 'OK' : 'KO');

    return address.countryCode && (shipping.countries || []).includes(address.countryCode)
        && (
            !shipping.exclude
            || (
                !address.postalCode
                || !shipping.exclude.postalCodes
                || !shipping.exclude.postalCodes.split(',').some(postalCode => !isAddressDepartmentAvailableForShipping(address.postalCode, postalCode))
            )
        )
        && (
            !company
            || !company.groups
            || !shipping.groups
            || !shipping.groups.length
            || (company && shipping.groups.find(group => (company.groups || []).includes(group)))
        ) && (shipping.conditions || []).every(condition => escapeConditions.includes(condition.key) || checkShippingCondition(condition, items));
}

export function checkShippingCondition(condition, items) {
    let stepValue = null;
    switch(condition.key) {
        case 'packCount':
            stepValue = getTotalQuantity(items);
            break;

        case 'subtotal':
            stepValue = getSubtotal(items);
            break;

        case 'weight':
        default:
            stepValue = getTotalWeight(items);
    }
    if(condition.limit === 'min') {
        if(condition.tolerance === 'exclusive') {
            return stepValue > condition.value;
        }
        // inclusive
        return stepValue >= condition.value;
    }
    // max
    if(condition.tolerance === 'exclusive') {
        return stepValue < condition.value;
    }
    // inclusive
    return stepValue <= condition.value;
}

export function isAddressDepartmentAvailableForShipping(addressPostalCode, postalCode) {
    const jokerIndex = postalCode.indexOf('*') > 0 ? postalCode.indexOf('*') : postalCode.length;
    return addressPostalCode.substring(0, jokerIndex) !== postalCode.replace('*', '');
}

export function setShippings(shippings) {
    return {
        type: SET_SHIPPINGS,
        shippings,
    };
}

export function removeShipping(id) {
    return {
        type: REMOVE_SHIPPING,
        id,
    };
}
