import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Alert, ButtonGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useReactToPrint } from 'react-to-print';

import { getCompanies } from '../../../Company/CompanyActions';
import { getProductsRequest, getProducts } from '../../ProductActions';
import { getCategories } from '../../../Category/CategoryActions';
import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockItemValue, getContentBlockMixedItem } from '../../../Cms/CmsActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getLanguagePath, getTranslation } from '../../../Intl/IntlActions';

import ProductListItem from '../../components/list/ProductListItem';
import GroupSelector from '../../../Group/components/form/GroupSelector';
import withRouter from '../../../../components/Router/WithRouter';
import ProductListExportButton from '../../components/list/ProductListExportButton';
import ProductListExport from '../../components/list/ProductListExport';

function ProductListPage({ dispatch, location, search, intl, page, user, products, categories, companies, ...props }) {
    useEffect(() => {
        dispatch(getProductsRequest()).then(() => {
            if(search.download) {
                setTimeout(() => {
                    document.getElementById('products-download-link').click();
                }, 500);
            }
        });
    }, []);

    const [activeTab, setActiveTab] = useState(categories.reduce((active, category, index) => (category.slug === search.cat ? index : active), 0));
    const [activeProduct, setActiveProduct] = useState(null);

    const productsExportRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => productsExportRef.current,
    });

    const { blocks } = page || {};
    const title = getContentBlockItemValue(blocks, 'title');
    const categoriesAllowed = (getContentBlockItemsByName(blocks, 'categories') || []).map(item => item.text);

    const [isScrolling, setIsScrolling] = useState(false);
    const scrollThreshold = 30;
    const handleScroll = event => {
        const newScrolling = typeof window !== 'undefined' && (document.body.scrollTop || document.documentElement.scrollTop) > scrollThreshold;
        if(newScrolling !== isScrolling) {
            setIsScrolling(newScrolling);
        }
    };
    typeof window !== 'undefined' && window.addEventListener('scroll', handleScroll);
    handleScroll();

    const toggle = tab => {
        setActiveTab(tab);
        typeof window !== 'undefined' && window.scrollTo(0, 0);
    };

    const getCategories = () => {
        return categories.filter(category => (!companies.length || companies.some(company => company.groups.some(group => category.groups.includes(group)))) && (!categoriesAllowed.length || categoriesAllowed.includes(category._id))).sort((catA, catB) => catA.order - catB.order);
    };

    const getProductsByCategory = category => {
        return (products || []).filter(product => product.categories.includes(category._id)).sort((productA, productB) => productA.order - productB.order);
    };

    const displayedCategories = getCategories();

    if(!displayedCategories.length) {
        return <GroupSelector />;
    }

    return (
        <div className={classnames('page-wrapper', isScrolling && 'pt-3')}>
            {displayedCategories.length > 1 && (
                <div className={classnames('bg-primary', isScrolling && ['position-fixed', 'w-100'])} style={{ top: (user || {}).role === 'admin' ? '94px' : '64px', zIndex: 5 }}>
                    <Container>
                        <Nav tabs fill className="mb-0 border-0">
                            {displayedCategories.map((category, index) => (
                                <NavItem key={category._id}>
                                    <NavLink
                                        className={classnames({ active: activeTab === index }, 'mb-0', 'px-1', 'px-lg-2', 'py-2', 'py-lg-3', 'border-0', 'rounded-0', 'bg-primary', 'font-secondary', 'text-white', activeTab === index && 'bg-secondary')}
                                        onClick={() => { toggle(index); }}
                                    >
                                        {getTranslation(category, 'name', intl.locale)}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Container>
                </div>
            )}

            <Container>
                <TabContent activeTab={activeTab}>
                    {displayedCategories.map((category, index) => {
                        const products = getProductsByCategory(category);
                        return (
                            <TabPane key={category._id} tabId={index} className="py-3 px-2 px-md-0">
                                <Container>
                                    {
                                        products && products.length > 0
                                        ? (
                                            <Row>
                                                {products.map(product => {
                                                    const isActive = true || (activeProduct && activeProduct._id === product._id);
                                                    return (
                                                        <Col key={product._id} xs={isActive ? '12' : '6'} md={isActive ? '12' : '4'}>
                                                            <ProductListItem product={product} viewType={isActive ? 'list' : 'grid'} onViewProduct={setActiveProduct} isActive={isActive} />
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        ) : (
                                            <Alert color="light" className="text-center">
                                                <FontAwesomeIcon icon="exclamation-circle" /> <FormattedMessage id="categoryProductNoItems" defaultMessage="No products in this category" />
                                            </Alert>
                                        )
                                    }
                                </Container>
                            </TabPane>
                        );
                    })}
                </TabContent>

                <ButtonGroup size="lg" className="position-fixed d-flex justify-content-between w-100" style={{ bottom: 0, left: 0 }}>
                    <Button onClick={handlePrint}><FontAwesomeIcon icon="download" /> <FormattedMessage id="productExportButton" defaultMessage="Download products prices" /></Button>
                    {!user && (
                        <Button color="secondary" tag={Link} to={getLanguagePath('/user/register', intl.locale)}>
                            <FormattedMessage id="orderProfromaCreateButton" defaultMessage="Get a quote" /> <FontAwesomeIcon icon="paper-plane" />
                        </Button>
                    )}
                </ButtonGroup>

                <div className="d-none">
                    <ProductListExport ref={productsExportRef} products={products} categories={displayedCategories} intl={intl} />
                </div>
                {user && companies.every(company => !((company.dates || {}).orders || []).length) && <GroupSelector />}
            </Container>
        </div>
    );
}
// <ProductListItem key={product._id} product={product} viewType="list" />

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        products: getProducts(store, true),
        categories: getCategories(store, true),
        companies: getCompanies(store, true),
    };
}

ProductListPage.defaultProps = {
    user: null,
    companies: [],
};

ProductListPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    user: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(withRouter(injectIntl(ProductListPage)));

export const ProductListPageModel = {
    type: 'page',
    name: 'products',
    slug: 'products',
    blocks: [
        {
            name: 'cover',
            type: 'image',
            max: 1,
        },
        {
            name: 'title',
            type: 'text',
            max: 1,
        },
        {
            name: 'text',
            max: 1,
            type: 'html',
        },
        {
            name: 'categories',
            type: 'category',
        },

    ],
};
