// Import Actions
import { INIT_SOCKET, SEND_SOCKET, RECEIVE_SOCKET, SET_REDIRECT, REDIRECT, SET_MOUNTED, UPDATE_TABLE, SET_SSR_MODE } from './AppActions';
// import { LOGIN_USER, LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    socket: null,
    redirect: '',
    isMounted: false,
    isSSR: false,
    tables: [
        // {
        //     id: 'users-list',
        //     activePage: 3,
        //     pageSize: null,
        //     searchFilters: [
        //         {
        //             property: 'name',
        //             value: 'test'
        //         }
        //     ],
        //     searchText: 'ma recherche'
        // }
    ],
};

const AppReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_REDIRECT:
            return {
                ...state,
                redirect: action.redirect,
                statusCode: action.statusCode || state.statusCode,
            };

        case REDIRECT:
            // state.redirect && browserHistory.push(state.redirect);
            return state;

        case INIT_SOCKET:
            return {
                ...state,
                socket: action.socket,
            };

        case SEND_SOCKET:
            console.log('SEND_SOCKET', action.data);
            state.socket && state.socket.emit(action.data.type, action.data.data);
            return state;

        case RECEIVE_SOCKET:
            console.log('RECEIVE_SOCKET', action.data);
            return state;

        case SET_MOUNTED:
            return {
                ...state,
                isMounted: true,
            };

        case UPDATE_TABLE:
            return {
                ...state,
                tables: [
                    ...state.tables.filter(table => table.id !== action.tableId),
                    {
                        id: action.tableId,
                        ...action.tableData,
                    },
                ],
            };

        case SET_SSR_MODE:
            return {
                ...state,
                isSSR: action.isSSR,
            };

        // case LOGIN_USER:
        // case LOGOUT_USER:
        //     return {
        //         ...state,
        //         isMounted: true,
        //     };

        default:
            return state;
    }
};

// Export Reducer
export default AppReducer;
