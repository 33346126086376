import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchMini from '../search/SearchMini';
import { getLanguagePath } from '../../../Intl/IntlActions';

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeDropdown: '',
            displaySearch: false,
        };
    }

    getRoutes() {
        return [
            {
                to: '/console',
                name: 'dashboard',
                picto: 'tachometer-alt',
                allowed: ['admin'],
            },
            {
                to: '/console/orders/order',
                name: 'orders',
                picto: 'list',
                allowed: ['admin'],
                subs: [
                    {
                        to: '/console/orders/proforma',
                        name: 'orderProforma',
                        picto: 'pen',
                    },
                    {
                        to: '/console/orders/forecast',
                        name: 'orderForecast',
                        picto: 'forward',
                    },
                ],
            },
            {
                to: '/console/transactions',
                name: 'transactions',
                picto: 'money-bill-alt',
                allowed: ['admin'],
            },
            {
                to: '/console/companies',
                name: 'companies',
                picto: 'store',
                allowed: ['admin'],
                subs: [
                    {
                        to: '/console/users',
                        name: 'users',
                        picto: 'users',
                    },
                ],
            },
            {
                to: '/console/products',
                name: 'products',
                picto: 'cubes',
                allowed: ['admin'],
                subs: [
                    {
                        to: '/console/categories',
                        name: 'categories',
                        picto: 'layer-group',
                    },
                ],
            },
            {
                to: '/console/contents',
                name: 'cms',
                picto: 'book',
                allowed: ['admin'],
            },
            {
                to: '/console/config',
                name: 'configuration',
                picto: 'cogs',
                allowed: ['admin'],
            },
        ];
    }

    toggle = (routeName, isActive = true) => {
        this.setState({
            activeDropdown: isActive ? routeName : '',
        });
    }

    renderRoutes() {
        return this.getRoutes().map(this.renderRoute);
    }

    renderRoute = route => {
        if(route.name === 'divider') {
            return <NavItem key={route.name} divider />;
        } if(!route.allowed || (this.props.user && this.props.user.role && route.allowed.includes(this.props.user.role))) {
            if(route.subs && route.subs.length) {
                return (
                    <Dropdown key={route.name} nav isOpen={this.state.activeDropdown === route.name} toggle={() => this.toggle(route.name)} onMouseEnter={() => { this.toggle(route.name); }} onMouseLeave={() => { this.toggle(route.name, false); }}>
                        <DropdownToggle tag="span" className="nav-link">
                            <Link to={getLanguagePath(route.to, this.props.intl.locale)} className="dropdown-item">
                                {this.renderRouteLabel(route)}
                            </Link>
                        </DropdownToggle>
                        <DropdownMenu>
                            {route.subs.map(sub => <DropdownItem key={sub.to} tag={Link} to={getLanguagePath(sub.to, this.props.intl.locale)}>{this.renderRouteLabel(sub)}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                );
            }
            return (
                <NavItem key={route.name}>
                    <NavLink tag={Link} to={getLanguagePath(route.to, this.props.intl.locale)}>{this.renderRouteLabel(route)}</NavLink>
                </NavItem>
            );
        }
        return null;
    }

    renderRouteLabel = route => (
        <Fragment>
            {route.picto && <FontAwesomeIcon icon={['fas', route.picto]} />} <span className="d-none d-lg-inline">{route.name && <FormattedMessage id={route.name} />}</span>
        </Fragment>
    )

    render() {
        if(this.props.user) {
            return (
                <Nav className="d-flex justify-content-around">
                    {this.state.displaySearch ? <SearchMini onClose={() => this.setState({ displaySearch: false })} /> : this.renderRoutes()}
                    {!this.state.displaySearch && (
                        <NavItem>
                            <NavLink onClick={() => this.setState({ displaySearch: !this.state.displaySearch })}><FontAwesomeIcon icon={this.state.displaySearch ? 'times-circle' : 'search'} /></NavLink>
                        </NavItem>
                    )}
                </Nav>
            );
        }
        return null;
    }
}

function mapStateToProps(store) {
    return {
        user: store.users.user,
    };
}

Menu.defaultProps = {
    user: null,
};

Menu.propTypes = {
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(injectIntl(Menu));
