import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'reactstrap';

import { loginRequest, logoutUser, setAdminRequest } from '../../UserActions';

class UserAdminLoginSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };

        this.triggerLogin = this.triggerLogin.bind(this);
    }

    triggerLogin(userRole) {
        let email = '';
        switch(userRole) {
            case 'admin':
                email = 'clement@offaxis.io';
                break;

            default:
                email = 'test@offaxis.io';
                break;
        }
        this.setState({
            email,
            password: '123456',
        }, () => {
            this.props.dispatch(logoutUser(true));
            setTimeout(() => {
                this.props.dispatch(loginRequest(this.state.email.toLowerCase(), this.state.password));
            }, 500);
        });
    }

    render() {
        return (
            <div className={process.env.NODE_ENV === 'production' ? 'd-none' : 'mw-100 overflow-hidden'}>
                <ButtonGroup size="sm" className="w-100 mt-5">
                    <Button color="secondary" onClick={event => this.triggerLogin('customer')}>Customer</Button>
                    <Button color="danger" onClick={event => this.triggerLogin('admin')}>Admin</Button>
                    <Button onClick={event => this.props.dispatch(setAdminRequest())}>Create admin</Button>
                </ButtonGroup>
            </div>
        );
    }
}

UserAdminLoginSwitcher.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect()(UserAdminLoginSwitcher);
