import React from 'react';
import PropTypes from 'prop-types';

import CmsBlockItem from './CmsBlockItem';

const CmsBlock = ({ block }) => {
    return (block.items || []).map((item, index) => <CmsBlockItem key={index} item={item} type={block.type} />);
};

CmsBlock.propTypes = {
    block: PropTypes.object.isRequired,
};

export default CmsBlock;
