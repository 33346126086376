import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Card, CardBody, ButtonGroup, Button, Form, FormGroup, InputGroup, InputGroupText, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import storage from '../../../../util/storage';

import { checkUserAccountRequest, loginRequest, checkEmail, getUserPreference, login, USER_AUTH_ATTEMPTS_ALLOWED } from '../../UserActions';
import { setRedirect } from '../../../App/AppActions';
import { getDefaultLanguage, getLanguagePath } from '../../../Intl/IntlActions';
import { displayErrors } from '../../../Error/ErrorActions';

import UserForgotPassword from './UserForgotPassword';
import UserNewPassword from './UserNewPassword';
import withRouter from '../../../../components/Router/WithRouter';

class UserLoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: props.step || (props.email ? 'password' : 'email'),
            displayForgotPasswordForm: !!props.code,
            isOnLogin: false,
            email: props.email || '',
            password: props.password || '',
            code: props.code || '',
            displayPassword: false,
            attempts: 0,
            errorFields: [],
        };
    }

    componentDidMount() {
        if(this.props.search.accessToken) {
            this.props.dispatch(login(this.props.search.accessToken));
        } else if(this.props.email && this.props.password) {
            this.handleLogin(new Event('autoLogin'));
        } else if(!this.state.email && storage && storage.getItem('userEmail')) {
            this.goToPasswordStep();
        }
    }

    handleChange = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        this.setState(
            { [name]: value },
        );
    }

    handleCheckEmail = event => {
        if(checkEmail(this.state.email)) {
            this.props.dispatch(checkUserAccountRequest(this.state.email.trim().toLowerCase())).then(result => {
                if(result && result.ok) {
                    this.setState({ step: result.needVerification ? 'verification' : 'password' });
                    storage && storage.setItem('userEmail', this.state.email);
                } else {
                    displayErrors('error', this.props.intl.formatMessage({ id: 'userAccountExistError', defaultMessage: 'Your email address is not associated to an account!' }));
                }
            });
        } else {
            displayErrors('warning', this.props.intl.formatMessage({ id: 'userEmailError', defaultMessage: 'Your email address is incorrect!' }));
        }
    }

    handleLogin = event => {
        event.preventDefault();
        if(this.state.email && this.state.password) {
            this.setState({ isOnLogin: true });
            this.props.dispatch(loginRequest(this.state.email.toLowerCase(), this.state.password)).then(user => {
                const newAttempsCount = this.state.attempts + (user ? 0 : 1);
                this.setState({
                    isOnLogin: false,
                    attempts: newAttempsCount,
                    displayForgotPasswordForm: newAttempsCount >= USER_AUTH_ATTEMPTS_ALLOWED,
                });
                if(!user) {
                    displayErrors('error', this.props.intl.formatMessage({
                        id: 'userLoginError',
                        defaultMessage: 'Wrong credentials! Please retry...',
                    }));
                } else if(!this.state.newPasswordRequired) {
                    const redirection = this.props.appRedirect || this.props.redirect || '';
                    this.props.navigate((!redirection.includes('user/login') && redirection) || getLanguagePath('/', getUserPreference(user, 'language') || getDefaultLanguage()));
                }
            });
        } else {
            const errorFields = [];
            if(!this.state.email) {
                errorFields.push('email');
            }
            if(!this.state.password) {
                errorFields.push('password');
            }
            this.setState({ errorFields });
        }
    }

    handleForgotPassword = () => {
        this.setState({ displayForgotPasswordForm: true });
    }

    handleSubmit = event => {
        event && event.preventDefault();
        switch(this.state.step) {
            case 'email':
            this.handleCheckEmail(event);
            break;

            case 'password':
            default:
            this.handleLogin(event);
            break;
        }
    }

    goToPasswordStep() {
        this.setState({
            email: storage.getItem('userEmail'),
            step: 'password',
        });
    }

    hasErrorField(name) {
        return this.state.errorFields && this.state.errorFields.indexOf(name) !== -1;
    }

    renderForm() {
        if(this.state.displayForgotPasswordForm) {
            return <UserForgotPassword email={this.state.email} code={this.state.code} closeForm={() => this.setState({ displayForgotPasswordForm: false })} redirect={this.props.redirect} />;
        }

        if(this.state.newPasswordRequired) {
            return <UserNewPassword user={this.state.user} userAttributes={this.state.userAttributes} redirect={this.props.redirect} />;
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                {this.state.step !== 'verification' && (
                    <FormGroup className="text-center">
                        <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userLoginIntro" defaultMessage="Please login to access to your dashboard." />
                    </FormGroup>
                )}

                {this.state.step === 'email' && (
                    <FormGroup className={this.hasErrorField('email') ? 'has-danger text-danger' : ''}>
                        <Label for="emailField"><FormattedMessage id="userEmail" defaultMessage="Email" /></Label>
                        <InputGroup>
                            <InputGroupText><FontAwesomeIcon icon="at" fixedWidth /></InputGroupText>
                            <Input type="text" name="email" id="emailField" className={`form-control-user ${this.hasErrorField('email') ? 'is-invalid form-control-danger' : ''}`} onChange={this.handleChange} placeholder={this.props.intl.messages.userEmailFillInfo} value={this.state.email} autoFocus />
                        </InputGroup>
                    </FormGroup>
                )}

                {this.state.step === 'password' && (
                    <div>
                        <p className="text-center">
                            <FormattedMessage id="userLoginEmailSelected" defaultMessage="Login with email: {email}" values={{ email: '' }} /><br />
                            <span className="text-secondary">
                                {this.state.email}
                                <Button color="link" size="sm" className="p-0 ms-2 mt-n1 text-black-50" onClick={() => this.setState({ step: 'email' })}>
                                    <FontAwesomeIcon icon="times-circle" />
                                </Button>
                            </span>
                        </p>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupText><FontAwesomeIcon icon="lock" fixedWidth /></InputGroupText>
                                <Input type={this.state.displayPassword ? 'text' : 'password'} name="password" id="passwordField" className={`${this.hasErrorField('password') ? 'is-invalid form-control-danger' : ''}`} onChange={this.handleChange} placeholder={this.props.intl.messages.userPassword} value={this.state.password} autoFocus />
                            </InputGroup>
                            <ButtonGroup size="sm" className="d-flex justify-content-between">
                                {this.state.step !== 'verification' && (
                                    <Button color="link" className="text-black-70 text-end" onClick={() => this.handleForgotPassword()}>
                                        <FontAwesomeIcon icon="unlock" /> <FormattedMessage id="userForgotPassword" defaultMessage="Forgot password?" />
                                    </Button>
                                )}
                                <Button color="link" className="text-black-70" onClick={event => { event.preventDefault(); this.setState({ displayPassword: !this.state.displayPassword }); }}>
                                    <FontAwesomeIcon icon={this.state.displayPassword ? 'eye-slash' : 'eye'} fixedWidth /> {this.state.displayPassword ? <FormattedMessage id="userPasswordHideButton" defaultMessage="Hide password" /> : <FormattedMessage id="userPasswordDisplayButton" defaultMessage="Display password" />}
                                </Button>
                            </ButtonGroup>
                        </FormGroup>
                    </div>
                )}

                {this.state.step === 'verification' && (
                    <div className="text-center">
                        <p>
                            <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userRecoverFromOldAppText" defaultMessage="You've been migrated to the new version! Check your mailbox to recover your password and activate your new account!" />
                        </p>
                        <ButtonGroup size="sm" className="my-2 ">
                            <Button color="light" onClick={() => this.setState({ step: 'email' })}>
                                <FontAwesomeIcon icon="chevron-left" /> <FormattedMessage id="back" defaultMessage="Back" />
                            </Button>
                            <Button color="success" onClick={() => this.setState({ step: 'password' })}>
                                <FontAwesomeIcon icon="check" /> <FormattedMessage id="userRecoverFromOldAppCheckButton" defaultMessage="I well received my recover password email" />
                            </Button>
                        </ButtonGroup>
                    </div>
                )}

                <FormGroup>
                    <ButtonGroup className="d-flex justify-content-between">
                        {this.props.closeForm && (
                            <Button color="light" onClick={event => this.props.closeForm()}>
                                <FontAwesomeIcon icon="chevron-left" /> <span className="d-none d-sm-inline"><FormattedMessage id="back" defaultMessage="Back" /></span>
                            </Button>
                        )}
                        {this.state.step === 'email' && (
                            <Button color="secondary" outline disabled={!this.state.email} onClick={this.handleCheckEmail}>
                                <FormattedMessage id="next" defaultMessage="Next" /> <FontAwesomeIcon icon="chevron-right" />
                            </Button>
                        )}
                        {this.state.step === 'password' && (
                            <Button color="secondary" outline size="lg" disabled={!this.state.email || !this.state.password || this.state.isOnLogin}>
                                <FontAwesomeIcon icon={this.state.isOnLogin ? 'spinner' : 'power-off'} spin={this.state.isOnLogin} /> <FormattedMessage id="userLoginAction" defaultMessage="Login" />
                            </Button>
                        )}
                    </ButtonGroup>
                </FormGroup>

                {!this.props.hideRegisterButton && (
                    <FormGroup className="text-center">
                        <hr />
                        <Button color="success" size="lg" className="w-100" outline tag={Link} to={`/${this.props.intl.locale}/user/register`}>
                            <FontAwesomeIcon icon="user-plus" /> <FormattedMessage id="userRegister" defaultMessage="Create my account" />
                        </Button>
                        <p className="mt-3 pt-3">
                            <FontAwesomeIcon icon="info-circle" /> <FormattedMessage id="userRegisterText" defaultMessage="Create your secure account, order in 1 click and save your orders easily!" />
                        </p>
                    </FormGroup>
                )}
            </Form>
        );
    }

    render() {
        if(this.props.noWrapper) {
            return this.renderForm();
        }

        return (
            <Card className="shadow">
                <CardBody>
                    {this.renderForm()}
                </CardBody>
            </Card>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        appRedirect: store.app.redirect,
    };
}

UserLoginForm.defaultProps = {
    appRedirect: null,
    redirect: null,
    step: '',
    email: '',
    password: '',
    code: '',
    hideRegisterButton: false,
    noWrapper: false,
    closeForm: null,
};

UserLoginForm.propTypes = {
    navigate: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    appRedirect: PropTypes.string,
    redirect: PropTypes.string,
    step: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    code: PropTypes.string,
    hideRegisterButton: PropTypes.bool,
    noWrapper: PropTypes.bool,
    closeForm: PropTypes.func,
};

export default connect(mapStateToProps)(withRouter(injectIntl(UserLoginForm)));
