/**
 * Client entry point
 */

// import 'regenerator-runtime/runtime';
// import 'core-js/stable';

import React from 'react';
// import ReactDOM, { render } from 'react-dom';
import ReactDOMClient, { createRoot } from 'react-dom/client';

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import config from './config';

import App from './App';
import { configureStore } from './store';


import '../intl/setup';

// PWA Offline support: https://github.com/NekR/offline-plugin
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime';
import { setAppSSRMode } from './modules/App/AppActions';
process.env.NODE_ENV === 'production' && OfflinePluginRuntime.install();

// GA: New
if(config.google.analytics.trackingCodeGA4) {
    config.google.analytics.trackingCodeGA4.split(',').forEach(trackingId => {
        ReactGA4.initialize(trackingId, {});
    });
    ReactGA4.ga('set', 'appName', config.application.name);
    ReactGA4.ga('set', 'appVersion', config.version);
    ReactGA4.gtag('consent', 'update', {
        analytics_storage: 'granted',
    });
}

// Initialize store
const store = configureStore(window.__INITIAL_STATE__);
// Allow the passed state to be garbage-collected
delete window.__INITIAL_STATE__

const container = document.getElementById('root');

// Set as not SSR
store.dispatch(setAppSSRMode(false))

// if(process.env.NODE_ENV === 'development') {
//     // React <18
    // render(<App store={store} />, container);
//
//     // ReactDOM.hydrate(<App store={store} />, container);
// } else {
    console.log('Index::: Hydrate');
    ReactDOMClient.hydrateRoot(container, <App store={store} />);
// }



// For hot reloading of react components
// if(module.hot) {
//     module.hot.accept('./App', () => {
//         // If you use Webpack 2 in ES modules mode, you can
//         // use <App /> here rather than require() a <NextApp />.
//         const NextApp = require('./App').default; // eslint-disable-line global-require
//         ReactDOM.render(<NextApp store={store} key={Math.random()} />, container);
//         // ReactDOM.createRoot(container).render(getApp({store}, Math.random()));
//     });
// }


// Block react-intl errors
if(process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error
    if(console.error === originalConsoleError) {
        console.error = (...args) => {
            if(`${args[0].message}`.indexOf('[@formatjs/intl') === 0) {
                return;
            }
            originalConsoleError.call(console, ...args)
        }
    }
}
