import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getGroupsRequest, getGroups } from '../../GroupActions';
import { setCompanyGroupRequest, getCompanies } from '../../../Company/CompanyActions';
import { clearCart } from '../../../Cart/CartActions';
import { getTranslation } from '../../../Intl/IntlActions';

import CmsPanel from '../../../Cms/components/content/CmsPanel';

class GroupSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null,
            isFetching: false,
        };
    }

    componentDidMount() {
        this.setCompany();
        this.props.dispatch(getGroupsRequest());
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.companies !== this.props.companies) {
            this.setCompany();
        }
    }

    setCompany = () => {
        this.setState({
            company: (this.props.companies || [])[0],
        });
    }

    handleSelect = group => {
        if(this.state.company) {
            this.setState({
                isFetching: true,
            });
            this.props.dispatch(setCompanyGroupRequest(this.state.company._id, group.identifier, true)).then(() => {
                this.props.dispatch(clearCart());
                this.setState({
                    isFetching: false,
                });
                window.scrollTo(0, 0);
            });
        }
    }

    render() {
        if(this.state.isFetching) {
            return (
                <div className="m-3 text-center">
                    <FontAwesomeIcon icon="spinner" spin size="2x" />
                </div>
            );
        }
        return (
            <Container className="page-wrapper p-5">
                <h3><FontAwesomeIcon icon="users" /> <FormattedMessage id="groupSelectTitle" defaultMessage="Please select your group" /></h3>
                <CmsPanel slug="group-selection" />

                <div className="d-flex justify-content-center">
                    {this.props.groups.filter(group => !this.props.companies.find(company => company.groups.includes(group.identifier))).map(group => (
                        <div key={group.identifier} xs="12" lg="4" className="me-4 p-3 w-50 rounded border text-center">
                            <h3>{getTranslation(group, 'name')}</h3>
                            <CmsPanel slug={`group-${group.identifier}-description`} />
                            <div className="mt-3">
                                <Button color="secondary" outline size="sm" onClick={() => this.handleSelect(group)}><FormattedMessage id="groupSelectButton" defaultMessage="Select this group" /> <FontAwesomeIcon icon="chevron-right" /></Button>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        companies: getCompanies(store, true),
        groups: getGroups(store),
    };
}

GroupSelector.defaultProps = {
    companies: [],
    groups: [],
};

GroupSelector.propTypes = {
    dispatch: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    groups: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(GroupSelector);
