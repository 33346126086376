// Import Actions
import { SET_CATEGORIES, REMOVE_CATEGORY } from './CategoryActions';
import { LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    data: [],
};

const CategoryReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                data: state.data.filter(existingCategory => !action.categories.find(category => category._id === existingCategory._id)).concat(action.categories),
            };

        case REMOVE_CATEGORY:
            return {
                ...state,
                data: state.data.filter(existingCategory => existingCategory._id !== action.categoryId),
            };

        case LOGOUT_USER:
            // return initialState;
            return state;

        default:
            return state;
    }
};

export default CategoryReducer;
