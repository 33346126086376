import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';

import config from '../../../../config';

import { getTranslation } from '../../../Intl/IntlActions';

import AppLogo from '../../../App/components/view/AppLogo';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import Price from '../../../Currency/components/view/Price';
import ProductUnit from '../view/ProductUnit';
import ProductWeight from '../view/ProductWeight';

class ProductListExport extends PureComponent {
    getProductsByCategory(category) {
        return (this.props.products || []).filter(product => product.categories.includes(category._id));
    }

    render() {
        return (
            <div>
                {this.props.categories.map(category => (
                    <div key={category._id}>
                        <div className="w-100 position-relative">
                            <div className="m-auto pt-3" style={{ width: '85%' }}>
                                <div className="pb-1">{' '}</div>

                                <table border="0" style={{ width: '100%', marginBottom: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td className="p-2 text-center" style={{ width: '30%' }}>
                                                <AppLogo suffix="alt" imageProps={{ className: 'w-50' }} disableLazy />
                                            </td>
                                            <td>
                                                <h2>{config.application.name}</h2>
                                                <p className="text-start" style={{ lineHeight: '1.5rem' }}>
                                                    <FormattedMessage id="orderProformaContactTitle" defaultMessage="Sales manager" /> BRIAND<br />
                                                    <FormattedMessage id="userEmail" defaultMessage="Email" /> : {config.application.email}<br />
                                                    <FormattedMessage id="userPhone" defaultMessage="Phone" /> : {config.application.phone}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="text-center">
                                    <CmsPanel slug="product-export-header" />
                                </div>

                                <hr />

                                <h1 className="text-secondary mt-3 mb-1">{getTranslation(category, 'name', this.props.intl.locale)}</h1>
                                <Row>
                                    <Col xs="6" />
                                    <Col xs="2">
                                        <strong><FormattedMessage id="weight" defaultMessage="Weight" /></strong>
                                    </Col>
                                    <Col xs="2">
                                        <strong><FormattedMessage id="productPriceHT" defaultMessage="Price excl. tax" /></strong>
                                    </Col>
                                    <Col xs="2">
                                        <strong><FormattedMessage id="packing" defaultMessage="Packing" /></strong>
                                    </Col>
                                </Row>
                                {this.getProductsByCategory(category).map((product, index) => (
                                    <Row key={product._id} className={classnames('mb-1', 'py-2', index % 2 === 0 && 'bg-light')}>
                                        <Col xs="6">
                                            <strong>{getTranslation(product, 'name', this.props.intl.locale)}</strong>
                                        </Col>
                                        <Col xs="2">
                                            <ProductWeight product={product} hideLabel />
                                        </Col>
                                        <Col xs="2">
                                            <Price amount={product[this.props.isNextPrice && product.nextUnitPriceHT ? 'nextUnitPriceHT' : 'unitPriceHT']} formatPrice /> <ProductUnit product={product} />
                                        </Col>
                                        <Col xs="2">
                                            {product.packing}
                                        </Col>
                                    </Row>
                                ))}

                                <hr />
                                <div className="my-2 text-center">
                                    <CmsPanel slug="product-export-footer" />
                                </div>
                            </div>
                        </div>
                        <div className="page-breaker" />
                    </div>
                ))}
            </div>
        );
    }
}

ProductListExport.defaultProps = {
    categories: [],
    products: [],
    isNextPrice: false,
};

ProductListExport.propTypes = {
    intl: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    isNextPrice: PropTypes.bool,
};

export default ProductListExport;
