// Import Actions
import { SET_ORDERS, SET_ORDER, REMOVE_ORDER } from './OrderActions';
import { SET_ORDER_TRANSACTIONS } from '../Transaction/TransactionActions';
import { SET_IS_FETCHING } from '../App/AppActions';
import { LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    data: [],
    isFetching: false,
};

const OrderReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ORDERS: {
            const otherOrders = state.data.filter(order => !action.orders.find(orderToSet => orderToSet._id === order._id));
            return {
                ...state,
                data: otherOrders.concat(action.orders),
                isFetching: false,
            };
        }

        case SET_ORDER: {
            return {
                ...state,
                data: state.data.map(order => {
                    if(action.order && action.order._id === order._id) {
                        return {
                            ...order,
                            ...action.order,
                        };
                    }
                    return order;
                }),
            };
        }

        case SET_ORDER_TRANSACTIONS: {
            return {
                ...state,
                data: state.data.map(order => {
                    if(action.orderId === order._id) {
                        return {
                            ...order,
                            transactions: (order.transactions || []).filter(existingTransaction => !action.transactions.find(transaction => transaction._id === existingTransaction._id)).concat(action.transactions),
                        };
                    }
                    return order;
                }),
            };
        }

        case REMOVE_ORDER:
            return {
                ...state,
                data: state.data.filter(order => order._id !== action.orderId),
            };

        case SET_IS_FETCHING:
            if(action.dataType === 'orders') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default OrderReducer;
