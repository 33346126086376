import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardImgOverlay } from 'reactstrap';

import slugify from 'slugify';

import { getContentBlockItemsByName, getContentBlockItemByName, getContentBlockItemValue, getContentBlockMixedItem } from '../../../CmsActions';

import Breadcrumbs from '../../../../App/components/view/Breadcrumbs';
import FileLoader from '../../../../../components/Content/FileLoader';
import CmsBlockItem from '../../content/CmsBlockItem';
import PreFooter from '../../../../App/components/PreFooter';

const PageMenu = ({ page }) => {
    const cover = getContentBlockItemValue(page.blocks, 'image', 'path');
    const title = getContentBlockItemValue(page.blocks, 'title');
    const contents = getContentBlockItemsByName(page.blocks, 'content');

    const titles = contents.map(content => getContentBlockMixedItem(content, 'title')).filter(title => title);

    return (
        <Fragment>
            <Helmet>
                <body data-header-contrast={cover ? 'true' : 'false'} />
            </Helmet>
            <div className={classnames(cover && 'header-cover', 'page-wrapper', 'bg-light')} style={{ backgroundImage: cover ? `url(${getFile(cover)})` : '', height: cover ? '550px' : '350px' }}>
                <Container className="ms-2 mx-auto mt-5 pt-5">
                    <h1 className={classnames('mt-2', 'ms-2', 'ml-md-0', 'w-100', 'w-md-50', 'border-half-danger', cover ? 'text-white' : 'text-dark')}>
                        {title}
                    </h1>
                </Container>
            </div>
            <Container className={classnames('mt-lg-n5')}>
                <Card body className={classnames('px-2', 'p-md-5')}>
                    <Row>
                        {
                            titles && titles.length > 0 && (
                                <Col xs="12" md="3">
                                    <div className="p-1 shadow border">
                                        <div className="p-3 ps-4 bg-light">
                                            {contents && contents.length > 0 && (
                                                <ol>
                                                    {titles.filter(title => title && title.text).map((title, index) => <li key={slugify(title.text)}><a href={`#${slugify(title.text)}`}>{title.text}</a></li>)}
                                                </ol>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        <Col>
                            {title && <h1 className="text-dark">{title.text}</h1>}
                            {contents.map((content, index) => (
                                <div key={index} id={getContentBlockMixedItem(content, 'title') && getContentBlockMixedItem(content, 'title').text && slugify(getContentBlockMixedItem(content, 'title').text)} className="mb-5">
                                    {false && getContentBlockMixedItem(content, 'title') && <h2>{getContentBlockMixedItem(content, 'title').text}</h2>}
                                    {getContentBlockMixedItem(content, 'text') && <CmsBlockItem item={getContentBlockMixedItem(content, 'text')} type="html" />}
                                    {getContentBlockMixedItem(content, 'image') && <div className="text-center"><CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" /></div>}
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Card>
            </Container>

            <PreFooter />
        </Fragment>
    );
};

PageMenu.propTypes = {
    page: PropTypes.object.isRequired,
};

export default PageMenu;

export const PageMenuModel = {
    type: 'page',
    name: 'pageMenu',
    blocks: [
        {
            name: 'image', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'image', // || text || link || image || mixed (with children)
        },
        {
            name: 'title', // name or id; MUST be unique
            max: 1, // max same items allowed
            type: 'text', // || text || link || image || mixed (with children)
        },
        {
            name: 'content',
            type: 'mixed',
            blocks: [
                {
                    name: 'title',
                    type: 'text',
                    max: 1,
                },
                {
                    name: 'text', // name or id; MUST be unique
                    type: 'html', // || text || link || image || mixed (with children)
                    max: 1, // max same items allowed
                },
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
            ],
        },
    ],
};
