import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
// import CookieConsent from 'react-cookie-consent';

import config from '../../../../config';

import AppLogo from '../view/AppLogo';

import UserAdminLoginSwitcher from '../../../User/components/admin/UserAdminLoginSwitcher';
import CmsPanel from '../../../Cms/components/content/CmsPanel';
import { isSSR } from '../../AppActions';

// Import Style

function Footer({ locale, isSSR }) {
    const [version, setVersion] = useState('');
    useEffect(() => {
        setVersion(config.version);
    }, []);
    return (
        <div className="footer-wrapper">
            <Container>
                <div className="text-center">
                    <AppLogo suffix="alt" disableLazy imageProps={{ width: '123', height: '126', style: { marginTop: '-63px' } }} />
                </div>
                <div className="subfooter py-3 text-center text-sm-left overflow-hidden">
                    <Row>
                        <Col xs="12" lg={{ size: 8, offset: 2 }}>
                            <Row>
                                <Col xs="12" sm="6" className="mb-3">
                                    <CmsPanel slug="footer-links" isTemplate locale={locale} />
                                </Col>
                                <Col xs="12" sm="6" className="mb-3 text-md-right">
                                    <h6>{config.application.name}</h6>
                                    <CmsPanel slug="footer-signature" isTemplate locale={locale} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <p className="subfooter-wrapper px-2 text-center">
                    <small>&copy; {new Date().getFullYear()} &middot; {config.application.name} - <span suppressHydrationWarning>v{version}</span> - <a href="https://offaxis.io" target="_blank" rel="noopener noreferrer">OFF<span style={{ color: '#FF0000' }}>/</span>AXIS</a></small>
                </p>
            </Container>

            {process.env.NODE_ENV !== 'production' && <UserAdminLoginSwitcher />}

            {/* <CookieConsent
                buttonText={<FormattedMessage id="close" defaultMessage="Close" />}
                buttonClasses="btn btn-outline-primary btn-sm"
                buttonStyle={{}}
            >
                <small><FormattedMessage id="appCookieConsentText" defaultMessage="By continuing your visit to this site, you accept the use of cookies for the purposes of the functioning of the proposed service and statistics. No personal data will be communicated without your prior consent." /></small>
                {' '}<Button size="sm" color="link" outline className="border-0" tag={Link} to={`/${locale}/data-protection`}><FormattedMessage id="appCookieConsentPrivacyLink" defaultMessage="See our privacy policy" /></Button>
            </CookieConsent> */}
        </div>
    );
}

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
        isSSR: isSSR(store),
    };
}

Footer.propTypes = {
    locale: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Footer);
