import slugify from 'slugify';

import callApi from '../../util/apiCaller';

import { sendSocket, setIsFetching, FETCH_LIMIT } from '../App/AppActions';
import { getLoggedUser } from '../User/UserActions';
import { addError } from '../Error/ErrorActions';

// Export Constants
export const SET_COMPANIES = 'SET_COMPANIES';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';

export const COMPANY_TYPES = [
    {
        name: 'auto-entrepreneur',
    },
    {
        name: 'SAS/SASU',
    },
    {
        name: 'EIRL/EURL',
    },
    {
        name: 'SA',
    },
    {
        name: 'Other',
    },
];

export const COMPANY_SIREN_REGEX = /([0-9]{9})/;

// Export Actions

export function getCompaniesRequest(search = '', filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, items = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`companies?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter ? `sortBy=${sorter.column || (typeof sorter === 'string' ? sorter : '')}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                items = items.concat(res.companies);
                if(!pager && res.companies && res.companies.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, items));
                }
                resolve(items);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('companies'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? pager.current * ((pager && pager.size) || FETCH_LIMIT) : 0).then(companies => {
            dispatch(setCompanies(companies || []));
            return companies;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function getCompanyRequest(companyId) {
    return dispatch => {
        if(companyId) {
            return callApi(`company/${companyId}`).then(res => {
                if(res.company) {
                    dispatch(setCompanies([res.company]));
                }
                return res.company;
            }).catch(error => {
                dispatch(addError(error));
                return null;
            });
        }
        console.error('MissingCompanyId', companyId);
        return null;
    };
}

export function searchCompaniesRequest(search, escapeStore = false) {
    return dispatch => {
        return callApi('companies/search', 'post', { search }).then(res => {
            !escapeStore && dispatch(setCompanies(res.companies || []));
            return res.companies;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function checkCompanyLegalVatNumber(vatNumber) {
    return dispatch => {
        return callApi('company/legal/vat/check', 'post', { vatNumber }).then(res => {
            return res;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getOutstandingRequest(companyId) {
    return callApi(`company/${companyId}/outstanding`).then(res => {
        return res;
    }).catch(error => {
        return null;
    });
}

export function sendOutstandingRequest(companyId) {
    return callApi(`company/${companyId}/outstanding/send`).then(res => {
        return res.ok;
    }).catch(error => {
        return null;
    });
}

export function checkOutstanding(companyId, totalTTC) {
    return callApi(`company/${companyId}/outstanding/check`, 'post', { totalTTC }).then(res => {
        return res;
    }).catch(error => {
        return null;
    });
}

export function editCompanyRequest(company) {
    return dispatch => {
        return callApi(company._id ? 'company/edit' : 'company/create', 'post', { company }).then(res => {
            if(res.company) {
                dispatch(setCompanies([res.company]));
                dispatch(sendSocket({ type: 'company/update', data: { companyId: company._id } }));
            }
            return res.company;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function removeCompanyRequest(companyId) {
    return dispatch => {
        return callApi('company/remove', 'delete', { company: { _id: companyId } }).then(res => {
            res.ok && dispatch(removeCompany(companyId));
            return res && res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setCompanyGroupRequest(companyId, group, isReplacement = false) {
    return dispatch => {
        return callApi(`company/${companyId}/group`, 'post', { group, replace: isReplacement }).then(res => {
            if(res.company) {
                dispatch(setCompanies([res.company]));
                dispatch(sendSocket({ type: 'company/update', data: { companyId } }));
            }
            return res.company;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setCompanyPreferenceRequest(companyId, key, value) {
    return dispatch => {
        return callApi(`company/${companyId}/preference`, 'post', { key, value }).then(res => {
            if(res.company) {
                dispatch(setCompanies([res.company]));
                dispatch(sendSocket({ type: 'company/update', data: { companyId } }));
            }
            return res.company;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getCompanies(store, isActive = null) {
    let companies = store.companies.data;
    if(isActive !== null) {
        companies = companies.filter(company => company.isActive === isActive);
    }
    return companies;
}

export function getCompany(store, companyId) {
    return store.companies.data.find(company => company._id === companyId);
}

export function canOrder(store) {
    return !!(getCompanies(store, true) || []).filter(company => ((getLoggedUser(store) || {}).companies || []).includes(company._id) || ((getLoggedUser(store) || {}).companies || []).includes('*')).find(canCompanyOrder);
}

export function canCompanyOrder(company) {
    return company.status === 'validated';
}

export function getCompanyLegal(company, legalKey) {
    return (company && company.legal && company.legal[legalKey]) || '';
}

export function getCompanyOutstanding(company, outstandingKey) {
    return (company && company.outstanding && company.outstanding[outstandingKey]) || '';
}

/*
 * Documents are stored like:
 * [{key: 'kbis', path: '...', date: '2021-02-18 10:25:00'}, ...]
 */
export function getCompanyDocument(company, documentKey) {
    return (company && company.documents && company.documents.find(document => document.key === documentKey)) || '';
}

export function getCompanyPreference(company, preference) {
    return (company && company.preferences && company.preferences[preference]) || '';
}

export function getCompanyOption(company, option) {
    return (company && company.options && company.options[option]) || '';
}

export function getLastOderDate(company) {
    return company && company.dates && (company.dates.lastOrder || (company.dates.orders && company.dates.orders.length && company.dates.orders[company.dates.orders.length - 1]));
}

export function getCompanyDocumentTypes() {
    return ['kbis', 'rib', 'pi'];
}

// Preference/orderProductMissing values
export function getCompanyPreferenceOrderProductMissing() {
    return ['unchange', 'replacement', 'ignore'];
}

export function getCompanyStatuses() {
    return ['onvalidation', 'validated'];
}

export function getCompanyStatusColor(status) {
    switch(status) {
        case 'validated':
            return 'success';

        case 'onvalidation':
        default:
            return 'warning';
    }
}

export function setCompanies(companies) {
    return {
        type: SET_COMPANIES,
        companies,
    };
}

export function removeCompany(companyId) {
    return {
        type: REMOVE_COMPANY,
        companyId,
    };
}
