import slugify from 'slugify';

import callApi from '../../util/apiCaller';

import { getTranslation } from '../Intl/IntlActions';
import { GROUP_DEFAULT } from '../Group/GroupActions';
import { addError } from '../Error/ErrorActions';

// Export Constants
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';

// Export Actions

export function getCategoriesRequest(isAll = false) {
    return dispatch => {
        return callApi(`categories${isAll ? '/all' : ''}`).then(res => {
            if(res.categories) {
                dispatch(setCategories(res.categories));
            }
            return res.categories;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editCategoryRequest(category) {
    return dispatch => {
        return callApi('category/edit', 'post', { category }).then(res => {
            if(res.category) {
                dispatch(setCategories([res.category]));
            }
            return res.category;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function deleteCategoryRequest(categoryId) {
    return dispatch => {
        return callApi('category/remove', 'delete', { category: { _id: categoryId } }).then(res => {
            if(res.ok) {
                dispatch(removeCategory(categoryId));
            }
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getCategories(store, isActive = null) {
    let categories = store.categories.data.filter(category => !category.isPack);
    if(isActive !== null) {
        categories = categories.filter(category => category.isActive === isActive);
    }
    return categories.sort((catA, catB) => catA.order - catB.order);
}

export function getCategory(store, categoryId) {
    return store.categories.data.find(category => category._id === categoryId);
}

export function getCategoryBySlug(store, categorySlug, lang = null) {
    return store.categories.data.find(category => {
        return getCategorySlug(category, lang || store.intl.locale) === (categorySlug && categorySlug.toLowerCase());
    });
}

export function getCategorySlug(category, lang) {
    return (getTranslation(category, 'slug', lang) && getTranslation(category, 'slug', lang).toLowerCase()) || slugify(getTranslation(category, 'name', lang)).toLowerCase();
}

export function getCategoryOption(category, option) {
    return (category && category.options && category.options[option]) || '';
}

export function setCategories(categories) {
    return {
        type: SET_CATEGORIES,
        categories,
    };
}

export function removeCategory(categoryId) {
    return {
        type: REMOVE_CATEGORY,
        categoryId,
    };
}
