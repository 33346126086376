// Import Actions
import { persistData, getPersistedData } from '../../util/persistor';

import { SET_COMPANY, SET_ITEM, REMOVE_ITEM, SET_SHIPPING_ADDRESS, SET_SHIPPING_METHOD, SET_EXPEDITION_DATE, CLEAR_CART } from './CartActions';
import { INIT_PERSISTED_DATA } from '../App/AppActions';

// Initial State
export const initialState = {
    company: null, // company _id
    items: [],
    shipping: {
        // address: {
        //     countryCode: '', // cca2 code like 'FR'
        //     postalCode: '', // string: '66000'
        // },
        // carrier: ''
    },
    expeditionDate: null,
    vouchers: [],
};

const CartReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_COMPANY: {
            const newState = {
                ...state,
                company: action.companyId,
                shipping: {},
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_ITEM: {
            const existingItem = state.items.find(item => item.productId === action.item.productId);
            const newState = {
                ...state,
                items: existingItem ? state.items.map(item => {
                    if(item.productId === action.item.productId) {
                        return {
                            ...item,
                            ...action.item,
                        };
                    }
                    return item;
                }) : state.items.concat([action.item]),
            };
            persistData('cart', newState);
            return newState;
        }

        case REMOVE_ITEM: {
            const newState = {
                ...state,
                items: state.items.filter(item => item.productId !== action.item.productId),
            };
            persistData('cart', newState);
            return newState;
        }

        case CLEAR_CART: {
            const newState = {
                ...state,
                items: [],
                expeditionDate: null,
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_EXPEDITION_DATE: {
            const newState = {
                ...state,
                expeditionDate: action.date,
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_SHIPPING_ADDRESS: {
            const newState = {
                ...state,
                shipping: {
                    ...state.shipping,
                    address: action.address,
                },
            };
            persistData('cart', newState);
            return newState;
        }

        case SET_SHIPPING_METHOD: {
            const newState = {
                ...state,
                shipping: {
                    ...state.shipping,
                    method: action.method,
                },
            };
            persistData('cart', newState);
            return newState;
        }

        case INIT_PERSISTED_DATA:
            return getPersistedData('cart', initialState);

        default:
            return state;
    }
};

export default CartReducer;
