import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, Badge, FormGroup, Label, Input, ButtonGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { dateFormat } from '../../../../util/date';
import { capitalizeFirstLetter } from '../../../../util/text';

import { getTasksRequest, editTaskRequest, removeTaskRequest, getTasks } from '../../TaskActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getCountryFromLanguage } from '../../../Intl/IntlActions';

class TaskSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            task: {},
        };
    }

    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.type !== this.props.type || prevProps.target !== this.props.target) {
            this.handleFetch();
        }
    }

    getPickerDate = date => {
        // console.log('getPickerDate', date, `${`${date || ''}`.replace('Z', '')}${moment().format('Z')}`);
        return date ? new Date(`${`${date || ''}`.replace('Z', '')}${moment().format('Z')}`) : null;
    }

    handleFetch = () => {
        this.props.dispatch(getTasksRequest(this.props.type, this.props.target));
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    handleChangeTask = event => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState({
            task: {
                ...this.state.task,
                [name]: value,
            },
        });
    }

    handleChangeDate = date => {
        this.setState({
            task: {
                ...this.state.task,
                dates: {
                    ...(this.state.task.dates || {}),
                    countdown: moment(date).utc(true).format(),
                },
            },
        });
    }

    handleSubmit = () => {
        if(this.state.task && (this.state.task._id || (this.props.type && this.props.target))) {
            this.props.dispatch(editTaskRequest({
                targetType: capitalizeFirstLetter(this.props.type),
                target: this.props.target,
                ...this.state.task,
            })).then(() => {
                this.setState({
                    task: null,
                });
                this.toggle();
            });
        }
    }

    handleRemove = task => {
        this.props.dispatch(removeTaskRequest(task));
    }

    renderTask = task => {
        switch(task.targetType.toLowerCase()) {
            case 'order': {
                return (
                    <Fragment>
                        {this.renderPriority(task)} <FormattedMessage id="order" defaultMessage="Order" /> {(task.target || {}).code} <small><em>{dateFormat(((task.target || {}).dates || {}).expedition, 'LL')}</em></small><br />
                        {this.renderDescription(task)}
                        {this.renderCountdown(task)}
                        <ButtonGroup size="sm" className="d-flex justify-content-between">
                            {task.target && <Button color="link" tag={Link} to={`/${this.props.intl.locale}/console/order/${(task.target || {})._id}`}><FontAwesomeIcon icon="eye" /></Button>}
                            {this.renderButtons(task)}
                        </ButtonGroup>
                    </Fragment>
                );
            }

            case 'transaction':
                return (
                    <Fragment>
                        <div className="d-flex justify-content-between">
                            <strong>{this.renderPriority(task)} <FormattedMessage id="transaction" defaultMessage="Transaction" /></strong>
                            <span>{(task.target || {}).method} <small><em>{dateFormat(((task.target || {}).dates || {}).created, 'LL')}</em></small></span>
                        </div>
                        {this.renderDescription(task)}
                        {this.renderCountdown(task)}
                        <ButtonGroup size="sm" className="d-flex justify-content-between">
                            {task.target && <Button color="link" tag={Link} to={`/${this.props.intl.locale}/console/transaction/${task.target._id}`}><FontAwesomeIcon icon="eye" /></Button>}
                            {this.renderButtons(task)}
                        </ButtonGroup>
                    </Fragment>
                );

            case 'company':
            case 'product':
                return (
                    <Fragment>
                        <div className="d-flex justify-content-between">
                            <strong>{this.renderPriority(task)} {(task.target || {}).name || '---'}</strong>
                            <small><em>{(task.target || {}).groups && ((task.target || {}).groups || []).join(', ')}</em></small>
                        </div>
                        {this.renderDescription(task)}
                        {this.renderCountdown(task)}
                        <ButtonGroup size="sm" className="d-flex justify-content-between">
                            {task.target && <Button color="link" className="text-white" tag={Link} to={`/${this.props.intl.locale}/console/${task.targetType.toLowerCase()}/${task.target._id}`}><FontAwesomeIcon icon="eye" /></Button>}
                            {this.renderButtons(task)}
                        </ButtonGroup>
                    </Fragment>
                );

            default:
                return null;
        }
    }

    renderPriority = task => {
        let color = 'teal';
        let pulse = false;
        switch(task.priority) {
            case 5:
                color = 'pink';
                pulse = true;
                break;

            case 4:
                color = 'red';
                break;

            case 3:
                color = 'purple';
                break;

            case 2:
                color = 'blue';
                break;

            case 1:
                color = 'green';
                break;

            default:
        }

        return <FontAwesomeIcon icon="circle" className={classnames(pulse && 'heartbeat')} style={{ color: `var(--${color})` }} />;
    }

    renderDescription = task => (
        <div className="border-left my-1 ps-2" style={{ fontSize: '0.8rem' }}>
            {task.description}
        </div>
    )

    renderCountdown = task => {
        return task && (task.dates || {}).countdown && <strong className="d-block w-100 text-danger text-center"><FontAwesomeIcon icon="bell" /> <em>{moment.utc().to(task.dates.countdown)}</em></strong>;
    }

    renderButtons = task => (
        <Fragment>
            <Button color="link" className="text-white" onClick={() => this.setState({ task }, this.toggle)}><FontAwesomeIcon icon="pen" /></Button>
            <Button color="link" className="text-danger" onClick={() => this.handleRemove(task)}><FontAwesomeIcon icon="trash" /></Button>
        </Fragment>
    );

    renderTaskTarget = () => {
        switch(this.state.task.targetType.toLowerCase()) {
            case 'order':
                return this.state.task.target.code;

            case 'transaction':
                return this.state.task.target.method;

            case 'company':
            case 'product':
                return this.state.task.target.name;

            default:
                return null;
        }
    }

    render() {
        if(this.props.user && this.props.user.role === 'admin' && ((this.props.type && this.props.target) || (this.props.tasks || []).length > 0)) {
            const togglerId = `tasks-toggler${this.props.type && this.props.target ? `-${this.props.type}-${this.props.target}` : ''}`;
            const toggler = (
                <Fragment>
                    {this.props.tasks.length || null} <FontAwesomeIcon icon="thumbtack" />
                </Fragment>
            );
            return (
                <Fragment>
                    {
                        this.props.type && this.props.target
                        ? (
                            <Button color="link" id={togglerId} className={classnames('h6', this.props.tasks.length && 'text-danger')}>
                                {toggler}
                            </Button>
                        ) : (
                            <Link to="#" id={togglerId} className={classnames('h6', this.props.tasks.length && 'text-danger')}>
                                {toggler}
                            </Link>
                        )
                    }

                    <UncontrolledCollapse toggler={`#${togglerId}`} className="position-absolute p-3 bg-black rounded-sm" style={{ top: '100%', right: 0, minWidth: '20rem', maxHeight: '300px', overflow: 'scroll', zIndex: 10 }}>
                        {this.props.tasks.map(task => (
                            <div key={task._id} className="mb-2 pb-2 border-bottom border-dark text-white" style={{ fontSize: '0.8rem' }}>
                                {this.renderTask(task)}
                            </div>
                        ))}
                        {this.props.type && this.props.target && <Button size="sm" outline block onClick={() => this.setState({ task: {} }, this.toggle)}><FontAwesomeIcon icon="plus" /></Button>}
                    </UncontrolledCollapse>

                    {this.state.task && ((this.props.type && this.props.target) || this.state.task._id) && (
                        <Modal isOpen={this.state.isOpen} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>
                                <FormattedMessage id="taskEditTitle" defaultMessage="Edit task" />
                            </ModalHeader>
                            <ModalBody>
                                {this.state.task && this.state.task.target && (
                                    <h3>{this.renderTaskTarget()}</h3>
                                )}

                                <FormGroup>
                                    <Label for="descriptionField"><FormattedMessage id="taskDescription" defaultMessage="Description" /></Label>
                                    <Input type="textarea" name="description" id="descriptionField" value={this.state.task.description} onChange={this.handleChangeTask} />
                                </FormGroup>

                                <FormGroup>
                                    <DatePicker
                                        name="countdown"
                                        id="countdownDateField"
                                        className="form-control"
                                        selected={this.getPickerDate((this.state.task.dates || {}).countdown)}
                                        onChange={this.handleChangeDate}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        utcOffset={0}
                                        shouldCloseOnSelect
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        minDate={moment().startOf('day').toDate()}
                                        placeholderText={this.props.intl.formatMessage({ id: 'dateSelect', defaultMessage: 'Select a date' })}
                                        autoComplete="off"
                                        popperPlacement="bottom-start"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="priorityField"><FormattedMessage id="taskPriority" defaultMessage="Priority" /></Label>
                                    <Input type="range" name="priority" id="priorityField" value={this.state.task.priority} step="1" min="0" max="5" onChange={this.handleChangeTask} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" block onClick={this.handleSubmit}><FontAwesomeIcon icon="check" /> <FormattedMessage id="save" defaultMessage="Save" /></Button>
                            </ModalFooter>
                        </Modal>
                    )}
                </Fragment>
            );
        }
        return null;
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        tasks: getTasks(store, props.type, props.target),
    };
}

TaskSummary.defaultProps = {
    user: null,
    tasks: [],
    type: null,
    target: null,
};

TaskSummary.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    tasks: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
    target: PropTypes.string,
};
export default connect(mapStateToProps)(injectIntl(TaskSummary));
