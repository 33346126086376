import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '../../../../config';

// Import Components

import PageWrapper from '../../../../components/Content/PageWrapper';
import PageTitle from '../../../../components/Content/PageTitle';
import UserLoginForm from '../../components/auth/UserLoginForm';
import UserNewPassword from '../../components/auth/UserNewPassword';

import User from '../../User';
import withRouter from '../../../../components/Router/WithRouter';

function UserLoginPage({ location, search, intl, redirect }) {
    console.log('UserLoginPage', search);
    return (
        <div className="page-wrapper py-3">
            <Helmet
                title={intl.formatMessage({ id: 'userLogin', defaultMessage: 'Login' })}
                meta={[
                    {
                        name: 'robots',
                        content: 'noindex',
                    },
                ]}
            />
            <Container className="bg-company">
                <PageWrapper>
                    <PageTitle>
                        <h1><FontAwesomeIcon icon="user-circle" /> <FormattedMessage id="userLogin" defaultMessage="Login" /></h1>
                    </PageTitle>
                    <UserLoginForm redirect={redirect || null} email={search.email} password={search.password} code={search.code} />
                </PageWrapper>
            </Container>
        </div>
    );
}

UserLoginPage.defaultProps = {
    redirect: '',
};

UserLoginPage.propTypes = {
    location: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    redirect: PropTypes.string,
};

export default withRouter(injectIntl(UserLoginPage));
