/* eslint-disable global-require */
import React, { lazy, Suspense, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router';
import { Helmet } from 'react-helmet';

import authorization from './components/Auth/AuthorizationComponent';
// import pageAnimator from './components/Content/PageAnimator';

import Loader from './components/Loader/Loader';

// Layout
import App from './modules/App/App';

// Role Filter
// const adminAuth = authorization(['admin']);
// const customerAuth = authorization(['customer']);
// const loggedInAuth = authorization([]);
// Set permissions by page

const HomePage = lazy(() => import('./modules/App/pages/view/HomePage'));
const PrinterPage = lazy(() => import('./modules/App/pages/print/PrintPage'));
const ContactPage = lazy(() => import('./modules/Contact/pages/view/ContactPage'));
const CmsPage = lazy(() => import('./modules/Cms/pages/view/CmsPage'));
// const ProductOrCmsPage = asyncComponent({
//     LoadingComponent: () => <Loader hideContent />,
//     resolve: () => import('./modules/App/pages/view/ProductOrCmsPage'),
// });

const UserRegisterPage = lazy(() => import('./modules/User/pages/auth/UserRegisterPage'));
const UserLoginPage = lazy(() => import('./modules/User/pages/auth/UserLoginPage'));
const UserProfilePage = lazy(() => import('./modules/User/pages/view/UserProfilePage'));
const AddressListPage = lazy(() => import('./modules/Address/pages/list/AddressListPage'));
const AddressEditPage = lazy(() => import('./modules/Address/pages/edit/AddressEditPage'));
const ProductListPage = lazy(() => import('./modules/Product/pages/list/ProductListPage'));
const CartPage = lazy(() => import('./modules/Cart/pages/view/CartPage'));
const OrderListPage = lazy(() => import('./modules/Order/pages/list/OrderListPage'));
const OrderViewPage = lazy(() => import('./modules/Order/pages/view/OrderViewPage'));

// Console components
const AdminDashboard = lazy(() => import('./modules/User/pages/dashboard/AdminDashboard'));
const CompanyListPage = lazy(() => import('./modules/Company/pages/list/CompanyListPage'));
const CompanyEditPage = lazy(() => import('./modules/Company/pages/edit/CompanyEditPage'));
const UserListPage = lazy(() => import('./modules/User/pages/list/UserListPage'));
const UserEditPage = lazy(() => import('./modules/User/pages/edit/UserEditPage'));
const CategoryListAdminPage = lazy(() => import('./modules/Category/pages/list/CategoryListAdminPage'));
const CategoryEditPage = lazy(() => import('./modules/Category/pages/edit/CategoryEditPage'));
const ProductListAdminPage = lazy(() => import('./modules/Product/pages/list/ProductListAdminPage'));
const ProductEditPage = lazy(() => import('./modules/Product/pages/edit/ProductEditPage'));
const OrderListAdminPage = lazy(() => import('./modules/Order/pages/list/OrderListAdminPage'));
const OrderViewAdminPage = lazy(() => import('./modules/Order/pages/view/OrderViewAdminPage'));
const OrderAddPage = lazy(() => import('./modules/Order/pages/edit/OrderAddPage'));
const OrdersSummaryPage = lazy(() => import('./modules/Order/pages/view/OrdersSummaryPage'));
const CompanyOutstandingPage = lazy(() => import('./modules/Company/pages/view/CompanyOutstandingPage'));
const TransactionListPage = lazy(() => import('./modules/Transaction/pages/list/TransactionListPage'));
const TransactionEditPage = lazy(() => import('./modules/Transaction/pages/edit/TransactionEditPage'));
const TransactionPayPage = lazy(() => import('./modules/Transaction/pages/edit/TransactionPayPage'));
const TransactionStatusPage = lazy(() => import('./modules/Transaction/pages/view/TransactionStatusPage'));
const CmsListPage = lazy(() => import('./modules/Cms/pages/list/CmsListPage'));
const CmsEditPage = lazy(() => import('./modules/Cms/pages/edit/CmsEditPage'));
const ConfigPage = lazy(() => import('./modules/Config/pages/view/ConfigPage'));

const baseRoute = '/:lang';

function getPath(path = '', isAdmin = false) {
    return `${baseRoute}${isAdmin ? '/console' : ''}${path}`;
}

export const routesData = [
        // User mgmt
        {
            path: getPath('', true),
            component: AdminDashboard,
        },
        {
            path: getPath('/companies', true),
            component: CompanyListPage,
        },
        {
            path: getPath('/company', true),
            component: CompanyEditPage,
            children: [
                {
                    path: ':id',
                    component: CompanyEditPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/company/:companyId/users', true),
            component: UserListPage,
        },
        {
            path: getPath('/users', true),
            component: UserListPage,
        },
        {
            path: getPath('/company/:companyId/user', true),
            component: UserEditPage,
            children: [
                {
                    path: ':id',
                    component: UserEditPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/user', true),
            component: UserEditPage,
            children: [
                {
                    path: ':id',
                    component: UserEditPage,
                    auth: ['admin'],
                },
            ],
        },
        // {
        //     path: getPath('/company/:companyId/orders', true),
        //     exact: true,
        //     component: OrderListAdminPage,
        // },
        {
            path: getPath('/orders/:type', true),
            component: OrderListAdminPage,
            children: [
                {
                    path: ':status',
                    component: OrderListAdminPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/order/add', true),
            component: OrderAddPage,
            children: [
                {
                    path: ':type',
                    component: OrderAddPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/order/:id', true),
            component: OrderViewAdminPage,
        },
        {
            path: getPath('/transactions', true),
            component: TransactionListPage,
        },
        {
            path: getPath('/transaction', true),
            component: TransactionEditPage,
            children: [
                {
                    path: ':id',
                    component: TransactionEditPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/categories', true),
            component: CategoryListAdminPage,
        },
        {
            path: getPath('/category', true),
            component: CategoryEditPage,
            children: [
                {
                    path: ':id',
                    component: CategoryEditPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/products', true),
            component: ProductListAdminPage,
        },
        {
            path: getPath('/product', true),
            component: ProductEditPage,
            children: [
                {
                    path: ':id',
                    component: ProductEditPage,
                    auth: ['admin'],
                },
            ],
        },
        // Testimonial mgmt
        {
            path: getPath('/contents', true),
            component: CmsListPage,
        },
        {
            path: getPath('/content', true),
            component: CmsEditPage,
            children: [
                {
                    path: ':id',
                    component: CmsEditPage,
                    auth: ['admin'],
                },
            ],
        },
        {
            path: getPath('/config', true),
            component: ConfigPage,
        },

    ].map(adminRoute => ({ ...adminRoute, auth: adminRoute.auth || ['admin'] }))
    .concat(
        [
            {
                path: getPath(),
                component: CmsPage,
            },
            {
                path: '/',
                redirect: baseRoute,
            },
            {
                path: getPath('/cart'),
                component: CartPage,
                children: [
                    {
                        path: ':step',
                        component: CartPage,
                        children: [
                            {
                                path: ':orderId',
                                component: CartPage,
                            },
                        ],
                    },
                ],
            },
            {
                path: getPath('/orders'),
                component: OrderListPage,
                children: [
                    {
                        path: ':type',
                        component: OrderListPage,
                        auth: [],
                    },
                ],
                auth: [],
            },
            {
                path: getPath('/orders/summary/:date/:key/:salt'),
                component: OrdersSummaryPage,
            },
            {
                path: getPath('/order/:orderCode'),
                component: OrderViewPage,
                auth: [],
            },
            {
                path: getPath('/transaction/:companyId/pay'),
                component: TransactionPayPage,
                children: [
                    {
                        path: ':transactionId',
                        component: TransactionPayPage,
                        auth: [],
                    },
                ],
                auth: [],
            },
            {
                path: getPath('/transaction/:companyId/payment/:transactionId/:status'),
                component: TransactionStatusPage,
                auth: [],
            },
            {
                path: getPath('/products'),
                component: ProductListPage,
            },
            {
                path: getPath('/address'),
                component: AddressEditPage,
                children: [
                    {
                        path: ':id',
                        component: AddressEditPage,
                        auth: [],
                    },
                ],
                auth: [],
            },
            {
                path: getPath('/addresses'),
                component: AddressListPage,
                auth: [],
            },
            {
                path: getPath('/company/:id/outstanding'),
                component: CompanyOutstandingPage,
                auth: [],
            },
            {
                path: getPath('/user/register'),
                component: UserRegisterPage,
            },
            {
                path: getPath('/user/login'),
                component: UserLoginPage,
            },
            {
                path: getPath('/user/resetpassword'),
                component: UserLoginPage,
            },
            {
                path: getPath('/user/profile'),
                component: UserProfilePage,
                auth: [],
            },
            {
                path: getPath('/page/printer'),
                component: PrinterPage,
            },
            {
                path: getPath('/page/contact'),
                component: ContactPage,
            },
            // Product
            // CMS
            // Homepage
            {
                path: getPath('/:slug'),
                component: CmsPage,
            },
            {
                path: getPath('/*'),
                component: CmsPage,
            },
            {
                path: getPath(''),
                component: CmsPage,
            },
        ],
    ).map(route => ({
        ...route,
        component: route.auth ? authorization(route.auth)(route.component) : route.component,
    }));

export const layoutComponent = App;

const renderRoutes = (routes, prefix = '') => {
    return routes.map((route, index) => {
        const path = `${prefix ? `${prefix}/` : ''}${route.path}`;
        const key = route.path || index;

        if(route.redirect) {
            return <Route key={key} path={path} element={<Navigate replace to={route.redirect} />} />;
        }
        // if(route.pagePath) {
        //     return (
        //         <Route key={index} {...route} render={props => <AsyncComponent path={route.pagePath} {...props} />} />
        //     );
        // }
        // prefix && console.log(prefix, route.path, path);
        return (
            <Route key={key} path={path} index={!route.path} element={<route.component />}>
                {route.children && route.children.length > 0 && renderRoutes(route.children, path)}
            </Route>
        );
    });
};

function AppRoutes(props) {
    console.log('AppRoutes::: Render');
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<App />}>
                    {renderRoutes(routesData)}
                </Route>
            </Routes>
        </Suspense>
    );
}

Routes.defaultProps = {
    ssr: false,
};

Routes.propTypes = {
    ssr: PropTypes.bool,
 };

export default AppRoutes;
