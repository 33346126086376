import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse, Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dateFormat } from '../../../../util/date';

import { getUnreadNotificationsRequest, setNotificationAsViewedRequest, getUnreadNotifications } from '../../NotificationActions';
import { getLoggedUser } from '../../../User/UserActions';

class NotificationSummary extends Component {
    componentDidMount() {
        this.fetchNotifications();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user !== this.props.user) {
            this.fetchNotifications();
        }
    }

    fetchNotifications = () => {
        if(this.props.user) {
            this.props.dispatch(getUnreadNotificationsRequest());
        }
    }

    handleMarkAllAsRead = () => {
        this.props.dispatch(setNotificationAsViewedRequest('all'));
    }

    renderNotification = notification => {
        switch(notification.targetType.toLowerCase()) {
            case 'order': {
                const order = notification.target;
                return (
                    <Fragment>
                        <FormattedMessage id="order" defaultMessage="Order" /> {order.code} <small><em>{dateFormat(order.dates.expedition, 'LL')}</em></small><br />
                        <Link to={`/${this.props.intl.locale}${this.props.user.role === 'admin' ? '/console' : ''}/order/${order._id}#notifications-list`}>
                            <FormattedMessage id="orderView" defaultMessage="View order" /> <FontAwesomeIcon icon="eye" />
                        </Link>
                    </Fragment>
                );
            }

            case 'transaction':
            case 'company':
            default:
                return null;
        }
    }

    render() {
        if(this.props.user && this.props.notifications.length) {
            return (
                <div className="position-relative">
                    <Link to="#" id="notifications-toggler" className="h5 mx-1 text-danger"><FontAwesomeIcon icon="bell" /></Link>
                    <UncontrolledCollapse toggler="#notifications-toggler" className="position-absolute p-2 bg-black rounded-sm" style={{ top: '100%', right: 0, minWidth: '20rem', maxHeight: '300px', overflow: 'scroll', zIndex: 10 }}>
                        {this.props.notifications.length > 0 && <Button color="light" outline size="sm" block onClick={this.handleMarkAllAsRead}><FormattedMessage id="notificationMarAllAsReadButton" defaultMessage="Mark all as read" /></Button>}
                        {this.props.notifications.map(notification => (
                            <div key={notification._id} className="my-2 pb-1 border-bottom text-end" style={{ fontSize: '0.8rem' }}>
                                {this.renderNotification(notification)}
                            </div>
                        ))}
                    </UncontrolledCollapse>
                </div>
            );
        }
        return null;
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        notifications: getUnreadNotifications(store),
    };
}

NotificationSummary.defaultProps = {
    user: null,
    notifications: [],
};

NotificationSummary.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    notifications: PropTypes.arrayOf(PropTypes.object),
};
export default connect(mapStateToProps)(injectIntl(NotificationSummary));
