import callApi from '../../util/apiCaller';

import { setRedirect } from '../App/AppActions';
import { getDefaultLanguage } from '../Intl/IntlActions';


export const SET_CONTENTS = 'SET_CONTENTS';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_CONTENT_TRANSLATION = 'SET_CONTENT_TRANSLATION';

export function getCmsTemplateContentsRequest(language = null) {
    return dispatch => {
        return callApi(`cms/template/${language || ''}`).then(res => {
            res.contents && dispatch(setContents(res.contents));
            return res.contents;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function getCmsContentsRequest() {
    return dispatch => {
        return callApi('cms').then(res => {
            res.contents && dispatch(setContents(res.contents, true));
            return res.contents;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function getCmsPageRequest(slug, language = getDefaultLanguage(), forceRefresh = false) {
    return dispatch => dispatch(getCmsRequest('page', slug || '*', language, forceRefresh));
}

export function getCmsPanelRequest(slug, language = getDefaultLanguage(), forceRefresh = false) {
    return dispatch => dispatch(getCmsRequest('panel', slug, language, forceRefresh));
}

export function getCmsRequest(type, slug, language = getDefaultLanguage(), forceRefresh = false, query = '') {
    return dispatch => {
        if(slug) {
            return callApi(`cms/${type}/${language}/${slug}${forceRefresh ? '?refreshCache=1' : (query ? (query.includes('?') ? query : `?${query}`) : '')}`).then(res => {
                res && res.content && res.redirect && dispatch(setRedirect(`/${res.content.slug}`));
                res && res.content && dispatch(setContent(res.content));
                // res && res.content && res.blocks && dispatch(setBlocks(res.content._id, JSON.parse(res.blocks)));
                return res.content;
            }).catch(err => {
                console.error(err);
                // dispatch(addError(error));
                return null;
            });
        }
        return Promise.resolve(null);
    };
}

export function getCmsPageTranslationsRequest(pageId) {
    return dispatch => {
        return callApi(`cms/${pageId}/translations`).then(res => {
            res && res.translations && dispatch(setContentTranslations(pageId, res.translations));
            return res.translations;
        }).catch(err => {
            console.error(err);
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editCmsContentRequest(content) {
    return dispatch => {
        return callApi('cms/edit', 'post', { content }).then(res => {
            res.content && dispatch(setContent(res.content));
            return res.content;
        }).catch(err => {
            return null;
        });
    };
}

export function removeCmsContentRequest(contentId) {
    return dispatch => {
        return callApi('cms/remove', 'delete', { content: { _id: contentId } }).then(res => {
            res.ok && getCmsContentsRequest();
            return res.ok;
        }).catch(err => {
            return null;
        });
    };
}

export function getContents(store) {
    return store.cms.data;
}

export function getPages(store, lang = null) {
    return store.cms.data.filter(content => content._id && (!lang || lang === content.language) && content.type === 'page');
}

export function getPanels(store, lang = null) {
    return store.cms.data.filter(content => content.type === 'panel' && (!lang || lang === content.language));
}

export function getNotices(store, lang = null) {
    return store.cms.data.filter(content => content.type === 'notice' && (!lang || lang === content.language));
}

export function getContent(store, contentId) {
    return store.cms.data.find(content => content._id === contentId);
}

export function getPanelBySlugAndLanguage(store, slug, language) {
    return store.cms.data.find(content => content.type === 'panel' && content.slug === slug && content.language === language);
}

export function getPageBySlugAndLanguage(store, slug, language) {
    return store.cms.data.find(content => content.type === 'page' && (content.slug === slug || (content.slug === '*' && !slug) || (content.redirections || []).includes(slug)) && content.language === language);
}

export function getContentTranslations(content, contents) {
    return contents.filter(otherContent => otherContent.translatedFrom === content._id);
}

export function getCmsPageSeo(page, key) {
    return (page && page.seo && page.seo[key]) || '';
}

export function getContentOption(content, key) {
    return (content && content.options && content.options[key]) || '';
}

export function getContentBlockByName(contentBlocks, blockName) {
    return (contentBlocks || []).find(existingBlock => existingBlock.name === blockName);
    // if(Array.isArray(contentBlocksOrMixedItems)) {
    // }
    // if(typeof contentBlocksOrMixedItems === 'object') {
    //     return {
    //         name: blockName,
    //         items: contentBlocksOrMixedItems[blockName],
    //     };
    // }
    // return null;
}

export function getContentBlockData(contentBlocks, block) {
    return getContentBlockByName(contentBlocks, block.name) || block;
}

export function getContentBlockItemsByName(contentBlocks, blockName) {
    return ((getContentBlockByName(contentBlocks, blockName) || {}).items || []).sort((itemA, itemB) => itemA.sort - itemB.sort);
}

export function getContentBlockItemByName(contentBlocks, blockName) {
    const items = getContentBlockItemsByName(contentBlocks, blockName) || [];
    return items.length ? items[0] : null;
}

export function getContentBlockMixedItems(mixedItem, subItemName) {
    return (mixedItem && mixedItem[subItemName]) || [];
}

export function getContentBlockMixedItem(mixedItem, subItemName) {
    const items = getContentBlockMixedItems(mixedItem, subItemName);
    return items.length ? items[0] : null;
}

export function getContentBlockItemValue(contentBlocks, blockName, key = 'text') {
    return (getContentBlockItemByName(contentBlocks, blockName) || {})[key] || '';
}

export function setContents(contents, resetAll = false) {
    return {
        type: SET_CONTENTS,
        contents,
        resetAll,
    };
}

export function setContent(content) {
    return {
        type: SET_CONTENT,
        content,
    };
}

export function setContentTranslations(contentId, translations) {
    return {
        type: SET_CONTENT_TRANSLATION,
        contentId,
        translations,
    };
}
