import createHmac from 'create-hmac';
import moment from 'moment';
import slugify from 'slugify';
import { storagePersistent } from '../../util/storage';

import config from '../../config';

import callApi from '../../util/apiCaller';
import { getRandomString } from '../../util/text';

import { GROUP_DEFAULT } from '../Group/GroupActions';
import { sendSocket, redirect, initApp, setIsFetching, FETCH_LIMIT } from '../App/AppActions';
import { addError } from '../Error/ErrorActions';
import { initLanguage, switchLanguage } from '../Intl/IntlActions';

// Export Constants
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CONNECTED_USER = 'CONNECTED_USER';
export const CONNECTED_USERS = 'CONNECTED_USERS';
export const DISCONNECTED_USER = 'DISCONNECTED_USER';
export const SET_USERS = 'SET_USERS';
export const SET_USER_ACTIVITY = 'SET_USER_ACTIVITY';
export const SET_USER_ACTIVITY_DATE = 'SET_USER_ACTIVITY_DATE';

export const USER_EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/m;
// export const USER_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*\W).{6,64}$/i; // 6 chars + 1 number + 1 special character
export const USER_PASSWORD_REGEX = /^(.){6,64}$/i;
export const USER_PHONE_REGEX = /(\+)?([0-9]{8,14})/gi;
export const USER_ACTIVITY_THRESHOLD = 5; // in minutes
export const USER_ROLE_DEFAULT = 'manager';
export const USER_AUTH_ATTEMPTS_ALLOWED = 3;

export const LOGIN_TOKEN_NAME = `${slugify((config.application.name || '').toLowerCase())}.${process.env.NODE_ENV}.auth.token`;

var activityInterval = null; // eslint-disable-line

// Export Actions
export function isLoggedInRequest() {
    return dispatch => {
        if(hasAuthToken()) {
            return callApi('user/getloggeduser').then(res => {
                if(res.user) {
                    dispatch(loginUser(res.user));
                    dispatch(redirect());
                    // dispatch(subscribeToChannel(`user/${res.user._id}`));
                    dispatch(initLanguage(getUserPreference(res.user, 'language'), res.user));
                    dispatch(sendActivityRecall(res.user));
                    activityInterval = setInterval(() => dispatch(sendActivityRecall(res.user)), (USER_ACTIVITY_THRESHOLD * 0.9 * 60 * 1000));
                } else {
                    dispatch(logoutUser());
                }
                return res.user;
            }).catch(error => {
                // if(error && error.message !== 'Unauthorized') {
                    dispatch(addError(error));
                // }
                dispatch(logoutUser());
                return false;
            });
        }
        return Promise.resolve(false);
    };
}

export function checkUserAccountRequest(email) {
    return dispatch => {
        return callApi('user/accountexists', 'post', { user: { email } }).then(result => {
            return result;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function loginRequest(email, password, blockDispatch = false) {
    return dispatch => {
        return callApi('user/login', 'post', { email, password }).then(result => {
            if(result && result.token && !blockDispatch) {
                dispatch(login(result.token));
            }
            return result;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function loginByIdRequest(userId) {
    return dispatch => {
        return callApi(`user/login/${userId}`, 'post').then(result => {
            if(result && result.token) {
                dispatch(logoutUser(true));
                dispatch(login(result.token));
            }
            return result;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function registerRequest(user) {
    return dispatch => {
        return callApi('user/register', 'post', { user }).then(result => {
            if(result && result.token) {
                dispatch(login(result.token));
            }
            return result;
        }).catch(err => {
            console.error(err);
            dispatch(addError(err));
            return null;
        });
    };
}

export function askLostPasswordRequest(email) {
    return dispatch => {
        const salt = getRandomString();
        const hash = createHmac('sha256', salt)
            .update(email)
            .digest()
            .toString('base64');

        return callApi('user/lostpassword', 'post', { email, salt, hash }).then(result => {
            return result && result.ok;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function resetPasswordRequest(email, code, password) {
    return dispatch => {
        const salt = getRandomString();
        const hash = createHmac('sha256', salt)
            .update(`${email}.${code}`)
            .digest()
            .toString('base64');

        return callApi('user/resetpassword', 'post', { email, code, password, salt, hash }).then(result => {
            return result && result.ok;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function editPasswordRequest(oldPassword, newPassword) {
    return dispatch => {
        return callApi('user/editpassword', 'post', { oldPassword, newPassword }).then(result => {
            return result && result.ok;
        }).catch(err => {
            console.error(err);
            dispatch(addError(err));
            return null;
        });
    };
}

export function setAdminRequest() {
    return dispatch => {
        return callApi('user/setadmin', 'get').then(res => {
            return res.user;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function getUsersRequest(search = '', filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, items = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`users?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter ? `sortBy=${sorter.column || sorter}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                items = items.concat(res.users);
                if(!pager && res.users && res.users.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, items));
                }
                resolve(items);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('users'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? pager.current * ((pager && pager.size) || FETCH_LIMIT) : 0).then(users => {
            dispatch(setUsers(users || []));
            return users;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function searchUsersRequest(search) {
    return dispatch => {
        return callApi('/users/search', 'post', { search }).then(res => {
            dispatch(setUsers(res.users || []));
            return res.users;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function getUserRequest(userId) {
    return dispatch => {
        dispatch(setIsFetching('users'));
        return callApi(`user/${userId}`).then(res => {
            if(res.user) {
                dispatch(setUsers([res.user]));
            }
            return res.user;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function getSubscribersRequest() {
    return dispatch => {
        return callApi('users/subscribers').then(res => {
            return res.subscribers;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function editUserRequest(user, isLoggedUser = false, requestAll = true) {
    return dispatch => {
        return callApi('user/edit', 'post', { user }).then(res => {
            if(res.user && isLoggedUser) {
                dispatch(loginUser(res.user));
            } else if(requestAll) {
                dispatch(getUsersRequest());
            }
            return res.user;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setUserPreferenceRequest(preferenceKey, preferenceValue, isLoggedUser = true) {
    return dispatch => {
        return callApi(`user/preference/${preferenceKey}/${encodeURIComponent(preferenceValue)}`).then(res => {
            res.user && isLoggedUser && dispatch(loginUser(res.user));
            res.user && !isLoggedUser && dispatch(getUsersRequest());
            return res.user;
        }).catch(error => {
            // dispatch(addError(error));
        });
    };
}

export function subscribeNewsletterRequest(newsletter = 'general', isSubscribe = true, user = {}) {
    return dispatch => {
        return callApi(`user/newsletter/subscribe/${newsletter}`, 'post', { isSubscribing: isSubscribe, user }).then(res => {
            res.ok && dispatch(isLoggedInRequest());
            return res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function removeUserRequest(userId) {
    return dispatch => {
        return callApi('user/remove', 'delete', { user: { _id: userId } }).then(res => {
            if(res.ok) {
                dispatch(getUsersRequest());
            }
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function deleteAccountRequest() {
    return dispatch => {
        return callApi('user/deleteaccount', 'delete').then(res => {
            dispatch(logoutUser(true));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}

export function sendNewAccountEmailRequest(userId) {
    return dispatch => {
        return callApi(`user/alert/${userId}`).then(res => {
            return res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function sendActivityRecall(user) {
    return dispatch => {
        dispatch(sendSocket({ type: 'user/activity/ping', data: { user: user._id } }));
    };
}

export function hasAuthToken() {
    return !!getAuthToken();
}

export function getAuthToken() {
    return storagePersistent && storagePersistent.getItem(LOGIN_TOKEN_NAME);
}

export function login(token) {
    return dispatch => {
        if(storagePersistent) {
            storagePersistent.setItem(LOGIN_TOKEN_NAME, token);
        }
        // dispatch(isLoggedIn());
        dispatch(initApp());
        return true;
    };
}

export function isLoggedIn() {
    return dispatch => {
        return dispatch(isLoggedInRequest());
    };
}

// Getters && Check
export function checkEmail(email) {
    return USER_EMAIL_REGEX.test(email.trim().toLowerCase());
}

export function checkPassword(password) {
    return USER_PASSWORD_REGEX.test(password);
}

export function getUserPreference(user, preference) {
    return (user && user.preferences && user.preferences[preference]) || '';
}

export function getUserOption(user, option) {
    return (user && user.options && user.options[option]) || '';
}

export function getLoggedUser(store) {
    return store.users.user;
}

export function getUsers(store, companies = null, isActive = null) {
    return store.users.data
        .filter(user => !companies || user.companies.some(company => companies.includes(company)))
        .filter(user => isActive === null || user.isActive === isActive);
}

export function getUser(store, userId) {
    return store.users.data.find(user => user._id === userId) || (store.users.user._id === userId ? store.users.user : null);
}

export function getUserActivity(store, userId) {
    return store.users.connectedUsers[userId];
}

export function getUserDateActivity(store, key) {
    return (store.users.activityDates && store.users.activityDates[key]) || null;
}

export function getUserId(userOrUserId) {
    return (userOrUserId || {})._id || userOrUserId;
}

export function canEditCompany(user, companyId = null) {
    return user && user.companies && ((user.companies.length > 1 && (!companyId || user.companies.includes(companyId))) || user.companies.includes('*'));
}

export function getAvailableUserRoles() {
    return ['manager', 'admin'];
}

// Actions
export function loginUser(user) {
    // console.log('set login user', user);
    // token = token.replace('JWT ', '');
    return {
        type: LOGIN_USER,
        user,
        // token: token
    };
}

export function logoutUser(userAction = false) {
    // browserHistory.push('/');
    if(userAction) {
        storagePersistent && storagePersistent.removeItem(LOGIN_TOKEN_NAME);
        sessionStorage && sessionStorage.removeItem(LOGIN_TOKEN_NAME);
    }
    clearInterval(activityInterval);
    return { type: LOGOUT_USER };
}

// export function userConnected(user) {
//     return {
//         type: CONNECTED_USER,
//         user,
//     };
// }
//
// export function usersConnected(users) {
//     return {
//         type: CONNECTED_USERS,
//         users,
//     };
// }
//
// export function userDisconnected(user) {
//     return {
//         type: DISCONNECTED_USER,
//         user,
//     };
// }

export function setUsers(users) {
    return {
        type: SET_USERS,
        users,
    };
}

export function setUserActivity(userId) {
    return {
        type: SET_USER_ACTIVITY,
        userId,
        lastActivityTimestamp: moment().unix(),
    };
}

export function setUserDateActivity(key, value) {
    return {
        type: SET_USER_ACTIVITY_DATE,
        key,
        value: value || moment.utc().unix(),
    };
}
