import callApi from '../../util/apiCaller';
import { getLoggedUser, getUser } from '../User/UserActions';
import { addError } from '../Error/ErrorActions';

// Export Constants
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const SET_COUNTRIES = 'SET_COUNTRIES';

export const ADDRESS_FLAGS = ['unreachable', 'missingData', 'notFound', 'openingHours', 'propertyType'];

// Export Actions
export function getAvailableCountriesRequest() {
    return dispatch => {
        return callApi('address/countries').then(res => {
            dispatch(setCountries(res.countries));
            return res.countries;
        });
    };
}

export function getAddressesRequest(companyId = null) {
    return dispatch => {
        return callApi(`addresses${companyId ? `/${companyId}` : ''}`).then(res => {
            if(res.addresses) {
                dispatch(setAddresses(res.addresses));
            }
            return res.addresses;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editAddressRequest(address) {
    return dispatch => {
        return callApi('address/edit', 'post', { address }).then(res => {
            if(res.address) {
                dispatch(setAddresses([res.address]));
            }
            return res.address;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function setAddressAsDefaultRequest(addressId, companyId) {
    return dispatch => {
        return callApi('address/setasdefault', 'post', { address: addressId, company: companyId }).then(res => {
            res && res.ok && dispatch(getAddressesRequest(companyId));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function deleteAddressRequest(addressId) {
    return dispatch => {
        return callApi('address/remove', 'post', { address: { _id: addressId } }).then(res => {
            if(res.ok) {
                dispatch(removeAddress(addressId));
            }
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getCountries(store) {
    return store.addresses.countries;
}

export function getAddress(store, addressId) {
    return store.addresses.data.find(address => address._id === addressId);
}

export function getAddresses(store, userId) {
    const user = getUser(store, userId);
    return store.addresses.data.filter(address => user.companies.includes(address.company));
}

export function getAddressesByCompany(store, companyId) {
    return store.addresses.data.filter(address => address.company === companyId);
}

export function getCountryNameTranslation(item, locale) {
    let name = '';
    switch(locale) {
        case 'fr':
            name = item.translations.fra && item.translations.fra.common;
            break;

        case 'de':
            name = item.translations.deu && item.translations.deu.common;
            break;

        case 'es':
            name = item.translations.spa && item.translations.spa.common;
            break;

        default:
            name = item.name.common;
    }
    return name || item.name.common;
}

export function setAddresses(addresses) {
    return {
        type: SET_ADDRESSES,
        addresses,
    };
}
export function removeAddress(addressId) {
    return {
        type: REMOVE_ADDRESS,
        addressId,
    };
}

export function setCountries(countries) {
    return {
        type: SET_COUNTRIES,
        countries,
    };
}
