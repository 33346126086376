// Import Actions
import { SET_TRANSACTIONS } from './TransactionActions';
import { SET_IS_FETCHING } from '../App/AppActions';
import { LOGOUT_USER } from '../User/UserActions';

// Initial State
export const initialState = {
    data: [],
    isFetching: false,
};

const TransactionReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_TRANSACTIONS: {
            const otherTransactions = state.data.filter(transaction => !action.transactions.find(transactionToSet => transactionToSet._id === transaction._id));
            return {
                ...state,
                data: otherTransactions.concat(action.transactions),
                isFetching: false,
            };
        }

        case SET_IS_FETCHING:
            if(action.dataType === 'transactions') {
                return {
                    ...state,
                    isFetching: action.isFetching,
                };
            }
            return state;

        case LOGOUT_USER:
            return initialState;

        default:
            return state;
    }
};

export default TransactionReducer;
