import callApi from '../../util/apiCaller';

import { addError } from '../Error/ErrorActions';

export const SET_CONFIGS = 'SET_CONFIGS';
export const REMOVE_CONFIG = 'REMOVE_CONFIG';

export function getPublicConfigRequest() {
    return dispatch => {
        return callApi('configs/public').then(res => {
            res.configs && dispatch(setConfigs(res.configs));
            return res.configs;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getAllConfigRequest() {
    return dispatch => {
        return callApi('configs').then(res => {
            res.configs && dispatch(setConfigs(res.configs));
            return res.configs;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getConfigRequest(key) {
    return dispatch => {
        return callApi(`config/${key}`).then(res => {
            res.config && dispatch(setConfigs([res.config]));
            return res.config;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editConfigRequest(config) {
    return dispatch => {
        return callApi('config/edit', 'post', { config }).then(res => {
            return res.config;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function removeConfigRequest(configKey) {
    return dispatch => {
        return callApi('config/remove', 'delete', { config: { key: configKey } }).then(res => {
            dispatch(removeConfig(configKey));
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

// Getters
export function getConfig(store, key) {
    return (store.config.data.find(config => config.key === key) || {}).value;
}

export function setConfigs(configs) {
    return {
        type: SET_CONFIGS,
        configs,
    };
}

export function removeConfig(key) {
    return {
        type: REMOVE_CONFIG,
        key,
    };
}
