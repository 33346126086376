import { SET_UNREAD_NOTIFICATIONS, SET_NOTIFICATION_AS_READ } from './NotificationActions';

// Initial State
export const initialState = {
    unread: [],
    isFetching: false,
};

const NotificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_UNREAD_NOTIFICATIONS: {
            return {
                ...state,
                unread: action.notifications,
            };
        }

        case SET_NOTIFICATION_AS_READ: {
            return {
                ...state,
                unread: action.notificationId ? state.unread.filter(notification => notification._id !== action.notificationId) : [],
            };
        }

        default:
            return state;
    }
};

export default NotificationReducer;
