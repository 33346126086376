import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import VisibilitySensor from 'react-visibility-sensor';
import { getFileUrl } from '../../util/file';

export default class FileLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: null,
            isLoading: false,
            isLoaded: false,
            loadedUrls: {},
        };

        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.handleLoadFile = this.handleLoadFile.bind(this);
    }

    componentDidMount() {
        if(this.props.loadonmount) {
            this.handleLoadFile();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.filename !== this.props.filename) {
            this.handleLoadFile();
        }
    }

    handleVisibilityChange(isVisible) {
        if(isVisible && !this.state.isLoaded && !this.state.isLoading) {
            this.handleLoadFile();
        }
    }

    handleLoadFile() {
        if(this.props.filename) {
            if(this.state.loadedUrls[this.props.filename]) {
                this.setState({
                    url: this.state.loadedUrls[this.props.filename],
                    isLoaded: true,
                });
            } else {
                this.setState({
                    isLoading: true,
                });
                getFileUrl(this.props.filename, this.props.owner).then(url => {
                    this.setState({
                        url,
                        isLoading: false,
                        isLoaded: true,
                        loadedUrls: {
                            ...this.state.loadedUrls,
                            [this.props.filename]: url,
                        },
                    });
                }).catch(() => {
                    console.error('File not found !');
                    this.setState({
                        isLoading: false,
                    });
                });
            }
        }
    }

    renderFileTag() {
        let tag = 'span';
        let props = {
            className: this.props.className || '',
            style: this.props.style || {},
        };
        let content = null;
        if(this.props.filename) {
            const ext = this.props.filename.split('.').pop();
            switch(ext) {
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'png':
                case 'tiff':
                case 'bmp':
                case 'svg':
                case 'webp':
                    tag = 'img';
                    props = {
                        ...props,
                        src: this.state.url,
                    };
                    break;
                    // return <img src={this.state.url} {...this.props} />; // eslint-disable-line

                default:
                    tag = 'a';
                    content = (
                        <Fragment>
                            <FontAwesomeIcon icon="download" /> {this.props.filename.split('/').pop()}
                        </Fragment>
                    );
                    props = {
                        href: this.state.url,
                        download: this.props.filename.split('/').pop(),
                        target: '_blank',
                    };
                    break;
                    // return <a href={this.state.url} download={this.props.filename.split('/').pop()} target="_blank"><FontAwesomeIcon icon="download" /> {this.props.filename.split('/').pop()}</a>; // eslint-disable-line
            }
        }

        const Tag = this.props.tag || tag;
        return content ? <Tag {...props}>{content}</Tag> : <Tag {...props} />;
    }

    render() {
        if(this.props.filename) {
            return (
                <VisibilitySensor onChange={this.handleVisibilityChange}>
                    {
                        this.state.isLoaded
                        ? this.renderFileTag()
                        : (
                            <Fragment>
                                {
                                    this.props.hidePlaceholders
                                    ? <span>&nbsp;</span>
                                    : (
                                        this.state.isLoading
                                        ? <FontAwesomeIcon icon="spinner" spin className="m-3" />
                                        : <FontAwesomeIcon icon="file-image" className="m-3" />
                                    )

                                }
                            </Fragment>
                        )
                    }
                </VisibilitySensor>
            );
        }
        return null;
    }
}

FileLoader.defaultProps = {
    owner: null,
    loadonmount: false,
    hidePlaceholders: false,
    tag: null,
};

FileLoader.propTypes = {
    filename: PropTypes.string.isRequired,
    owner: PropTypes.string,
    loadonmount: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hidePlaceholders: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
