import callApi from '../../util/apiCaller';

import { addError } from '../Error/ErrorActions';

export function contactRequest(userData, message) {
    return (dispatch) => {
        return callApi('/contact', 'post', { user: userData, message }).then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
        });
    };
}
