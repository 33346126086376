import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import config from '../../../../config';

// import PageContent from '../../components/content/PageContent';

import { getCmsPanelRequest, getPanelBySlugAndLanguage } from '../../CmsActions';
import { getLanguagePath } from '../../../Intl/IntlActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getPanelModelViewer } from '../../CmsModels';

class CmsPanel extends Component {
    componentDidMount() {
        !this.props.panel && this.setContent();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.slug !== this.props.slug || prevProps.locale !== this.props.locale || prevProps.intl.locale !== this.props.intl.locale) {
            this.setContent();
        }
    }

    setContent() {
        console.log('CmsPanel::: setContent', this.props.slug, this.props.locale || this.props.intl.locale, this.props.isTemplate);
        return this.props.dispatch(getCmsPanelRequest(this.props.slug, this.props.locale || this.props.intl.locale));
    }

    render() {
        if(this.props.panel) {
            const ModelComponent = getPanelModelViewer(this.props.panel.model);
            const panel = <ModelComponent panel={this.props.panel} {...this.props} />; // eslint-disable-line

            if(this.props.user && this.props.user.role === 'admin') {
                return (
                    <div className="position-relative">
                        {panel}
                        <Button color="link" size="sm" tag={Link} to={getLanguagePath(`/console/content/${this.props.panel._id}`, this.props.intl.locale)} className="m-0 p-0 no-print" style={{ position: 'absolute', top: '5px', left: '-10px' }}><FontAwesomeIcon icon="pen" /></Button>
                    </div>
                );
            }
            return panel;
        }
        if((this.props.user && this.props.user.role === 'admin') || process.env.NODE_ENV === 'development') {
            return `CmsPanel ::: TODO ::: ${this.props.slug}`;
        }
        // console.log('Panel not found !!');
        return null;
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        panel: getPanelBySlugAndLanguage(store, props.slug, props.locale || store.intl.locale),
    };
}

CmsPanel.defaultProps = {
    user: null,
    panel: null,
    locale: '',
    isTemplate: false,
};

CmsPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    user: PropTypes.object,
    panel: PropTypes.object,
    locale: PropTypes.string,
    isTemplate: PropTypes.bool,
};

export default connect(mapStateToProps)(injectIntl(CmsPanel));
