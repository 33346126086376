import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Button, Input, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import config from '../../../../config';

import { setCartItem, removeItem, getCartItemByProduct, getCartItemFromProduct, formatAnalyticsItems } from '../../CartActions';
import { getProductOption, getProductPrice } from '../../../Product/ProductActions';
import { getLoggedUser } from '../../../User/UserActions';
import { getTranslation } from '../../../Intl/IntlActions';
import { getCurrentCurrency } from '../../../Currency/CurrencyActions';

class CartItemQuantitySelector extends Component {
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     console.log('getDerivedStateFromProps', nextProps, prevState);
    //     if(nextProps.item && nextProps.item.quantity !== prevState.quantity) {
    //         return { quantity: nextProps.item.quantity };
    //     }
    //     return null;
    // }

    handleSubtract = () => {
        if((this.props.item || {}).quantity > 0) {
            this.handleChangeQuantity((this.props.item || {}).quantity - 1);
        }
    };

    handleAdd = () => {
        this.handleChangeQuantity(((this.props.item || {}).quantity || 0) + 1);
    };

    handleChange = event => {
        this.handleChangeQuantity(parseInt(event.target.value, 10));
    };

    handleChangeQuantity = newQuantity => {
        if(!getProductOption(this.props.product, 'quantityMaxInCart') || newQuantity <= getProductOption(this.props.product, 'quantityMaxInCart')) {
            this.props.dispatch(setCartItem(getCartItemFromProduct(this.props.product, newQuantity, this.props.intl.locale, this.props.user)));
            this.handleTrackAnalytics(newQuantity, newQuantity - (this.props.item?.quantity || 0));
            if(this.props.item && !this.props.onCart && !newQuantity) {
                this.props.dispatch(removeItem(this.props.item));
            }
        } else {
            this.handleChangeQuantity(getProductOption(this.props.product, 'quantityMaxInCart'));
        }
    };

    handleTrackAnalytics = (quantity = 1, diff = 0) => {
        console.log('handleTrackAnalytics', quantity, diff);
        const { product, currency } = this.props;
        const productPrice = getProductPrice(product, 1);
        if(typeof window !== 'undefined') {
            if(config.google.analytics.trackingCodeGA4) {
                // GA: New
                console.log(ReactGA4.event(diff > 0 ? 'add_to_cart' : 'remove_from_cart', {
                    currency,
                    value: productPrice,
                    items: formatAnalyticsItems([{ ...product, priceTTC: productPrice, quantity }]),
                }));
            }
            if(window.fbq) {
                window.fbq('track', 'AddToCart', {
                    content_name: this.props.product.name,
                    content_ids: [this.props.product.sku || this.props.product._id],
                    content_type: 'product',
                    value: productPrice,
                    currency: 'EUR',
                });
            }
        }
    };

    render() {
        const { product, item, onCart, disableQuantityEdition } = this.props;
        const quantity = (item && item.quantity) || 0;
        return (
            <div>
                <div className="d-flex p-1 p-md-2 bg-light rounded-sm">
                    <span><Button color="link" size="sm" className="qty-control" onClick={this.handleSubtract}><FontAwesomeIcon icon="minus" /></Button></span>
                    <Input type="text" name="quantity" bsSize="sm" value={quantity} className="border-0 bg-light text-dark text-center font-weight-bold" onChange={this.handleChange} />
                    <span><Button color="link" size="sm" className="qty-control" disabled={getProductOption(product, 'quantityMaxInCart') && (item?.quantity || 0) > getProductOption(product, 'quantityMaxInCart')} onClick={this.handleAdd}><FontAwesomeIcon icon="plus" /></Button></span>
                </div>
                {item && item.quantity > 0 && (
                    <small className="d-block text-center font-italic">
                        <FormattedMessage id="productPacksCount" defaultMessage="Box(es)" values={{ count: quantity }} /><br />
                        <FormattedMessage id="cartItemPackingPiece" defaultMessage="Or {count} piece(s)" values={{ count: item.quantity * item.packing }} />
                    </small>
                )}
            </div>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        currency: getCurrentCurrency(store),
        user: getLoggedUser(store),
        item: getCartItemByProduct(store, props.product),
    };
}

CartItemQuantitySelector.defaultProps = {
    user: null,
    item: null,
    onCart: false,
    disableQuantityEdition: false,
    onAddToCart: null,
};

CartItemQuantitySelector.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    product: PropTypes.object.isRequired,
    user: PropTypes.object,
    item: PropTypes.object,
    onCart: PropTypes.bool,
    disableQuantityEdition: PropTypes.bool,
    onAddToCart: PropTypes.func,
};

export default connect(mapStateToProps)(injectIntl(CartItemQuantitySelector));
