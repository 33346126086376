let storage = null;
let storagePersistent = null;

if(typeof(sessionStorage) !== "undefined"){
    //use the local storage
    storage = sessionStorage;
}

if(typeof(localStorage) !== "undefined"){
    //use the local storage
    storagePersistent = localStorage;
}

export default storage;
export { storagePersistent };
