import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CmsBlock from '../../content/CmsBlock';

const PanelDefault = ({ panel }) => {
    return (
        <Fragment>
            {(panel.blocks || []).map((subBlock, index) => <CmsBlock key={subBlock.id || index} block={subBlock} />)}
        </Fragment>
    );
};

PanelDefault.propTypes = {
    panel: PropTypes.object.isRequired,
};

export default PanelDefault;

export const PanelDefaultModel = {
    type: 'panel',
    name: 'default',
    blocks: [
        {
            name: 'content', // name or id; MUST be unique
            label: 'Content', // Display name, if not set, name is used
            max: 1, // max same items allowed
            type: 'html', // || text || link || image || mixed (with children)
            // items: [{text: '<p>azerty 123456</p>'}], // values saved inserted in content model
        },
    ],
};
