import moment from 'moment';

import callApi from '../../util/apiCaller';
import { getPriceCent, getPriceFromCent } from '../../util/price';
import { getHashKeys } from '../../util/security';
import { getRandomString } from '../../util/text';

import { getOrderRequest, getOrder } from '../Order/OrderActions';
import { setIsFetching, FETCH_LIMIT } from '../App/AppActions';
import { addError } from '../Error/ErrorActions';

export const PAYMENT_TYPE_CARD = 'creditcard';
export const PAYMENT_TYPE_TRANSFER = 'banktransfer';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_ORDER_TRANSACTIONS = 'SET_ORDER_TRANSACTIONS';

export function getTransactionsRequest(search, filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, transactions = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`transactions?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter ? `sortBy=${sorter.column || sorter}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                transactions = transactions.concat(res.transactions);
                if(!pager && res.transactions && res.transactions.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, transactions));
                }
                resolve(transactions);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('transactions'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? pager.current * ((pager && pager.size) || FETCH_LIMIT) : 0).then(transactions => {
            dispatch(setTransactions(transactions || []));
            return transactions;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function getTransactionRequest(transactionId) {
    return dispatch => {
        return callApi(`transaction/${transactionId}`).then(res => {
            if(res.transaction) {
                dispatch(setTransactions([res.transaction]));
            }
            return res.transaction;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getOrderTransactions(orderId) {
    return dispatch => {
        return callApi(`order/${orderId}/transactions`).then(res => {
            if(res.transactions) {
                dispatch(setTransactions(res.transactions));
            }
            return res.transactions;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function createOrderTransactionRequest(orderId, method, amount, userId = null) {
    // console.log('token received', token, amount);
    return dispatch => {
        return callApi(`order/${orderId}/transaction/payment`, 'post', { method, amount, creator: userId }).then(res => {
            if(res.order && res.transaction) {
                dispatch(setOrderTransactions(res.order._id, [res.transaction]));
            }
            return res;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function captureTransactionRequest(transaction, data = {}) {
    return dispatch => {
        return callApi(`transaction/${getTransactionId(transaction)}/capture`, 'post', { transaction, data, hash: getHashKeys(`${transaction._id}.${moment.utc().format('YMD')}`) }).then(res => {
            if(res.transaction) {
                dispatch(setTransactions([res.transaction]));
            }
            return res;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function editTransactionRequest(transaction, sendRequest = false) {
    return dispatch => {
        return callApi('transaction/edit', 'post', { transaction, sendRequest }).then(res => {
            if(res.transaction) {
                dispatch(setTransactions([res.transaction]));
            }
            return res.transaction;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function removeTransactionRequest(transactionId) {
    return dispatch => {
        return callApi(`transaction/${transactionId}/remove`, 'delete').then(res => {
            return res && res.ok;
        }).catch(err => {
            console.error(err);
            return null;
        });
    };
}

export function getTransaction(store, transactionId) {
    return store.transactions.data.find(transaction => transaction._id === transactionId);
}

export function getTransactionsByOrder(store, orderId, type, status = null) {
    let transactions = (store.transactions.data || []).filter(transaction => transaction.orders.includes(orderId) && transaction.type === type);
    if(status) {
        if(!Array.isArray(status)) {
            status = [status];
        }
        transactions = transactions.filter(payment => status.includes(payment.status));
    }
    return transactions;
}

export function getTransactionsByCompany(store, companyId, type, status = null) {
    let transactions = (store.transactions.data || []).filter(transaction => getTransactionCompanyId(transaction) === companyId);
    if(status) {
        if(!Array.isArray(status)) {
            status = [status];
        }
        transactions = transactions.filter(payment => status.includes(payment.status));
    }
    return transactions;
}

export function getPendingCardPayment(store, orderId) {
    return getTransactionsByOrder(store, orderId, 'payment', 'pending').find(payment => payment.method === PAYMENT_TYPE_CARD && moment(payment.date).isAfter(moment().subtract(7, 'days'))) || null;
}

export function getPendingTransferPayment(store, orderId) {
    return getTransactionsByOrder(store, orderId, 'payment', 'pending').find(payment => payment.method === PAYMENT_TYPE_TRANSFER) || null;
}

export function getPaymentsTotal(payments, totalType = 'amount') {
    return payments && payments.length ? getPriceFromCent(payments.filter(payment => payment.status === 'validated').reduce((total, payment) => total + getPriceCent(payment[totalType]), 0)) : 0;
}

export function getTransactionId(transaction) {
    return transaction?._id || transaction;
}

export function getTransactionCompanyId(transaction) {
    return transaction && transaction.company && transaction.company._id ? transaction.company._id : (transaction || {}).company;
}

export function getTransactionTypes() {
    return ['payment', 'creditmemo', 'refund'];
}

export function getTransactionStatuses() {
    return ['pending', 'validated', 'canceled'];
}

export function getTransactionTypeColor(type) {
    switch(type) {
        case 'payment':
            return 'success';

        case 'refund':
            return 'danger';

        default:
            return 'warning';
    }
}

export function getTransactionStatusColor(status) {
    switch(status) {
        case 'validated':
            return 'success';

        case 'canceled':
            return 'danger';

        default:
            return 'warning';
    }
}

export function getTransactionMethods() {
    return ['creditcard', 'banktransfer'];
}

export function setTransactions(transactions) {
    return {
        type: SET_TRANSACTIONS,
        transactions,
    };
}

export function setOrderTransactions(orderId, transactions) {
    return {
        type: SET_ORDER_TRANSACTIONS,
        orderId,
        transactions,
    };
}
