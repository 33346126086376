import slugify from 'slugify';

import callApi from '../../util/apiCaller';
import { formatPrice } from '../../util/price';

import { sendSocket } from '../App/AppActions';
import { getTranslation } from '../Intl/IntlActions';
import { setCategories } from '../Category/CategoryActions';
import { GROUP_DEFAULT } from '../Group/GroupActions';
import { addError } from '../Error/ErrorActions';

// Export Constants
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

// Export Actions

export function getProductsRequest(isAll = false) {
    return dispatch => {
        return callApi(`products${isAll ? '/all' : ''}`).then(res => {
            if(res.products) {
                dispatch(setProducts(res.products));
            }
            if(res.categories) {
                dispatch(setCategories(res.categories));
            }
            return res.products;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function editProductRequest(product, fetchAll = true) {
    return dispatch => {
        return callApi('product/edit', 'post', { product }).then(res => {
            if(res.product) {
                fetchAll ? dispatch(getProductsRequest(true)) : dispatch(setProducts([res.product]));
                fetchAll && dispatch(sendSocket({ type: 'products/update' }));
            }
            return res.product;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function deleteProductRequest(productId) {
    return dispatch => {
        return callApi('product/remove', 'delete', { product: { _id: productId } }).then(res => {
            if(res.ok) {
                dispatch(removeProduct(productId));
            }
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getProducts(store, isActive = null) {
    let products = store.products.data.filter(product => !product.isPack);
    if(isActive !== null) {
        products = products.filter(product => product.isActive === isActive);
    }
    return products.sort((productA, productB) => productA.order - productB.order);
}

export function getProduct(store, productId) {
    return store.products.data.find(product => product._id === productId);
}

export function getProductBySlug(store, productSlug, lang = null) {
    return store.products.data.find(product => {
        return getProductSlug(product, lang || store.intl.locale) === (productSlug && productSlug.toLowerCase());
    });
}

export function getProductSlug(product, lang) {
    return (getTranslation(product, 'slug', lang) && getTranslation(product, 'slug', lang).toLowerCase()) || slugify(getTranslation(product, 'name', lang)).toLowerCase();
}

export function getProductPrice(product, quantity = 1, isUnit = false, inclTax = false) {
    let price = 0;
    if(product) {
        price = parseFloat(product.unitPriceHT);
        if(product.unitType === 'kg') {
            price *= product.weight;
        }
        price *= quantity;
        if(!isUnit) {
            price *= parseInt(product.packing, 10);
        }
    }
    if(inclTax) {
        price *= (1 + product.taxRate);
    }
    return formatPrice(price);
}

export function getProductOption(product, option) {
    return (product && product.options && product.options[option]) || '';
}

export function setProducts(products) {
    return {
        type: SET_PRODUCTS,
        products,
    };
}

export function removeProduct(productId) {
    return {
        type: REMOVE_PRODUCT,
        productId,
    };
}
